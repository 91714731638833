export default {
	UPDATE_GROUPS: "UPDATE_GROUPS",
	UPDATE_USERSGROUPS: "UPDATE_USERSGROUPS",
	CREATE_USER: "CREATE_USER",
	UPDATE_USER: "UPDATE_USER",
	UPDATE_GROUP_MEMBERS: "UPDATE_GROUP_MEMBERS",
	DELETE_USER: "DELETE_USER",
	CREATE_GROUP: "CREATE_GROUP",
	UPDATE_GROUP: "UPDATE_GROUP",
	DELETE_GROUP: "DELETE_GROUP",
	SAVE_USER_DATA: "SAVE_USER_DATA",
	SAVE_USER_PROFILE: "SAVE_USER_PROFILE",
	UPDATE_ONLINE_USERS: "UPDATE_ONLINE_USERS",
	RESET_USERS_GROUPS: "RESET_USERS_GROUPS",
	RESET_USERGROUP: "RESET_USERGROUP"
};
