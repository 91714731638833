import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import "./Switch.css";

export default function FormSwitch(props) {
	const { name, checked, onChange, label, color = "primary", ...others } = props;

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	return (
		<FormControlLabel
			label={
				<Typography component={"span"} color={checked ? color : undefined}>
					{label}
				</Typography>
			}
			control={
				<Switch
					name={name}
					checked={checked}
					color={color}
					className="custome-disabled"
					onChange={e => onChange(convertToDefEventParam(name, e.target.checked))}
					{...others}
				/>
			}
		/>
	);
}
