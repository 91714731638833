import { preloadedState } from "redux/store.js";
import C from "../../constants/userGroup.js";

const userGroupReducers = function (currentState = {}, action) {
	switch (action.type) {
		case C.UPDATE_GROUPS: {
			let groups = action.payload;
			return { ...currentState, groups: groups };
		}

		case C.UPDATE_USERSGROUPS: {
			let groups = action.payload.groups;
			let users = action.payload.users;

			return { ...currentState, groups: groups, users: users, groupsAreFetching: false };
		}

		case C.RESET_USERGROUP: {
			return preloadedState.userGroup;
		}

		default:
			return currentState;
	}
};

export default userGroupReducers;
