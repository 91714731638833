import C from "constants/routes.js";

const actions = {
	updateRoutes: function (route) {
		return dispatch => {
			dispatch({
				type: C.UPDATE_ROUTES,
				payload: route
			});
		};
	}
};

export default actions;
