/* eslint-disable react/display-name */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React, { useEffect, useRef, useState } from "react";
import useStyles from "../../CockpitStyleJs/styleFilter";
import Tooltip from "@material-ui/core/Tooltip";
import C from "../../../../constants/cockpit.js";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n";
import InboxIcon from "@material-ui/icons/Inbox";
import { ArrowDownwardOutlined, ArrowUpwardOutlined, DraftsOutlined } from "@material-ui/icons";
import lod_ from "lodash";

/**
 *
 */
export default ({}) => {
	const dispatch = useDispatch();
	const classes = useStyles();

	const { auth } = useSelector(state => state.userStatus);
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [hoveredActions, setHoveredActions] = useState(false);
	const [direction, setDirection] = useState("");
	const [openActions, setOpenActions] = useState(false);
	const anchorRefActions = useRef(null);

	function handleListKeyDownActions(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenActions(false);
		}
	}

	/**
	 *
	 */
	useEffect(() => {
		if (hoveredActions) {
			const timer = hoveredActions && setTimeout(handleToggleActions, 100);
			return () => {
				clearTimeout(timer);
			};
		} else {
			setOpenActions(false);
		}
	}, [hoveredActions]);

	/**
	 *
	 */
	const handleToggleActions = () => {
		setOpenActions(prevOpen => !prevOpen);
	};

	/**
	 *
	 * @param {*} event
	 * @returns
	 */
	const handleCloseActions = event => {
		if (anchorRefActions.current && anchorRefActions.current.contains(event.target)) {
			return;
		}
		setOpenActions(false);
	};
	/**
	 *
	 * @param {*} direction
	 * @returns
	 */
	const handleDirection = direction => {
		setDirection(direction);
		handleSaveFilter(direction);
	};

	/**
	 *
	 */
	const handleSaveFilter = direction => {
		dispatch({
			type: C.SET_CONV_DIRECTION_FILTER,
			payload: lod_.isNil(direction) ? "" : direction
		});
	};

	return (
		<Tooltip title={i18n.t("COC.directionActions")} placement="top">
			<Box
				className={classes.filterButton}
				mt={1}
				onMouseEnter={() => setHoveredActions(true)}
				onMouseLeave={() => setHoveredActions(false)}
			>
				<ToggleButton
					aria-haspopup="menu"
					aria-controls={openActions ? "menu-list-grow-actions" : undefined}
					ref={anchorRefActions}
					value={openActions}
					size="small"
					onClick={() => (direction !== "" ? handleDirection("") : null)}
					style={direction !== "" ? { color: "black", backgroundColor: "rgba(0, 0, 0, 0.15)" } : {}}
				>
					{direction === "Inbound" && (
						<Box
							style={{
								position: "relative",
								display: "flex"
							}}
						>
							<ArrowDownwardOutlined
								fontSize="small"
								style={{
									position: "absolute",
									top: -8,
									fontSize: "20px"
								}}
							/>
							<DraftsOutlined fontSize="small" />
						</Box>
					)}

					{direction === "Outbound" && (
						<Box style={{ position: "relative", display: "flex" }}>
							<ArrowUpwardOutlined
								fontSize="small"
								style={{
									position: "absolute",
									top: -9,
									fontSize: "20px"
								}}
							/>
							<DraftsOutlined fontSize="small" />
						</Box>
					)}

					{direction === "" && <DraftsOutlined fontSize="small" />}
				</ToggleButton>
				<Popper
					open={openActions}
					anchorEl={anchorRefActions.current}
					className={classes.dropDown}
					role={undefined}
					transition
					disablePortal
				>
					{() => (
						<ClickAwayListener onClickAway={handleCloseActions}>
							<MenuList
								mt={0}
								mb={0}
								pt={0}
								autoFocusItem={openActions}
								id="menu-list-grow-actions"
								onKeyDown={handleListKeyDownActions}
							>
								<Paper>
									<ToggleButtonGroup
										orientation="vertical"
										exclusive
										size="small"
										background="#00000000"
										value={direction}
									>
										<Tooltip title={i18n.t("COC.inboundDirection")} placement="right">
											<ToggleButton
												value="Inbound"
												onClick={() =>
													direction !== "" ? handleDirection("") : handleDirection("Inbound")
												}
												style={
													direction === "Inbound"
														? { color: "black", backgroundColor: "rgba(0, 0, 0, 0.15)" }
														: {}
												}
											>
												<Box style={{ position: "relative", display: "flex" }}>
													<ArrowDownwardOutlined
														fontSize="small"
														style={{ position: "absolute", top: -8, fontSize: "20px" }}
													/>
													<DraftsOutlined fontSize="small" />
												</Box>
											</ToggleButton>
										</Tooltip>
										<Tooltip title={i18n.t("COC.outboundDirection")} placement="right">
											<ToggleButton
												value="Outbound"
												onClick={() =>
													direction !== "" ? handleDirection("") : handleDirection("Outbound")
												}
												style={
													direction === "Outbound"
														? { color: "black", backgroundColor: "rgba(0, 0, 0, 0.15)" }
														: {}
												}
											>
												<Box style={{ position: "relative" }}>
													<ArrowUpwardOutlined
														fontSize="small"
														style={{ position: "absolute", top: -20, fontSize: "20px" }}
													/>
												</Box>
												<DraftsOutlined fontSize="small" />
											</ToggleButton>
										</Tooltip>
									</ToggleButtonGroup>
								</Paper>
							</MenuList>
						</ClickAwayListener>
					)}
				</Popper>
			</Box>
		</Tooltip>
	);
};
