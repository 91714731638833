import { createSelector } from "reselect";
export const getUserReducer = state => state.userStatus;
export const getWsReducer = state => state.ws;

export const getWsSelector = createSelector(
	[getUserReducer, getWsReducer],
	(userStatus, { onlineUsers, humanRequest }) => ({
		unavailable: onlineUsers[userStatus?.auth?.user?._id]?.unavailable,
		onlineUsers,
		humanRequest
	})
);
