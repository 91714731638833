import { getBackgroundColor } from "helpers/utilities";
import React from "react";
import { useSelector } from "react-redux";
import logoFaibrik from "../../assets/img/logo/default/faibrik_white.png";

export const ImgSettings = ({ updateDisplayStatus, unavailable, humanRequest, classes }) => {
	const { channels } = useSelector(state => state.assistantconfig);

	return (
		<img
			src={logoFaibrik}
			alt={"logo-faibrik-witch"}
			style={{
				backgroundColor: getBackgroundColor(channels, unavailable, humanRequest),
				borderRadius: "80%",
				cursor: "pointer",
				transition: "all 300ms linear",
				opacity: 1,
				textAlign: "center",
				display: "inline-block",
				marginLeft: "22px",
				marginRight: "18px",
				marginTop: "10px",
				color: "inherit"
			}}
			width={"40px"}
			height="40px"
			onClick={updateDisplayStatus}
		/>
	);
};
