/* eslint-disable eqeqeq */

import C from "constants/assistants.js";
import store from "../store.js";
import { api } from "./api.js";

const actions = {
	updateAssistants: function (assistantsIDs) {
		let user = store.getState().userStatus.auth.user;
		if (!user) return;

		const onSuccess = payload => {
			let assistants = payload.map(assistant => {
				return {
					assistantID: assistant.assistantID,
					description: assistant.description
				};
			});
			return {
				type: C.UPDATE_ASSISTANTS,
				payload: assistants
			};
		};

		return dispatch => {
			dispatch(
				api({
					type: "updateAssistants",
					url: "/api/v1/getAssistantConfigDescriptions",
					data: { email: user.email, assistantsIDs: assistantsIDs },
					onSuccess
				})
			);
		};
	}
};

export default actions;
