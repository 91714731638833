module.exports = {
	ExportConversationToGedDialogSchema
};

function ExportConversationToGedDialogSchema(t) {
	const schema = {
		type: "object",
		required: ["affairNumber"],
		properties: {
			affairNumber: {
				type: "string",
				title: "Numéro d'affaire PL/"
			}
		}
	};

	const uiSchema = {
		type: "VerticalLayout",
		elements: [
			{
				type: "Control",
				scope: "#/properties/affairNumber"
			}
		]
	};

	return {
		schema,
		uiSchema
	};
}
