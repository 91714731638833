import lod_ from "lodash";
import { api } from "redux/actions/api.js";
import store from "redux/store.js";
import { createSlice } from "@reduxjs/toolkit";
import { infoMsg } from "./snackMsgsReducers";
import axios from "axios";
const endPoint = process.env.REACT_APP_AMBACK;
let request;

const initialState = {
	intentOpenDialog: false,
	groupOpenDialog: false,
	userOpenDialog: false,
	applyFiltersToUsersOpenDialog: false,
	formSelected: []
};
export const filters = initialState;

const slice = createSlice({
	name: "filters",
	initialState,
	reducers: {
		initFilters: () => ({ ...initialState }),
		setGroupOpenDialog: (state, action) => {
			const open = action.payload;
			state.groupOpenDialog = open;
		},
		setIntentOpenDialog: (state, action) => {
			const open = action.payload;
			state.intentOpenDialog = open;
		},
		setUserOpenDialog: (state, action) => {
			const open = action.payload;
			state.userOpenDialog = open;
		},
		setFormSelected: (state, action) => {
			if (!lod_.isNil(action.payload)) state.formSelected = action.payload;
		},
		setApplyFiltersToUsersOpenDialog: (state, action) => {
			const open = action.payload;
			state.applyFiltersToUsersOpenDialog = open;
		}
	}
});

// ACTIONS
export const {
	initFilters,
	setGroupSelectAll,
	setGroupOpenDialog,
	setIntentOpenDialog,
	setUserOpenDialog,
	setFormSelected,
	setApplyFiltersToUsersOpenDialog
} = slice.actions;

export default slice.reducer;
