import { Box } from "@mui/system";
import React from "react";

/**
 * Display of a menuText
 *
 * {menuTitle: "", menuText: [ {label, value, order} ]}
 * @returns
 */
export default function MenuText({ menuTitle, menuText }) {
	if (menuText?.length > 0) {
		const hasOrderProperty = menuText.every(obj => obj.order);
		if (hasOrderProperty) {
			menuText = menuText.sort((a, b) => {
				return a.order - b.order;
			});
		}
		return (
			<>
				<p style={{ display: "flex", margin: "10px", justifyContent: "center" }}>{menuTitle}</p>
				<Box display="flex" justifyContent="center" flexDirection="row" flexWrap="wrap">
					{menuText.map(
						(e, index) =>
							e.value && (
								<Box
									display="flex"
									textAlign="center"
									borderRadius="5px"
									padding="5px"
									key={index}
									width="40%"
									maxWidth="40%"
									margin="10px"
									height="50px"
									alignItems="center"
									border="2px"
									justifyContent="center"
									style={{ border: "solid rgb(212, 211, 211)" }}
								>
									<p style={{ margin: "0" }}>{e.value}</p>
								</Box>
							)
					)}
				</Box>
			</>
		);
	} else return <></>;
}
