/* eslint-disable react/prop-types */
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputAdornment,
	TextField,
	Tooltip
} from "@material-ui/core";
import { Chip } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import lod_ from "lodash";
import React, { forwardRef, memo } from "react";
import cockpitActions from "../../../redux/actions/cockpitActions";
import i18n from "i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getRedirectionDialogSelector } from "redux/selectors/cockpitSelectors.js";

/**
 *
 */
const RedirectionDialogUsers = forwardRef(
	({ showRedirectionSuccessPopup, handleCloseUsersRedirectionDialog, open }, ref) => {
		const dispatch = useDispatch();
		const { selectedConversation } = useSelector(state => state.cockpit);
		let { userStatus, userGroup, resolverGroup } = useSelector(
			getRedirectionDialogSelector,
			shallowEqual
		);
		const [search, setSearch] = React.useState("");

		/**
		 *
		 * @param {*} event
		 */
		const handleSearchChange = event => {
			setSearch(event.target.value);
		};

		/**
		 *
		 * @param {*} selectedConversation
		 * @param {*} user
		 */
		const handleUserRedirection = (selectedConversation, user) => {
			if (lod_.has(userStatus, "auth.user")) {
				dispatch(
					cockpitActions.redirectConversationToUser(
						selectedConversation,
						user,
						showRedirectionSuccessPopup
					)
				);
				handleCloseUsersRedirectionDialog();
			}
		};

		const currentUser = selectedConversation?.agent?.uid;

		return (
			<Dialog
				fullWidth
				maxWidth="md"
				open={open}
				onClose={handleCloseUsersRedirectionDialog}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{i18n.t("COC.Redirect")}</DialogTitle>
				<DialogContent dividers>
					{selectedConversation && userGroup.users && userGroup.users.length > 0 && (
						<>
							{" "}
							<Box m={1}>
								<TextField
									fullWidth
									label=""
									variant="outlined"
									size="small"
									onChange={handleSearchChange}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										)
									}}
								/>
							</Box>
							<span
								className="tagListForm"
								style={{
									display: "flex",
									flexWrap: "wrap",
									width: "100%"
								}}
							>
								{userGroup.users
									.filter(user => {
										if (resolverGroup) {
											if (Array.isArray(resolverGroup)) {
												if (!user.groups.some(grp => resolverGroup.includes(grp))) return false;
											} else if (!user.groups.includes(resolverGroup)) {
												return false;
											}
										} else {
											return false;
										}

										if (search.trim().length >= 0) {
											let fullSearch =
												(user.name || "") + " " + (user.surname || "") + " " + user._id;
											return fullSearch.toLowerCase().includes(search.toLowerCase());
										} else {
											return true;
										}
									})
									.sort((a, b) => a.name.localeCompare(b.name))
									.map(user => {
										return (
											<Tooltip
												title={
													"Rediriger à l'utilisateur " +
													user.name +
													" " +
													user.surname +
													" | " +
													user._id
												}
												key={user._id}
											>
												<Chip
													style={{ textTransform: "capitalize" }}
													label={user.name + " " + user.surname}
													color={currentUser === user._id ? "primary" : "default"}
													clickable={true}
													onClick={() => handleUserRedirection(selectedConversation, user)}
												/>
											</Tooltip>
										);
									})}
							</span>
						</>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseUsersRedirectionDialog} color="primary">
						{`${i18n.t("COC.Cancel")}`}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
);

const MemoizedRedirectionDialogUsers = memo(RedirectionDialogUsers);
export default MemoizedRedirectionDialogUsers;
