import C from "../../constants/assistants.js";

const assistantsReducers = function (currentState = {}, action) {
	switch (action.type) {
		case C.UPDATE_ASSISTANTS:
			return action.payload;

		case C.UPDATE_ASSISTANT: {
			let assistantID = action.payload.assistantID;
			let assistantName = action.payload.assistantName;

			return currentState.map(assistant =>
				assistant.assistantID === assistantID
					? { ...assistant, description: assistantName }
					: assistant
			);
		}

		default:
			return currentState;
	}
};

export default assistantsReducers;
