import assistantsReducers from "./assistantsReducers";
import userStatusReducers from "./userStatusReducers";
import selectedAssistantReducers from "./selectedAssistantReducers";
import cockpitReducers from "./cockpitReducers";
import routesReducers from "./routesReducers";
import engageReducers from "./engageReducers";
import errorsReducer from "./errorsReducer";
import assistantconfigReducers from "./assistantconfigReducers";
import configCatalogReducers from "./configCatalogReducers";
import labelsReducers from "./labelsReducers";
import userGroupReducers from "./userGroupReducers";
import releaseNotesReducers from "./releaseNotesReducers";
import s3 from "./s3Reducers";
import snackMsgs from "./snackMsgsReducers";
import ws from "./wsReducers";
import notification from "./notificationReducers";
import conversation from "./conversationReducers";
import answers from "./answersReducers";
import filters from "./filtersReducers";
import profile from "./profileReducers";

// import conversationReducers from "./conversationReducers";

const rootReducer = {
	assistants: assistantsReducers,
	userStatus: userStatusReducers,
	selectedAssistantID: selectedAssistantReducers,
	cockpit: cockpitReducers,
	routes: routesReducers,
	labels: labelsReducers,
	engage: engageReducers,
	assistantconfig: assistantconfigReducers,
	assistantconfigCatalog: configCatalogReducers,
	error: errorsReducer,
	userGroup: userGroupReducers,
	releaseNotes: releaseNotesReducers,
	s3,
	snackMsgs,
	ws,
	notification,
	conversation,
	answers,
	filters,
	profile
};

export default rootReducer;
