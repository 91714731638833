const attachmentPlugin = ({ handleAddAttachment }) => ({
	// @Required @Unique
	name: "add_attachment",
	// @Required
	display: "command",
	// @options
	// * You can also set from the button list
	// HTML title attribute (tooltip) - default: plugin's name
	title: "Ajout d'une pièce jointe",
	// HTML to be append to button (icon)
	// Recommend using the inline svg icon. - default: "<span class="se-icon-text">!</span>"
	innerHTML: '<i class="fa fa-paperclip">',
	// The class of the button. - default: "se-btn"
	// "se-code-view-enabled": It is not disable when on code view mode.
	// "se-resizing-enabled": It is not disable when on using resizing module.
	buttonClass: "se-btn se-tooltip se-customClass",

	// @Required
	add: function (core, targetElement) {
		const context = core.context;
		// const rangeTag = core.util.createElement("div");
		// core.util.addClass(rangeTag, "__se__format__range_custom");

		// @Required
		// Registering a namespace for caching as a plugin name in the context object
		context.customCommand = {
			targetButton: targetElement
			// tag: rangeTag
		};
	},
	action: function () {
		handleAddAttachment();
	}
});

export default attachmentPlugin;
