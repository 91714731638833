import lod_ from "lodash";
import C from "../../constants/cockpit.js";
import store from "../store.js";
import axios from "axios";
// require("dotenv").config();

const endPoint = process.env.REACT_APP_AMBACK;

const actions = {
	doAnAction: function (contact, actionType, expectFile, language, successCB, failCB) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			let agentSender = {
				name: `${user.name || ""} ${user.given_name || ""}`,
				uid: user.email
			};
			let currentAssistantID = store.getState().selectedAssistantID;

			let data = {
				assistantID: currentAssistantID,
				agentSender,
				contact,
				language,
				actionType
			};
			return dispatch => {
				let options = {
					url: endPoint + "/api/v1/callTabActionContact",
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
						// Accept: 'application/pdf',
					},
					method: "POST",
					data: data
				};

				if (expectFile) {
					options.responseType = "arraybuffer";
				}

				axios(options)
					.then(res => {
						if (res.status === 200) {
							if (expectFile) {
								const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
								const link = document.createElement("a");
								link.href = downloadUrl;
								link.setAttribute("download", res.headers["content-language"]);

								document.body.appendChild(link);
								link.click();
								link.remove();
							}

							// START -> OLD CODE
							let payload = {
								res: res.data
							};
							dispatch({
								//TODO : this do nothing, to remove ? 16/06/22
								type: C.DOWNLOAD_ZIP,
								payload: payload
							});
							if (successCB) {
								successCB(expectFile);
							}
							// END -> OLD CODE
						}
					})
					.catch(err => {
						if (err && err.response && err.response.data) {
							let error;

							if (expectFile) {
								error = JSON.parse(new TextDecoder("utf-8").decode(err.response.data));
							} else {
								error = err.response.data;
							}
							let errorCode = error.errorCode;

							if (failCB) {
								failCB(errorCode);
							}
						} else {
							if (failCB) {
								failCB("UNKNOWN_ERROR");
							}
						}
					});
			};
		}
	}
};

export default actions;
