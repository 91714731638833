import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(1),
		width: "100%",
		display: "flex",
		flexDirection: "column"
	},
	inputForm: {
		alignSelf: "flex-end"
	},
	customTypogr: {
		paddingTop: "50px"
	}
}));
export default useStyles;
