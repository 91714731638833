import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withTranslation } from "react-i18next";
import userStatusActions from "../../redux/actions/userStatusActions";
import { Box, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import i18n from "i18n";
import lod_ from "lodash";
import { sanitize } from "dompurify";
import { parseHtml } from "./../../helpers/utilities";

function DisplayReleaseNote(props) {
	const [isReleaseNoteHiddenSelected, setIsReleaseNoteHiddenSelected] = React.useState(
		props.checked
	);
	const [contentToDisplay, setContentToDisplay] = React.useState(`${i18n.t("RN.empty")}`);
	const [titleToDisplay, setTitleToDisplay] = React.useState(`${i18n.t("RN.emptyTitle")}`);

	let releaseNotesList = props.releaseNotes;
	let userLanguage = [props.userLanguage];

	if (releaseNotesList === { undefined }) {
		setContentToDisplay("Pas de Release Notes");
	}

	useEffect(() => {
		if (releaseNotesList && userLanguage) {
			if (lod_.has(releaseNotesList, "content")) {
				let contentToDisplay = releaseNotesList.content[userLanguage];
				setContentToDisplay(contentToDisplay);
				setTitleToDisplay(`${releaseNotesList.title}`);
			}
		}
	}, [releaseNotesList]);

	useEffect(() => {
		if (props.isCockpitLastReleaseNotesView === true) {
			setIsReleaseNoteHiddenSelected(true);
		}
		if (props.isCockpitLastReleaseNotesView === undefined) {
			setIsReleaseNoteHiddenSelected(false);
		}
	}, [props.releaseNotesDialogStatus]);

	const toggleReleaseNote = event => {
		const value = event.target.value;
		if (isReleaseNoteHiddenSelected === false) {
			setIsReleaseNoteHiddenSelected(true);
		} else {
			setIsReleaseNoteHiddenSelected(false);
		}
	};

	const handleClose = () => {
		props.handleCloseDisplayReleaseNotes(isReleaseNoteHiddenSelected, releaseNotesList.date);
	};

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={props.open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{titleToDisplay}</DialogTitle>
			<DialogContent style={{ height: "65vh", display: "flex", flexDirection: "column" }} dividers>
				<Box display="flex" alignItems="center" justifyContent="center" width={1}>
					<Tabs value={0} indicatorColor="primary" textColor="primary">
						<Tab label={`${i18n.t("RN.version")} ${releaseNotesList.date}`} />
					</Tabs>
				</Box>
				<Box display="flex" alignItems="center" justifyContent="flex-start" marginTop="70px">
					{parseHtml(sanitize(contentToDisplay))}
				</Box>
			</DialogContent>
			<DialogActions>
				<Box
					display="flex"
					flexDirection="row"
					flexGrow="1"
					alignItems="center"
					justifyContent="flex-start"
				>
					<IconButton onClick={toggleReleaseNote}>
						<Checkbox checked={isReleaseNoteHiddenSelected} />
					</IconButton>
					<p style={{ marginBottom: 0 }}>{`${i18n.t("RN.displayReleaseNotes")}`}</p>
				</Box>
				<Button onClick={handleClose} color="primary" style={{ marginRight: 27 }}>
					{`${i18n.t("RN.confirm")}`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		userStatusActions: bindActionCreators(userStatusActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(DisplayReleaseNote);
