import { Tab, Tabs } from "@mui/material";
import i18n from "i18n";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export const ColdTabInfoTabs = ({ handleRightTabChange }) => {
	const { selectedConversation } = useSelector(state => state.cockpit);
	useEffect(() => {
		handleRightTabChange(null, 0);
	}, [selectedConversation?.header?.fID]);
	return (
		<Tabs
			value={0}
			indicatorColor="primary"
			textColor="primary"
			variant="scrollable"
			scrollButtons="auto"
		>
			<Tab style={{ minWidth: 20, fontSize: "12px" }} label={i18n.t("COC.File")} />
		</Tabs>
	);
};
