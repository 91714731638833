import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		marginBottom: "10px",
		marginTop: "10px",
		borderRadius: "1%"
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: "33.33%",
		flexShrink: 0
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: "black"
	},
	AccordionDetails: {
		padding: "0px"
	},
	customAccordionSummary: {
		paddingLeft: "16px",
		paddingRight: "0px",
		width: "90%"
	},

	addAnswer: {
		display: "flex",
		alignItems: "center"
	}
}));
export default useStyles;
