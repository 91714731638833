import lod_ from "lodash";

/**
 * findValueInDict find a value in an object with a given path
 * @param {Object} object dict to seek
 * @param {string} path the path to find the value we seeek in the object, with format key1.[2].key3.key4
 */
function findValueInDict(object, path) {
	try {
		let steps = path.split(".");

		for (let step of steps) {
			if (step[0] == "[" && step[step.length - 1] == "]") {
				// Position in array
				let array_idx = parseInt(step.replace("[", "").replace("]", ""));

				object = object[array_idx];
			} else {
				// Key in dict
				// TODO: Take care -> object[step] can be undefined if the value is not found in the path
				// it may be easier to use lod_.has(object, path) ans then do the assignement or return undefined

				object = object[step];
			}
		}

		return object;
	} catch (error) {
		return undefined;
	}
}

/**
 * Recursive function that remove every field (key+value) on a JSON with value === null/undefined/emptyString
 *
 * @param {*} obj : The JSON object on which the process is carried out
 */
function removal(obj) {
	Object.keys(obj).forEach(key => {
		if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
			delete obj[key];
		} else if (obj[key].constructor === Object) {
			removal(obj[key]);
		}
	});
}

/**
 * replaceInText replace tags like this <?tag.example> by the value in the context
 * @param {String} text contains the template with possibly fields to replace
 * @param {Object} context an object with all value for replacement
 * @param {Boolean} isJson is true if the text is a json, usefull to treat string (add "" in json, not elsewhere)
 */
export function replaceInText(text, context, isJson, removeEmpty = true) {
	let res = {};

	let values = {};
	let i = 0;
	let tags = [];

	// eslint-disable-next-line no-useless-escape
	let t = text.replace(/<\?\s*([^<^>\/]+)./g, (match, tag) => {
		tags.push(tag.trim());
		i += 1;
		return `\${${i - 1}}`;
	});
	i = 0;

	for (let tagAction of tags) {
		values[i + ""] = tagAction;
		i += 1;
	}

	let valuesEval = new Array(values.length);
	for (let key in values) {
		let isOptional = false;
		let splited = values[key].split(",");
		valuesEval[key] = findValueInDict(context, splited[0]);
		if (!lod_.isNil(splited[1]) && splited[1] === "optional") {
			isOptional = true;
		}
		if (valuesEval[key] === undefined) {
			let error = `Couldn't find key ${splited[0]}, in replaceInText`;
			if (!isOptional) {
				if (!res.error) {
					res.error = "";
				}
				res.error += error;
			}
		}

		// Yes if not undefined, if null consider that is found
		const foundValue = typeof valuesEval[key] !== "undefined";

		if (isJson) {
			t = t.replace(`\${${key}}`, foundValue ? JSON.stringify(valuesEval[key]) : `""`);
		} else {
			t = t.replace(`\${${key}}`, foundValue ? valuesEval[key] : "");
		}
	}

	if (isJson && removeEmpty) {
		// delete undefined, null or empty string
		try {
			t = JSON.parse(t);
			removal(t);
			t = JSON.stringify(t);
		} catch (error) {
			res["error"] = "Invalid resulting JSON";
		}
	}
	res["data"] = t;
	return res;
}
