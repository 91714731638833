/* eslint-disable default-case */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-fallthrough */
import { Box, Grid, Slide, Snackbar } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import lod_ from "lodash";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, compose } from "redux";
import C from "../../../constants/cockpit.js";
import cockpitActions from "../../../redux/actions/cockpitActions";
import engageActions from "../../../redux/actions/engageActions";
import {
	assistantHasApiCold,
	getChannelsByAssistant,
	getLanguagesByAssistant,
	getTabs
} from ".././../../helpers/utilities";
import Convs from "./Convs";
import ConvsContainerTabs from "./ConvsContainerTabs";
import Filters from "./Filters/Filters";
import { IntentFilterDialog } from "./Filters/IntentFilter.js";
import SearchFilter from "./Filters/SearchFilter";

import { getConvsContainerSelector } from "redux/selectors/cockpitSelectors.js";
import { GroupFilterDialog } from "./Filters/GroupFilter.js";
import { UserFilterDialog } from "./Filters/UserFilter.js";
import Typography from "@material-ui/core/Typography";
import { FiltersActions } from "./Filters/FiltersActions.js";
import { ApplyFiltersToUsersDialog } from "./Filters/ApplyFiltersToUsersDialog.js";

const ConvsContainer = props => {
	const dispatch = useDispatch();
	const {
		conversations,
		leftTab,
		convIntentFilter,
		convGroupFilter,
		convUserFilter,
		convSearchFilter,
		convChannelFilter,
		selectedAssistantID,
		selectedConversation,
		isCockpitArchived
	} = useSelector(getConvsContainerSelector, shallowEqual);
	const { cockpit } = useSelector(state => state.assistantconfig);
	const { auth } = useSelector(state => state.userStatus);
	const [possibleChannels, setPossibleChannels] = React.useState(null);
	const [possibleChannelFilter, setPossibleChannelFilter] = React.useState([]);
	const [possibleLanguageFilter, setPossibleLanguageFilter] = React.useState([]);
	const [intentList, setIntentList] = React.useState([]);
	const [openIntentFilterDialog, setOpenIntentFilterDialog] = useState(false);
	const [users, setUsers] = React.useState([]);
	const [userList, setUserList] = React.useState([]);
	const [openUserFilterDialog, setOpenUserFilterDialog] = useState(false);
	const [groups, setGroups] = React.useState([]);
	const [groupList, setGroupList] = React.useState([]);
	const [snackbarStatus, setSnackbarStatus] = React.useState({ open: false, message: "" });

	/* Reset All filters when switching assistantID */
	useEffect(() => {
		//setOpenIntentFilterDialog(false);
		setPossibleChannels(null);
	}, [selectedAssistantID]);

	useEffect(() => {
		if (props.assistantconfig && props.assistantconfig.channels) {
			let channelByAssistant = props.assistantconfig.channels.filter(a => a.engage === true);
			if (channelByAssistant.length > 0) {
				if (channelByAssistant[0] !== undefined) {
					let engageableChannels = [];
					channelByAssistant.forEach(aChannel => {
						if (!engageableChannels.includes(aChannel.code)) {
							engageableChannels.push(aChannel);
						}
					});
					setPossibleChannels(engageableChannels);
					// eslint-disable-next-line
					if (engageableChannels.length == 1) {
						props.cockpitActions.saveDefaultChannel(engageableChannels[0], false);
					}
				}
			}
		}
	}, [props.assistantconfig]);

	useEffect(() => {
		if (
			props.cockpit.selectedConversation &&
			props.cockpit.selectedConversation.version !== "cold-conv-1.0" // PIKA: We do not want the history of the conv. Esp. the contact.FRU is reworked by cold
		) {
			props.cockpitActions.getHistoryCount(props.cockpit.selectedConversation);
		}
	}, [props.cockpit.selectedConversation?.header?.fID]);

	// Intent Filter Button ON/OFF
	useEffect(() => {
		if (props.selectedAssistantID) {
			let intentsConfig = props.assistantconfig.intents;
			if (!lod_.isNil(intentsConfig)) {
				let intentsName = intentsConfig.map(intent => intent.name);
				setIntentList(intentsName);
			}
		}
	}, [selectedAssistantID, props.assistantconfig.intents]);

	useEffect(() => {
		setGroups(props.userGroup.groups);
	}, [props.userGroup.groups]);

	useEffect(() => {
		let groupIdList = groups.map(group => group._id);
		setGroupList(groupIdList);
	}, [groups]);

	useEffect(() => {
		setUsers(props.userGroup.users);
	}, [props.userGroup.users]);

	useEffect(() => {
		let userIdList = users.map(user => user._id);
		setUserList(userIdList);
	}, [users]);

	/**
	 * TODO : Why nothing re supervisor in these 2 fonctions ?
	 */
	useEffect(() => {
		if (props.cockpit.convAgentFilter && leftTab === 2) {
			dispatch({ type: C.EMPTY_CONV_STATE, value: "resolved" });
		}
	}, [props.cockpit.convAgentFetchedResolved, props.cockpit.convAgentFilter]);

	useEffect(() => {
		if (props.cockpit.convAgentFilter) {
			if (!props.cockpit.convAgentFetchedOngoing) {
				props.cockpitActions.convAgentFetchedOngoing(true);
			}
		}
	}, [props.cockpit.convAgentFetchedOngoing, props.cockpit.convAgentFilter]);

	useEffect(() => {
		let filtersSaved;
		if (auth?.user?.cockpitConvFiltersSaved) {
			filtersSaved = auth?.user?.cockpitConvFiltersSaved.filter(
				filter => filter.assistantID === selectedAssistantID
			);
		}
		if (cockpit) {
			if (!cockpit?.ongoing_list_display_mine && filtersSaved) {
				handleChangeAgentFilter(filtersSaved[0]?.filters?.convAgentFilter);
				handleChangeSupervisorFilter(filtersSaved[0]?.filters?.convSupervisorFilter);
			} else if (cockpit?.ongoing_list_display_mine && filtersSaved) {
				handleChangeAgentFilter(filtersSaved[0]?.filters?.convAgentFilter);
				handleChangeSupervisorFilter(filtersSaved[0]?.filters?.convSupervisorFilter);
			} else if (!filtersSaved) {
				handleChangeAgentFilter(false);
				handleChangeSupervisorFilter(false);
			}
		}
	}, [props.assistantconfig]);

	useEffect(() => {
		if (leftTab === 2) {
			props.cockpitActions.convAgentFetchedResolved(false);
			props.cockpitActions.convAgentFetchedOngoing(false);
		}
	}, [leftTab]);

	useEffect(async () => {
		/**
		 * TODO: PIKA - Maybe we can change the value when we change assistant?
		 * by commenting props.cockpit.convAscFilter === null
		 * --> if no default in assistantConfig the user user keeps its value
		 */
		if (/* props.cockpit.convAscFilter === null &&  */ props.assistantconfig.length !== 0) {
			// On initialization only
			if (
				props.assistantconfig &&
				props.assistantconfig.cockpit &&
				props.assistantconfig.cockpit.conv_list_display_order
			) {
				if (props.assistantconfig.cockpit.conv_list_display_order === "desc") {
					props.cockpitActions.changeAscFilter(true);
				}
			} else {
				props.cockpitActions.changeAscFilter(false);
			}
		}
		setPossibleChannelFilter(getChannelsByAssistant(props.assistantconfig));
		setPossibleLanguageFilter(getLanguagesByAssistant(props.assistantconfig));
	}, [props.assistantconfig]);

	// Load conversations count when user change assistant
	useEffect(() => {
		if (auth?.user) {
			dispatch(cockpitActions.getConversationsCountByQuery(selectedAssistantID));
		}
	}, [selectedAssistantID]);

	// Load cold conversations count
	useEffect(() => {
		if (auth?.user && props.assistantconfig.APIs && isCockpitArchived) {
			dispatch(cockpitActions.getConversationsCountByQuery(selectedAssistantID, true));
		}
	}, [isCockpitArchived]);

	const handleTabChange = async newTab => {
		if (selectedConversation && isCockpitArchived)
			dispatch({
				type: C.CLEAR_CONVERSATION,
				payload: null
			});

		dispatch({
			type: C.UPDATE_CONVERSATIONS,
			payload: []
		});

		dispatch({
			type: C.SET_IS_COLD,
			payload: newTab === 4 && assistantHasApiCold()
		});

		props.cockpitActions.setLeftTab(newTab);

		dispatch({ type: C.EMPTY_CONV_STATE, value: getTabs[newTab] });
	};

	// Handle Snackbar
	const MySnackbar = ({ transition, direction, timeout, ...rest }) => (
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			TransitionComponent={{ slide: Slide }[transition]}
			TransitionProps={{ direction, timeout }}
			{...rest}
		/>
	);

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarStatus({ open: false, message: "" });
	};

	const handleSelectConversation = selectedConv => {
		props.cockpitActions.selectConversation(selectedConv);
	};

	const handleClearConvSearchFilter = () => {
		dispatch({
			type: C.SET_CONV_SEARCH_FILTER,
			payload: ""
		});
	};

	const handleConvChannelFilter = (event, newValue) => {
		if (convChannelFilter === newValue) {
			newValue = null;
		}
		dispatch({
			type: C.SET_CONV_CHANNEL_FILTER,
			payload: newValue
		});
	};
	const handleConvLanguageFilter = (event, newValue) => {
		dispatch({
			type: C.SET_CONV_LANGUAGE_FILTER,
			payload: newValue
		});
	};
	const handleConvSubstateFilter = (event, newValue) => {
		dispatch({
			type: C.SET_CONV_SUBSTATE_FILTER,
			payload: newValue
		});
	};
	const handleConvPriorityFilter = (event, newValue) => {
		dispatch({
			type: C.SET_CONV_PRIORITY_FILTER,
			payload: newValue
		});
	};
	const handleConvSentimentFilter = (event, newValue) => {
		dispatch({
			type: C.SET_CONV_SENTIMENT_FILTER,
			payload: newValue
		});
	};
	const handleConvEscalationFilter = (event, newValue) => {
		dispatch({
			type: C.SET_CONV_ESCALATE_FILTER,
			payload: newValue
		});
	};

	// Filtre OPEN/CLOSE/SAVE INTENT DIAlOG
	const handleOpenIntentFilterDialog = () => {
		//setconvIntentFilter(filters => [...filters]);
		setOpenIntentFilterDialog(true);
	};
	const handleCloseIntentFilterDialog = () => {
		setOpenIntentFilterDialog(false);
	};
	const handleSubmitIntentFilterDialog = filters => {
		dispatch({
			type: C.SET_CONV_INTENT_FILTER,
			payload: filters
		});
		setOpenIntentFilterDialog(false);
	};

	// Filtre OPEN/CLOSE/SAVE USER DIAlOG
	const handleOpenUserFilterDialog = () => {
		//setUserFiltered(filters => [...filters]);
		setOpenUserFilterDialog(true);
	};
	const handleCloseUserFilterDialog = () => {
		setOpenUserFilterDialog(false);
	};
	const handleSubmitUserFilterDialog = filters => {
		//setUserFiltered(filters);
		dispatch({
			type: C.SET_CONV_USER_FILTER,
			payload: filters
		});
		setOpenUserFilterDialog(false);
	};

	// Filtre OPEN/CLOSE/SAVE ENGAGE DIAlOG
	const handleEngagedialog = channel => {
		// New engage Epic https://faibrikplatform.atlassian.net/browse/FBDB-577 for all channels
		const channelCode = channel?.code;

		if (channelCode) {
			dispatch(engageActions.createEngageConversation(channelCode, {}));
		}
	};
	const handleChangeAscFilter = filter => {
		props.cockpitActions.changeAscFilter(filter);
	};
	const handleChangeSupervisorFilter = (filter, noBot = false) => {
		props.cockpitActions.changeSupervisorFilter(filter, noBot);
	};
	const handleChangeAgentFilter = (filter, noBot = false) => {
		props.cockpitActions.changeAgentFilter(filter, noBot);
	};
	return (
		<>
			<Grid item xs={3} data-component-name="ConvsContainer">
				<MySnackbar
					style={{ top: "65px" }}
					open={snackbarStatus.open}
					onClose={handleCloseSnackbar}
					autoHideDuration={3500}
					message={snackbarStatus.message}
					transition="slide"
					direction="left"
					timeout={{
						enter: 2000
					}}
				/>

				<Box component="div" width="100%" height="97vh" display="flex" flexDirection="column">
					<ConvsContainerTabs
						handleTabChange={handleTabChange}
						nbTab={conversations.length}
						nbWaitingConvs={props.cockpit.nbWaitingConvs}
						nbOngoingConvs={props.cockpit.nbOngoingConvs}
						nbResolvedConvs={props.cockpit.nbResolvedConvs}
						nbLiveConvs={props.cockpit.nbLiveConvs}
						nbArchivedConvs={props.cockpit.nbArchivedConvs}
						nbHiddenConvs={props.cockpit.nbHiddenConvs}
					/>

					<SearchFilter
						convSearchFilter={convSearchFilter}
						handleClearConvSearchFilter={handleClearConvSearchFilter}
						handleEngagedialog={handleEngagedialog}
						possibleChannels={possibleChannels}
					/>
					<Box component="div" width="100%" display="flex">
						<Filters
							role={props.user.role}
							// Filtre Ordre Chronologique
							convAscFilter={props.cockpit.convAscFilter}
							handleChangeAscFilter={handleChangeAscFilter}
							// Filtre Channel
							possibleChannelFilter={possibleChannelFilter}
							handleConvChannelFilter={handleConvChannelFilter}
							// Filtre Langue
							possibleLanguageFilter={possibleLanguageFilter}
							handleConvLanguageFilter={handleConvLanguageFilter}
							// Filtre Priorité
							handleConvPriorityFilter={handleConvPriorityFilter}
							// Filtre Sentiment
							handleConvSentimentFilter={handleConvSentimentFilter}
							// Filtre Statut
							handleConvSubstateFilter={handleConvSubstateFilter}
							// Filtre Escalade
							handleConvEscalationFilter={handleConvEscalationFilter}
							// Filtre Intent
							intentList={intentList}
							//handleOpenIntentFilterDialog={handleOpenIntentFilterDialog}
							// Filtre User
							userList={userList}
							//handleOpenUserFilterDialog={handleOpenUserFilterDialog}
							// Filtre Group
							groupList={groupList}
							// Filtre All/Personal Convs
							handleChangeAgentFilter={handleChangeAgentFilter}
							handleChangeSupervisorFilter={handleChangeSupervisorFilter}
						/>
					</Box>

					<Divider />
					{/*} <LinearProgress variant="determinate" value={currentProgress} />*/}
					<Convs
						handleSelectConversation={handleSelectConversation}
						intentList={intentList}
						intentOFF={convIntentFilter.length === 0}
						groupOFF={convGroupFilter.length === 0}
						userOFF={convUserFilter.length === 0}
					/>
				</Box>
			</Grid>

			<IntentFilterDialog />

			<UserFilterDialog />

			<GroupFilterDialog />

			<ApplyFiltersToUsersDialog />
		</>
	);
};

const mapStateToProps = state => {
	return state;
};

const mapDispatchToProps = dispatch => {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
};

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(ConvsContainer);
