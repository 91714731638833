import { createSlice } from "@reduxjs/toolkit";
import lod_ from "lodash";

const initialState = {
	connected: false,
	unavailable: true, // PIKA : 2022.10.08 - change to true to avoid  issues with CM-LC computation
	humanRequest: false,
	onlineUsers: {},
	compositeUploadUrl: null
};

export const ws = initialState;

const slice = createSlice({
	name: "ws",
	initialState,
	reducers: {
		reset: () => {
			return initialState;
		},
		clear: state => {
			state.onlineUsers = {};
		},
		connected: state => {
			state.connected = true;
		},
		disconnected: state => {
			state.connected = false;
			state.id = "";
		},
		set_id: (state, action) => {
			state.id = action.payload;
		},
		set_human_request: (state, action) => {
			// check assistantID !
			state.humanRequest = action.payload;
		},
		set_users_status: (state, action) => {
			// check assistantID !
			const user = action.payload.user;
			let onlineUsers = lod_.cloneDeep(state.onlineUsers);
			if (user) onlineUsers[user._id] = user;
			return { ...state, onlineUsers };
		},
		update_users_status: (state, action) => {
			// check assistantID !
			const user = action.payload.user;
			const operation = action.payload.action;
			let onlineUsers = lod_.cloneDeep(state.onlineUsers);
			// never ever add a user on this event otherwise some fAIbrik user will be visible
			if (user && onlineUsers[user._id]) {
				if (operation === "update") {
					onlineUsers[user._id].unavailable = user.unavailable;
				} else if (operation === "remove") {
					delete onlineUsers[user._id];
				}
			}
			return { ...state, onlineUsers };
		},
		clickToUpload: (state, action) => {
			state.compositeUploadUrl = action.payload.compositeUploadUrl;
		},
		delete_attachments: (state, action) => {
			return { ...state, compositeUploadUrl: null };
		}
	}
});

export default slice.reducer;
