import store from "../../redux/store";

export function getMustForceConversationSelection(event) {
	try {
		const standardCondition = event?.forceConversationSelection;
		const phoneCondition =
			event?.content?.header?.substate === "pickup" &&
			event?.content?.agent?.uid === store.getState()?.userStatus?.auth?.user?.email;
		return standardCondition || phoneCondition;
	} catch (error) {
		return false;
	}
}
