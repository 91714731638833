import React from "react";
import MuiPhoneNumber from "material-ui-phone-number";

import { Box } from "@material-ui/core";

export default function Phone(props) {
	const {
		user,
		assistantConfig,
		name,
		value,
		onChange,
		label,
		color = "primary",
		FRU,
		...others
	} = props;

	{
		/* TO DO DELETE THIS CODE IF TEST AVAYA IS SUCCESS !  */
	}
	// const dispatch = useDispatch();
	// const actions = assistantConfig?.actions;
	// const initialDraft = {
	// 	engageLinkContact: value
	// };

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	{
		/* TO DO DELETE THIS CODE IF TEST AVAYA IS SUCCESS !  */
	}
	// function handleCallOutAction(value) {
	// 	const phone = value;
	// 	const actions = assistantConfig?.actions;
	// 	const triggerCallOutAction = actions.find(action => action.action === "triggerCallOut");

	// 	// dispatch(engageActions.createEngageConversation("PH01", initialDraft));
	// 	// if (initialDraft?.engageLinkContact) {
	// 	// 	// This is an engage from contact page, then redirect to cockpit page
	// 	// 	window.location.href = "/admin/cockpit";
	// 	// }

	// 	if (triggerCallOutAction) {
	// 		const { config } = triggerCallOutAction;
	// 		const poolData = { user, phone };
	// 		const phoneNumber = lod_.get(poolData, config.phone);
	// 		const avayaId = lod_.get(poolData, config.avayaId);

	// 		const data = {
	// 			header: {
	// 				assistantID: assistantConfig?.assistantID,
	// 				fID: "CALL-OUT"
	// 			},
	// 			payload: {
	// 				phoneNumber,
	// 				avayaId,
	// 				FRU
	// 			}
	// 		};

	// 		dispatch(cockpitActions.launchActionFrontV2("triggerCallOut", data));
	// 		window.location.href = "/admin/cockpit";
	// 	} else {
	// 		// console.error("triggerCallOut action not found");
	// 	}
	// }

	return (
		<Box display="flex" alignItems="center">
			<MuiPhoneNumber
				variant="outlined"
				fullWidth
				name={name}
				label={label}
				value={value}
				onChange={e => onChange(convertToDefEventParam(name, e))}
				{...others}
				style={{ flex: 1, marginRight: 1 }}
			/>
			{/* TO DO DELETE THIS CODE IF TEST AVAYA IS SUCCESS !  */}
			{/* {actions && actions.some(action => action.action === "triggerCallOut") && (
				<Tooltip title={label} placement="top">
					<IconButton
						disabled={!value}
						style={{ border: "1px solid #E0E0E0", borderRadius: "5px", marginLeft: 10 }}
						onClick={() => handleCallOutAction(value)}
					>
						<PhoneIcon style={{ fontSize: 30 }} />
					</IconButton>
				</Tooltip>
			)} */}
		</Box>
	);
}
