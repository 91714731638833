import React, { useEffect, useState } from "react";
import lod_ from "lodash";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Box } from "@material-ui/core";

export function DisplayReviewChoices(props) {
	const { conversation } = props;
	const [userChoices, setUserChoices] = useState([]);
	const [alignment, setAlignment] = React.useState(0);

	const handleAlignment = (event, newAlignment) => {
		if (newAlignment === null) return;
		setAlignment(newAlignment);
	};

	useEffect(() => {
		if (
			conversation &&
			lod_.has(conversation, "body.output.user") &&
			Array.isArray(conversation.body.output.user)
		) {
			let user = conversation.body.output.user;
			let arrReview = [];
			user.forEach((e, index) => {
				let str = "";
				e.forEach((eachSentence, index) => {
					str += eachSentence.content;
				});
				if (str !== "") arrReview.push(str);
			});
			setUserChoices(arrReview);
		}
	}, [conversation]);

	return (
		<>
			<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" m={2}>
				<ToggleButtonGroup
					value={alignment}
					exclusive
					onChange={handleAlignment}
					aria-label="text alignment"
					style={{ height: "20px" }}
				>
					{userChoices.length > 0 &&
						userChoices.map((e, index) => {
							return (
								<ToggleButton value={index} key={index} aria-label="left aligned">
									<p style={{ margin: "0 0 0 0" }}>{index + 1}</p>
								</ToggleButton>
							);
						})}
				</ToggleButtonGroup>
				<Box pt={2}>
					<p>{userChoices[alignment] ? userChoices[alignment] : ""}</p>
				</Box>
			</Box>
		</>
	);
}
