export default {
	GET_CONFIG_CATALOG: "GET_CONFIG_CATALOG",
	GET_CONFIG: "GET_CONFIG",
	GET_CHANNEL: "GET_CHANNEL",
	SAVE_CHANNEL: "SAVE_CHANNEL",
	UPDATE_CHANNEL: "UPDATE_CHANNEL",
	DELETE_CHANNEL: "DELETE_CHANNEL",
	SAVE_CHART: "SAVE_CHART",
	UPDATE_CHART: "UPDATE_CHART",
	DELETE_CHART: "DELETE_CHART",
	SAVE_API: "SAVE_API",
	UPDATE_API: "UPDATE_API",
	DELETE_API: "DELETE_API",
	GET_INTENTS: "GET_INTENTS",
	GET_CONTEXT: "GET_CONTEXT"
};
