import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { Dialog, DialogActions, DialogContent, Icon, makeStyles, Tooltip } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListIcon from "@material-ui/icons/List";
// Redux
import i18n from "i18next";
import { wsEvent } from "redux/actions/ws";
import { ChatBubble } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { Button, IconButton } from "@mui/material";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { getBackgroundColor } from "helpers/utilities";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import SearchIcon from "@material-ui/icons/Search";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import { getWsSelector } from "redux/selectors/wsSelectors";
import { NavLink } from "react-router-dom";

const LiveChatButton = props => {
	//const classes = useStyles();

	// Redux
	const dispatch = useDispatch();
	const role = useSelector(state => state?.userStatus?.auth?.user?.role);
	const { unavailable, onlineUsers } = useSelector(getWsSelector, shallowEqual);
	const { channels } = useSelector(state => state.assistantconfig);
	const email = useSelector(state => state?.userStatus?.auth?.user?.email);
	const [open, setOpen] = useState(false);
	const [search, setSearch] = React.useState("");
	const { classes, navLinkClasses, itemText } = props;
	// Needed for computing states
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);

	const handleClose = () => {
		setOpen(false);
	};

	const changeAvailability = userMail => {
		if (onlineUsers[userMail]) {
			dispatch(
				wsEvent({
					action: "updateAgentAvailability",
					email: userMail,
					unavailable: !onlineUsers[userMail].unavailable,
					assistantID: selectedAssistantID
				})
			);
		}
	};

	const toggleUserSelectAll = toggle => {
		dispatch(
			wsEvent({
				action: "updateAgentsAvailability",
				unavailable: toggle,
				assistantID: selectedAssistantID
			})
		);
	};

	const handleSearchChange = event => {
		setSearch(event.target.value);
	};

	function switchLiveChatState() {
		dispatch(
			wsEvent({
				action: "updateAgentAvailability",
				email,
				unavailable: !unavailable,
				assistantID: selectedAssistantID
			})
		);
	}

	return (
		<>
			<Tooltip
				title={unavailable ? i18n.t("COC.goOnline") : i18n.t("COC.goOffline")}
				placement="bottom"
			>
				<ListItem className={classes.item}>
					<NavLink className={navLinkClasses} to={"#"} onClick={() => switchLiveChatState()}>
						<Icon className={classes.itemIcon} style={{ color: "white" }}>
							<ChatBubble />
						</Icon>
						<ListItemText
							className={itemText}
							disableTypography={true}
							primary={unavailable ? i18n.t("COC.online") : i18n.t("COC.offline")}
							style={{ color: "white" }}
						/>
					</NavLink>
				</ListItem>
			</Tooltip>
			<Tooltip title={i18n.t("COC.onlineUsers")} placement="bottom">
				<ListItem className={classes.item}>
					<NavLink className={navLinkClasses} to={"#"} onClick={() => setOpen(true)}>
						<Icon className={classes.itemIcon} style={{ color: "white" }}>
							<ListIcon />
						</Icon>
						<ListItemText
							className={itemText}
							disableTypography={true}
							primary={i18n.t("COC.onlineUsers")}
							style={{ color: "white" }}
						/>
					</NavLink>
				</ListItem>
			</Tooltip>
			<Dialog open={open} aria-labelledby="form-dialog-title" fullWidth>
				<DialogContent
					dividers
					style={{ height: "65vh", display: "flex", flexDirection: "column" }}
				>
					<Box display="flex" alignItems="center" justifyContent="center" width={1}>
						<Tabs
							value={0}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="auto"
						>
							<Tab label={i18n.t("COC.Users")} />
							{props.showUsers && <Tab label={i18n.t("COC.Users")} />}
						</Tabs>
					</Box>
					<Box display="flex" flexDirection="row" alignItems="center">
						<TextField
							value={search}
							style={{ width: "90%", marginTop: "3%" }}
							label=""
							variant="outlined"
							size="small"
							onChange={handleSearchChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								)
							}}
						/>
						{/* Checkbox for all users */}
						{(role === "supervisor" || role === "soloUser") && (
							<>
								<Tooltip placement="top" title={i18n.t("COC.deconnectAll")}>
									<IconButton style={{ marginLeft: 1 }} onClick={() => toggleUserSelectAll(true)}>
										<FiberManualRecordIcon style={{ color: "black" }} />
									</IconButton>
								</Tooltip>
								<Tooltip placement="top" title={i18n.t("COC.connectAll")}>
									<IconButton style={{ marginLeft: 1 }} onClick={() => toggleUserSelectAll(false)}>
										<FiberManualRecordIcon style={{ color: "green" }} />
									</IconButton>
								</Tooltip>
							</>
						)}
					</Box>
					{/* Mapping to display users list */}
					<List className={classes.root} subheader={<li />}>
						{Object.values(onlineUsers)
							?.filter(user => {
								/*if (user._id === props.userStatus.auth.user.email) {
									return false;
								} else {*/
								if (search.length >= 0) {
									let fullSearch = (user.name || "") + " " + (user.surname || "") + " " + user._id;
									return fullSearch.toLowerCase().includes(search.toLowerCase());
								} else {
									return true;
								}
								//}
							})
							.map((user, index) => (
								<React.Fragment key={`user-${index}`}>
									<ListItem>
										<ListItemText
											primary={
												<React.Fragment>
													{`${user.surname || ""} ${user.name || ""} - `}
													<Typography component="span" variant="body2" color="textSecondary">
														{user._id}
													</Typography>
												</React.Fragment>
											}
										/>
										<ListItemSecondaryAction>
											<Tooltip
												placement="top"
												title={
													role === "supervisor" || role === "soloUser"
														? user.unavailable
															? i18n.t("COC.setOnline")
															: i18n.t("COC.setOffline")
														: user.unavailable
														? i18n.t("COC.offline")
														: i18n.t("COC.online")
												}
											>
												{role === "supervisor" || role === "soloUser" ? (
													<IconButton
														edge="end"
														onClick={() => {
															changeAvailability(user._id);
														}}
													>
														<FiberManualRecordIcon
															style={{ color: getBackgroundColor(channels, user.unavailable) }}
														/>
													</IconButton>
												) : (
													<FiberManualRecordIcon
														style={{ color: getBackgroundColor(channels, user.unavailable) }}
													/>
												)}
											</Tooltip>
										</ListItemSecondaryAction>
									</ListItem>
									<Divider />
								</React.Fragment>
							))}
					</List>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Quitter
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

function mapStateToProps(state) {
	return state;
}

export default compose(withTranslation(), connect(mapStateToProps))(LiveChatButton);
