import DE from "../assets/img/flags/DE.png";
import EN from "../assets/img/flags/EN.png";
import ES from "../assets/img/flags/ES.png";
import FR from "../assets/img/flags/FR.png";
import IT from "../assets/img/flags/IT.png";
import NL from "../assets/img/flags/NL.png";
import PT from "../assets/img/flags/PT.png";
import PL from "../assets/img/flags/PL.png";
import interrogation from "../assets/img/flags/interrogation.svg";

export default function GetFlag(flag) {
	switch (flag) {
		case "DE":
			return DE;
		case "EN":
			return EN;
		case "ES":
			return ES;
		case "FR":
			return FR;
		case "IT":
			return IT;
		case "NL":
			return NL;
		case "PT":
			return PT;
		case "PL":
			return PL;
		default:
			return interrogation;
	}
}

// return (
//     <>
//         <img src={require(`../../assets/img/flags/${props.flag}.svg`)} alt='flags_codes' width="20" height="20" style={{ objectFit: "contain" }} />
//     </>
// );
