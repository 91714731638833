/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

export default props => {
	const errorColor = "#aa2e25";
	const validColor = "#357a38";

	const password = props.password;

	const AtLeast8chars = password.length > 7;

	const AtLeastOneLower = /[a-z]/.test(password);

	const AtLeastOneUpper = /[A-Z]/.test(password);

	const AtLeastOneNumber = /[0-9]/.test(password);

	const AtLeastOneSpecial = /[-+_!@#$%^&*.,?]/.test(password);

	const passwordIsStrong =
		AtLeast8chars && AtLeastOneLower && AtLeastOneUpper && AtLeastOneNumber && AtLeastOneSpecial;

	React.useEffect(() => {
		props.validate(passwordIsStrong);
		// eslint-disable-next-line
	}, [passwordIsStrong]);

	return (
		<Box p={2} m={2} border={1} borderRadius="borderRadius" borderColor="#E0E0E0">
			<Box mb={1}>
				<b>{props.t("EDIT_FORM.psswordStrength.message")}</b>
			</Box>
			<Box>
				{AtLeast8chars ? (
					<CheckIcon style={{ color: validColor }} fontSize="small" />
				) : (
					<CloseIcon style={{ color: errorColor }} fontSize="small" />
				)}
				<span
					style={{
						color: AtLeast8chars ? validColor : errorColor,
						verticalAlign: "top",
						fontWeight: "bold"
					}}
				>
					{props.t("EDIT_FORM.psswordStrength.length")}
				</span>
			</Box>
			<Box>
				{AtLeastOneUpper ? (
					<CheckIcon style={{ color: validColor }} fontSize="small" />
				) : (
					<CloseIcon style={{ color: errorColor }} fontSize="small" />
				)}
				<span
					style={{
						color: AtLeastOneUpper ? validColor : errorColor,
						verticalAlign: "top",
						fontWeight: "bold"
					}}
				>
					{props.t("EDIT_FORM.psswordStrength.capitalLetter")}
				</span>
			</Box>
			<Box>
				{AtLeastOneLower ? (
					<CheckIcon style={{ color: validColor }} fontSize="small" />
				) : (
					<CloseIcon style={{ color: errorColor }} fontSize="small" />
				)}
				<span
					style={{
						color: AtLeastOneLower ? validColor : errorColor,
						verticalAlign: "top",
						fontWeight: "bold"
					}}
				>
					{props.t("EDIT_FORM.psswordStrength.lowercaseLetter")}
				</span>
			</Box>
			<Box>
				{AtLeastOneSpecial ? (
					<CheckIcon style={{ color: validColor }} fontSize="small" />
				) : (
					<CloseIcon style={{ color: errorColor }} fontSize="small" />
				)}
				<span
					style={{
						color: AtLeastOneSpecial ? validColor : errorColor,
						verticalAlign: "top",
						fontWeight: "bold"
					}}
				>
					{props.t("EDIT_FORM.psswordStrength.specialChar")}
				</span>
			</Box>
			<Box>
				{AtLeastOneNumber ? (
					<CheckIcon style={{ color: validColor }} fontSize="small" />
				) : (
					<CloseIcon style={{ color: errorColor }} fontSize="small" />
				)}
				<span
					style={{
						color: AtLeastOneNumber ? validColor : errorColor,
						verticalAlign: "top",
						fontWeight: "bold"
					}}
				>
					{props.t("EDIT_FORM.psswordStrength.number")}
				</span>
			</Box>
		</Box>
	);
};
