/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import i18n from "i18n";
import { Card, CardContent, CardHeader } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		marginBottom: "10px",
		marginTop: "10px",
		borderRadius: "15%"
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		display: "flex",
		flexDirection: "row",
		alignItems: "center"
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: "black"
	},
	AccordionDetails: {
		marginBottom: "10px",
		fontSize: theme.typography.pxToRem(15)
	}
}));

export default function Summary({ conversation }) {
	const classes = useStyles();

	return (
		<Box style={{ padding: "5px" }}>
			<Card>
				<CardHeader
					style={{ paddingBottom: "1px" }}
					avatar={<LibraryBooksIcon />}
					title={i18n.t("COC.convSummary")}
				/>
				<CardContent className={classes.AccordionDetails}>
					{conversation?.meta?.cockpit?.summary}
				</CardContent>
			</Card>
		</Box>
	);
}
