import React from "react";
import { Box } from "@material-ui/core";

import { withTranslation } from "react-i18next";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import LiveChatButton from "./LiveChatButton";
import { ImgSettings } from "./ImgSettings";
import { getWsSelector } from "redux/selectors/wsSelectors";
import cx from "classnames";
import AssistantMenu from "components/Sidebar/AssistantMenu";
import C from "../../constants/cockpit";

function AssistantSettings(props) {
	const { unavailable, humanRequest } = useSelector(getWsSelector, shallowEqual);
	const { classes, state } = props;
	const channels = props.assistantconfig.channels;
	const dispatch = useDispatch();
	function isLiveChatEnabled() {
		if (channels != undefined) {
			for (let channel of channels) {
				if (channel.type === "LC" && channel.active) {
					return true;
				}
			}
		}
		return false;
	}

	function updateDisplayStatus() {
		dispatch({ type: C.UPDATE_CONV_LIST_DISPLAY_STATUS });
	}
	return (
		<>
			<Box display={"flex"} style={{ width: "fit-content" }}>
				<ImgSettings
					unavailable={unavailable}
					humanRequest={humanRequest}
					classes={classes}
					updateDisplayStatus={updateDisplayStatus}
				/>
				<AssistantMenu />
			</Box>

			{isLiveChatEnabled() && (
				<div>
					<LiveChatButton
						classes={classes}
						navLinkClasses={classes.itemLink}
						itemText={
							classes.itemText +
							" " +
							cx({
								[classes.itemTextMini]: state?.miniActive
							})
						}
					/>
				</div>
			)}
		</>
	);
}
function mapStateToProps(state) {
	return state;
}

export default compose(
	withTranslation(),

	connect(mapStateToProps)
)(AssistantSettings);
