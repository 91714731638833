module.exports = {
	redirectCockpitUrlIfNeeded
};

/**
 * Redirect if needed the cockpit to the good version
 * @param {Object} result the assistantConfig information
 */
function redirectCockpitUrlIfNeeded(result) {
	if (
		process.env.REACT_APP_AMBACK.startsWith("http://localhost") ||
		process.env.REACT_APP_AMBACK.startsWith("https://auth-dev-prod3") ||
		process.env.REACT_APP_AMBACK.includes(".faibrikplatform.com")
	)
		return; // Stick to my host

	const redirectCockpitHost = result?.data?.cockpit?.redirectCockpitHost;

	if (redirectCockpitHost !== undefined) {
		let currentHost = window.location.host; // Host is domain + port
		// Compare the actual url with the one in config
		const mustRedirect = currentHost !== redirectCockpitHost;

		if (mustRedirect) {
			window.location.href = `https://${redirectCockpitHost}`;
		}
	}
}
