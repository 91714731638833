import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	info: null,
	error: null,
	initialError: null,
	warn: null,
	coldError: null,
	duration: null
};

export const snackMsgs = initialState;

const slice = createSlice({
	name: "snackMsgs",
	initialState,
	reducers: {
		clear: (state, action) => ({ ...initialState }),
		info: (state, action) => {
			if (typeof action.payload === "string") {
				state.info = action.payload;
			} else {
				state.info = action.payload.message;
				state.duration = action.payload.duration;
			}
		},
		warn: (state, action) => {
			if (typeof action.payload === "string") {
				state.warn = action.payload;
			} else {
				state.warn = action.payload.message;
				state.duration = action.payload.duration;
			}
		},
		error: (state, action) => {
			if (typeof action.payload === "string") {
				state.error = action.payload;
			} else {
				state.error = action.payload.message;
				state.duration = action.payload.duration;
			}
		},
		coldError: (state, action) => {
			state.coldError = action.payload;
		}
	},
	extraReducers: builder => {
		builder.addCase("api/call", () => ({ ...initialState }));
	}
});

export default slice.reducer;

// ACTIONS
export const {
	clear,
	error: errorMsg,
	info: infoMsg,
	warn: warnMsg,
	coldError: coldError
} = slice.actions;
