import C from "../../constants/releaseNotes";
import store from "../store.js";
import axios from "axios";

// require("dotenv").config();

const endPoint = process.env.REACT_APP_AMBACK;

const actions = {
	getReleaseNotes: function (myAppID) {
		if (store.getState().userStatus.auth) {
			let user = store.getState().userStatus.auth.user;

			if (user) {
				return dispatch => {
					axios(endPoint + "/api/v1/getReleaseNotes", {
						headers: {
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email
						},
						method: "GET",
						params: { myAppID }
					})
						.then(res => {
							dispatch({
								type: C.GET_RELEASE_NOTES,
								payload: res.data
							});
						})
						.catch(error => {});
				};
			}
		}
	}
};

export default actions;
