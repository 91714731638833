module.exports = {
	getSchemaInternationalised
};

function getSchemaInternationalised(t) {
	const schema = {
		type: "object",
		properties: {
			email: {
				type: "string",
				title: t("CONTACT.email")
			},
			first: {
				type: "string",
				title: t("CONTACT.first")
			},
			last: {
				type: "string",
				title: t("CONTACT.lastname")
			},
			company: {
				type: "string",
				title: t("CONTACT.company")
			},
			// phone: {
			//     type: "string",
			//     title: t("CONTACT.phone"),
			// },
			created: {
				type: "string"
			},
			name: {
				type: "string"
			}
		}
	};

	const uiSchema = {
		type: "VerticalLayout",
		elements: [
			{
				type: "Control",
				scope: "#/properties/email"
			},
			{
				type: "Control",
				scope: "#/properties/first"
			},
			{
				type: "Control",
				scope: "#/properties/last"
			},
			{
				type: "Control",
				scope: "#/properties/company"
			}
			// {
			//     type: "Control",
			//     scope: "#/properties/phone",
			// },
		]
	};

	return {
		schema,
		uiSchema
	};
}
