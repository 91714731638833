import lod_ from "lodash";
import { api } from "redux/actions/api.js";
import store from "redux/store.js";
import { createSlice } from "@reduxjs/toolkit";
import { infoMsg } from "./snackMsgsReducers";
import axios from "../../../node_modules/axios/index";
const endPoint = process.env.REACT_APP_AMBACK;
let request;

const initialState = {
	answersFetched: false,
	data: [],
	language: "",
	contactAnsDocSearchFilter: "",
	sortedBuiltInAnswers: []
};
export const answers = initialState;

const slice = createSlice({
	name: "answers",
	initialState,
	reducers: {
		initAnswers: () => ({ ...initialState }),
		updateAnswers: (state, action) => {
			const answers = action.payload.answers;
			return { ...state, data: answers, answersFetched: true };
		},
		setAnswerLanguage: (state, action) => {
			state.language = action.payload;
		},
		setContactAnsDocSearchFilter: (state, action) => {
			state.contactAnsDocSearchFilter = action.payload;
		},
		setSortedBuiltInAnswers: (state, action) => {
			state.sortedBuiltInAnswers = action.payload;
		}
	}
});

// ACTIONS
export const {
	initAnswers,
	updateAnswers,
	setAnswerLanguage,
	setContactAnsDocSearchFilter,
	setSortedBuiltInAnswers
} = slice.actions;

export default slice.reducer;

/**
 * get All answer items in alternative format
 * @param {*} assistantID
 * @returns
 */
export function updateAnswerItems(assistantID) {
	if (lod_.has(store.getState().userStatus, "auth.user")) {
		let user = store.getState().userStatus.auth.user;

		let data = {
			assistantID: assistantID,
			email: user.email
		};

		const currentRequest = performance.now();
		request = currentRequest;

		return dispatch => {
			dispatch(initAnswers());

			axios(endPoint + "/api/v1/getAllAnswerItemsSchema", {
				headers: {
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "POST",
				data: data
			})
				.then(result => {
					if (currentRequest === request) dispatch(updateAnswers({ answers: result.data }));
				})
				.catch(error => {});
		};
	}
}
