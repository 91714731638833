/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint consistent-return: 2*/
/* eslint-disable no-mixed-operators */
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import cockpitActions from "redux/actions/cockpitActions";
import { Box, Button } from "@material-ui/core";
import { api } from "redux/actions/api";
import { useDispatch } from "react-redux";
import GridItem from "components/Grid/GridItem";

function CreateListDialog(props) {
	const dispatch = useDispatch();

	const [handleSave, setHandleSave] = useState(false);
	const [listName, setListName] = useState("");
	const [error, setError] = useState(false);
	const [errorText, setErrorText] = useState("");

	/**
	 * Close modal ContactEditDialog
	 **/
	const handleClose = () => {
		props.handleCloseAddListDialog();
	};

	/**
	 * Click on save button
	 */
	const handleSaveList = async () => {
		if (listName.length > 0) {
			setHandleSave(true);
			var contactsString = props.selectedContacts
				.map(function (contact) {
					return contact.email;
				})
				.join(",");

			props.resetCheckedData();

			dispatch(
				api({
					type: "handleSaveList",
					url: "/api/v1/lists",
					method: "POST",
					data: {
						assistantID: props.selectedAssistantID,
						name: listName,
						emails: contactsString
					},
					onSuccess: () => {
						props.handleCloseAddListDialog();
						props.refreshLists();
						return { type: "SUCCESS" };
					},
					onFailure: error => {
						setError(true);
						setErrorText(props.t("CONTACT.listAlreadyExist"));
						return { type: "ERROR" };
					}
				})
			);

			setHandleSave(false);
		} else {
			setErrorText(props.t("CONTACT.enterListName"));
			setError(true);
		}
	};

	/**
	 * Handle list name input change
	 * @param {*} event
	 */
	function onChangeInput(event) {
		setError(false);
		setListName(event.target.value);
	}

	return (
		<React.Fragment>
			<Dialog onClose={handleClose} open={true} aria-labelledby="form-dialog-title" maxWidth={"lg"}>
				<Box display="flex" width="800px" flexDirection={"column"}>
					<Box display="flex" flexDirection={"column"} width={"100%"}>
						<DialogTitle
							id="form-dialog-title"
							maxwidth={"md"}
							height="401px"
							style={{
								borderBottom: "1px solid",
								borderColor: "#d8d6d6"
							}}
						>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								wrap="nowrap"
							>
								{props.t("CONTACT.createNewList")}
							</Grid>
						</DialogTitle>

						<Grid container item xs={9} style={{ maxWidth: "100%" }}>
							<DialogContent style={{ overflowY: "hidden" }}>
								<div className="input-container">
									<input type="text" onChange={onChangeInput} />
									<label className={listName && "filled"} htmlFor={listName}>
										{props.t("CONTACT.listName")}
									</label>
								</div>

								{props.selectedContacts.length > 0 && (
									<>
										<h4 style={{ paddingTop: "2%", fontWeight: "bold" }}>
											{props.t("CONTACT.contacts")}
										</h4>

										<Grid className="ulSquare">
											{props.selectedContacts?.map((item, index) => {
												return (
													<GridItem className="liElement" key={index}>
														{item.email}
													</GridItem>
												);
											})}
										</Grid>
									</>
								)}
							</DialogContent>
						</Grid>

						<Grid
							container
							direction="row"
							justifyContent="space-between"
							style={{ padding: "2%", paddingTop: "2%" }}
						>
							{error && (
								<Grid item style={{ paddingTop: "2%" }}>
									<p className="errorText">{errorText}</p>
								</Grid>
							)}

							{!error && <div></div>}

							<Grid item container direction="row" style={{ paddingTop: "2%", width: "auto" }}>
								<Grid item>
									<Button onClick={handleClose} style={{ color: "red" }}>
										{props.t("CONTACT.cancel")}
									</Button>
								</Grid>

								<Grid item>
									<Button disabled={handleSave} onClick={handleSaveList} color="primary">
										{props.t("CONTACT.save")}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(CreateListDialog);
