/* eslint-disable no-fallthrough */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */

import "./Call.css";

import React, { Fragment, useEffect, useRef, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	IconButton,
	Paper,
	Tooltip,
	CircularProgress,
	Typography,
	Popover,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Switch,
	FormGroup,
	FormControlLabel
} from "@material-ui/core";
import axios from "axios";

import EditIcon from "@material-ui/icons/Edit";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";

import AddIcon from "@material-ui/icons/Add";

import i18n from "i18n";
import { AddRounded, AttachFile, Delete, ExpandMore, VisibilityOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import cockpitActions from "../../../../redux/actions/cockpitActions";
import { Infos } from "../../CockpitTabInfo/Infos";
import { ActionsComponent } from "pages/Cockpit/CockpitTabInfo/Actions";
import { getRcvSntAtt } from "pages/Cockpit/CockpitTabInfo/CockpitTabInfo";
import { getRcvSntAttAll } from "pages/Cockpit/CockpitTabInfo/CockpitTabInfo";
import Controls from "components/Controls/Controls";
import { useForm } from "hooks/useForm";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import lod_ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import COC from "../../../../constants/cockpit.js";
import generalConstant from "../../../../constants/general";

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import useCopyToClipboard from "../../../../helpers/useCopyToClipboard";
import ConfirmationDialog from "components/ConfirmationDialog";
import { api } from "redux/actions/api";
import { traceError } from "helpers/utilities";
import { handleSpecialDisplayDate } from "helpers/utilities";
import { Link } from "react-router-dom";
import C from "../../../../constants/cockpit";

import engageActions from "../../../../redux/actions/engageActions";
import EngageDropDown from "pages/Engage/EngageDropDown";

const useStyles = makeStyles(theme => ({
	numberCircle: {
		width: "30px",
		height: "30px",
		padding: "6px 0px",
		borderRadius: "15px",
		textAlign: "center",
		fontSize: "14px",
		lineHeight: 1.42857,
		backgroundColor: "#dfe6e9"
	},
	radio: {
		color: "black"
	}
}));

const ContextCallinterface = ({ callHistory = false }) => {
	const [formDictionary, setFormDictionary] = useState(false);

	const { selectedConversation } = useSelector(state => state.cockpit);
	const valueForm = {
		context: selectedConversation.context,
		contact: selectedConversation.contact
	};
	const assistantconfig = useSelector(state => state.assistantconfig);
	const dispatch = useDispatch();
	const [sntAtt, setSntAtt] = useState([]);
	const [sntAttAll, setSntAttAll] = useState([]);
	const [rcvAtt, setRcvAtt] = useState([]);
	const [rcvAttAll, setRcvAttAll] = useState([]); //arr to download all attachments received
	const [dictionary, setDictionary] = useState(assistantconfig.dictionary);

	const [PI, setPI] = useState(false);
	const user = useSelector(state => state.userStatus?.auth?.user);

	const userLangage = user.auth?.user?.language?.toLowerCase() || "fr";
	const inputStyle = { margin: "0.5em 0 0.5em 0" };
	const [customErrors, setCustomErrors] = useState({});
	const [hasErrors, setHasErrors] = useState(false);
	const [openConfirmSave, setOpenConfirmSave] = useState(false);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [openConfirmValuePI, setOpenConfirmValuePI] = useState(false);
	const [cancel, setCancel] = useState(false);

	const [convSet, setConvSet] = useState(false);
	const [assignConv, setAssignConv] = useState(false);

	const [hasValuePi, setHasValuePI] = useState(false);
	/**
	 *
	 */
	useEffect(() => {
		if (!callHistory) {
			dispatch(cockpitActions.FetchHistory(selectedConversation));
		}
	}, [selectedConversation.header.fID]);

	/**
	 *  Build the map of all attachments (inline and attachments) sent and received for one conversation
	 * @param {Function} useEffect
	 */
	useEffect(() => {
		if (selectedConversation?.messages) {
			const att = getRcvSntAtt(selectedConversation);

			setSntAtt(att.sntAtt);
			setRcvAtt(att.rcvAtt);
		}
	}, [selectedConversation?.messages]);

	/**
	 *  Build the map of all attachments (inline and attachments) sent and received for all the conversations
	 * @param {Function} useEffect
	 */
	useEffect(() => {
		const rcvSntAll = getRcvSntAttAll(rcvAtt, sntAtt, assistantconfig, selectedConversation);
		setRcvAttAll(rcvSntAll.rcvAttAll);
		setSntAttAll(rcvSntAll.sntAttAll);
	}, [sntAtt, rcvAtt]);

	let validate = () => {
		const errors = {};
		//validateData();
		return errors;
	};

	let { values, errors, setErrors, setValues, handleInputChange } = useForm(
		valueForm,
		true,
		validate
	);

	const handleCloseSave = () => {
		setOpenConfirmSave(false);
	};

	useEffect(() => {
		if (valueForm?.context?.hasPIValue || valueForm?.contact?.hasPIValue) {
			setHasValuePI(true);
		} else {
			setHasValuePI(false);
		}

		let formConfigContext = getStatsKeys(dictionary, valueForm, PI);
		setFormDictionary(formConfigContext);
	}, []);

	useEffect(() => {
		if (PI === false) {
			let formConfigContext = getStatsKeys(
				dictionary,
				{
					context: selectedConversation.context,
					contact: selectedConversation.contact
				},
				false
			);
			setFormDictionary(formConfigContext);
			setValues({
				context: selectedConversation.context,
				contact: selectedConversation.contact
			});
		}
	}, [selectedConversation.context]);

	useEffect(() => {
		if (valueForm?.context?.hasPIValue || valueForm?.contact?.hasPIValue) {
			setHasValuePI(true);
		} else {
			setHasValuePI(false);
		}

		let formConfigContext = getStatsKeys(dictionary, valueForm, PI);
		setFormDictionary(formConfigContext);
		setValues(valueForm);
		if (selectedConversation.agent.uid !== "") {
			if (selectedConversation.agent.uid === user._id) {
				setAssignConv(true);
			} else {
				setAssignConv(false);
			}
		} else {
			setAssignConv(false);
		}
		setPI(false);
	}, [selectedConversation.header.fID]);

	const updateContext = (fID, assistantID, context, conv) => {
		let data = {
			action: "updateConvContext",
			data: {
				header: {
					assistantID,
					fID
				},
				payload: {
					context
				}
			},
			source: generalConstant.APP_NAME,
			ts: Date.now()
		};
		const onSuccess = () => {
			return [
				infoMsg(i18n.t("OrderForm.saved")),
				{
					type: COC.UPDATE_CONV,
					payload: {
						conv,
						fID
					}
				}
			];
		};

		dispatch(
			api({
				url: "/api/v1/actionFront",
				method: "POST",
				data,
				onSuccess
			})
		);
	};

	const updateContact = (assistantID, contact) => {
		try {
			if (contact?.email) {
				contact = {
					...contact,
					email: contact.email && contact.email.toLowerCase(),
					ML_id: contact.email && contact.email.toLowerCase()
				};
			}
			//merge contact crm and contact conversation
			let contactConv = selectedConversation.contact;

			contactConv = { ...contactConv, ...contact };
			//contact = {...contact, name: cockpit.selectedConversation.contact.name}

			dispatch(cockpitActions.updateConversationContact(contactConv, selectedConversation.header));

			// dispatch(cockpitActions.updateContact(contact, assistantID, dictionary));
		} catch (err) {
			traceError(`handleSave-contact ${contact} failed: ${err.message}`);
		}
	};

	const handleSubmit = () => {
		const conv = selectedConversation;
		const fID = conv?.header?.fID;
		const assistantID = conv?.header?.assistantID;
		let context = { ...conv.context, ...values.context };
		let contact = { ...conv.contact, ...values.contact };

		const contextWithoutNull = removeNullLeaves(context);
		const contactWithoutNull = removeNullLeaves(contact);

		setOpenConfirmSave(false);
		updateContext(fID, assistantID, contextWithoutNull, conv);
		updateContact(assistantID, contactWithoutNull);

		let formConfigContext = getStatsKeys(
			dictionary,
			{
				context: contextWithoutNull,
				contact: contactWithoutNull
			},
			false
		);
		setFormDictionary(formConfigContext);
		setValues({
			context: contextWithoutNull,
			contact: contactWithoutNull
		});

		setPI(false);
		setConvSet(false);
		setAssignConv(true);
		setCancel(true);
	};

	function removeNullLeaves(obj) {
		if (Array.isArray(obj)) {
			// Si c'est un tableau, traiter chaque élément de manière récursive
			return obj
				.map(item => {
					if (item === null) {
						return undefined; // Retourne undefined pour éliminer les éléments null
					} else if (typeof item === "object") {
						return removeNullLeaves(item); // Appel récursif pour les objets imbriqués dans le tableau
					} else {
						return item; // Conserver les éléments non null et non objets
					}
				})
				.filter(item => item !== undefined); // Filtrer les éléments undefined (éléments null supprimés)
		} else if (typeof obj === "object" && obj !== null) {
			const newObj = {};
			for (const key in obj) {
				if (obj[key] !== null && typeof obj[key] === "object" && !(obj[key] instanceof Date)) {
					// Recursivement traiter les objets imbriqués sauf s'ils sont de type Date
					const nestedResult = removeNullLeaves(obj[key]);
					if (Object.keys(nestedResult).length > 0) {
						newObj[key] = nestedResult;
					}
				} else if (obj[key] !== null) {
					// Conserver les valeurs non null
					newObj[key] = obj[key];
				}
			}
			return newObj;
		} else {
			return obj; // Retourner les valeurs non objets
		}
	}

	const handleCancel = () => {
		const onSuccess = payload => {
			setPI(false);
			setConvSet(false);
			setAssignConv(true);
			setCancel(true);

			if (payload.payload?.context?.hasPIValue || payload.payload?.contact?.hasPIValue) {
				setHasValuePI(true);
			} else {
				setHasValuePI(false);
			}

			let formConfigContext = getStatsKeys(
				dictionary,
				{
					context: payload.payload.context,
					contact: payload.payload.contact
				},
				false
			);
			setFormDictionary(formConfigContext);
			setValues({
				context: payload.payload.context,
				contact: payload.payload.contact
			});
		};

		let getPIvalues = false;
		let casePI = false;
		let viewPI = false;

		dispatch(
			api({
				type: "cockpit/selectConversation",
				url: "/api/v1/conversationPI",
				data: {
					assistantID: selectedConversation.header.assistantID,
					fID: selectedConversation.header.fID,
					getPIvalues,
					casePI,
					viewPI
				},
				onSuccess
			})
		);
	};

	const handleInputChangeValidate = (e, value, child) => {
		if (value?.blacklist) {
			if (value.blacklist.includes(e.target.value)) {
				let copyErrors = lod_.cloneDeep(customErrors);
				lod_.set(copyErrors, child, i18n.t("CONTACT.blackListContact"));
				setCustomErrors(copyErrors);
				setHasErrors(true);
			} else {
				let copyErrors = lod_.cloneDeep(customErrors);
				lod_.unset(copyErrors, child);

				setCustomErrors(copyErrors);
				setHasErrors(false);
			}
		}

		if (value?.whitelist) {
			if (
				value.whitelist.includes(e.target.value) ||
				e.target.value === "" ||
				value.whitelist.length === 0
			) {
				let copyErrors = lod_.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
				setHasErrors(false);
			} else {
				setCustomErrors({ ...customErrors, [child]: i18n.t("CONTACT.whitelistContact") });
				setHasErrors(true);
			}
		}
		if (value?.type === "date" || value?.type === "dateYearMonthDay") {
			e = { target: { name: child, value: e } };
		}

		if (value?.type === "number") {
			e = { target: { name: child, value: parseFloat(e.target.value) } };
		}
		if (value?.type === "phone") {
			e.target.value = e.target.value.replace(/\s/g, "");
		}

		// Fix because value seems to be undefined
		if (e.currentTarget?.type === "number") {
			e = { target: { name: child, value: parseFloat(e.target.value) } };
		}

		if (value?.type === "boolean") {
			e = { target: { name: child, value: e.target.checked } };
		}

		handleInputChange(e);
	};

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	const handleRemoveProduct = (index, path, e, pathDict) => {
		e.stopPropagation();
		lod_.get(values, path).splice(index, 1);
		const cloneDict = lod_.cloneDeep(formDictionary);
		lod_.get(cloneDict, pathDict).splice(index, 1);
		setFormDictionary(cloneDict);
		handleInputChange(convertToDefEventParam(path, lod_.get(values, path)));
	};

	const handleAddProduct = (path, pathDict) => {
		let formatDict = dictionary;
		let pathFormat = path;
		const cloneDict = lod_.cloneDeep(formDictionary);
		if (pathFormat.includes(".")) {
			pathFormat = pathFormat.replaceAll(".", ".items.") + ".items";
		} else {
			pathFormat += ".items";
		}

		const newFormat = lod_.get(formatDict, pathFormat);
		let valueFormat = {};
		let dictFormat = {};
		for (let childFormat in newFormat) {
			dictFormat[childFormat] = {
				type: newFormat[childFormat].type,
				...newFormat[childFormat].label,
				isEdit: newFormat[childFormat]?.actions?.edit,
				value: null
			};
			valueFormat[childFormat] = null;
		}

		if (lod_.get(values, path)) {
			lod_.get(values, path).push({ ...valueFormat });
			lod_.get(cloneDict, pathDict).push({ ...dictFormat });
		} else {
			lod_.set(values, path, []);
			lod_.get(values, path).push({ ...valueFormat });
			lod_.get(cloneDict, pathDict).push({ ...dictFormat });
		}

		setFormDictionary(cloneDict);
		handleInputChange(convertToDefEventParam(path, lod_.get(values, path)));
	};

	const hasPI = () => {
		const onSuccess = payload => {
			setPI(true);
			setAssignConv(false);
			handleClosePI();
			setConvSet(true);
			let formConfigContext = getStatsKeys(
				dictionary,
				{
					context: payload.payload.context,
					contact: payload.payload.contact
				},
				true
			);
			setFormDictionary(formConfigContext);
			setValues({
				context: payload.payload.context,
				contact: payload.payload.contact
			});
		};

		let getPIvalues = false;
		let casePI = true;
		let viewPI = false;

		dispatch(
			api({
				type: "cockpit/selectConversation",
				url: "/api/v1/conversationPI",
				data: {
					assistantID: selectedConversation.header.assistantID,
					fID: selectedConversation.header.fID,
					getPIvalues,
					casePI,
					viewPI
				},
				onSuccess
			})
		);
	};

	const handleClosePI = () => {
		setOpenConfirm(false);
	};

	const setOpenConfirmPI = () => {
		if (valueForm?.context?.hasPIValue || valueForm?.contact?.hasPIValue) {
			setOpenConfirm(true);
		} else {
			hasPI();
		}
	};

	const hasPIView = () => {
		const onSuccess = payload => {
			setOpenConfirmValuePI(false);
			setHasValuePI(false);
			setCancel(true);

			setValues({
				context: payload.payload.context,
				contact: payload.payload.contact
			});
			dispatch({ type: C.UPDATE_CONV_CONTEXT_AND_CONTACT, payload });
		};

		let getPIvalues = false;
		let casePI = false;
		let viewPI = true;

		dispatch(
			api({
				type: "cockpit/selectConversation",
				url: "/api/v1/conversationPI",
				data: {
					assistantID: selectedConversation.header.assistantID,
					fID: selectedConversation.header.fID,
					getPIvalues,
					casePI,
					viewPI
				},
				onSuccess
			})
		);
	};

	const handleClosePIView = () => {
		setOpenConfirmValuePI(false);
	};

	const [expanded, setExpanded] = useState([]);
	const [expandedAll, setExpandedAll] = useState(true);

	const handleChange = panel => (event, isExpanded) => {
		if (isExpanded) {
			setExpanded(prevExpanded => [...prevExpanded, panel]);
		} else {
			setExpanded(prevExpanded => prevExpanded.filter(item => item !== panel));
		}
	};

	const handleExpandAll = () => {
		const allPanels = Object.keys(formDictionary?.context);
		setExpanded(allPanels);
		setExpandedAll(false);
	};

	const handleCollapseAll = () => {
		setExpanded([]);
		setExpandedAll(true);
	};

	return (
		<>
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					flexWrap: "wrap",
					justifyContent: "flex-start",
					width: "100%"
				}}
				data-component-name="ContextCallinterface"
			>
				<Box display={"flex"}>
					<div>
						{expandedAll ? (
							<IconButton onClick={handleExpandAll}>
								<Tooltip title={i18n.t("TabInfo.expandedAll")}>
									<ExpandLessIcon />
								</Tooltip>
							</IconButton>
						) : (
							<IconButton onClick={handleCollapseAll}>
								<Tooltip title={i18n.t("TabInfo.collapseAll")}>
									<ExpandMoreIcon />
								</Tooltip>
							</IconButton>
						)}
					</div>

					<Box
						style={{
							display: "flex",
							flexDirection: "row"
						}}
					>
						<Box style={{ display: "flex", alignItems: "center" }}>
							{hasValuePi && !PI && (
								<Tooltip title={i18n.t("TabInfo.showHiddenFields")}>
									<IconButton onClick={() => setOpenConfirmValuePI(true)}>
										<VisibilityOutlined />
									</IconButton>
								</Tooltip>
							)}

							{openConfirmValuePI && (
								<ConfirmationDialog
									open={openConfirmValuePI}
									handleClose={handleClosePIView}
									title={i18n.t("TabInfo.showHiddenConfirmTitle")}
									message={i18n.t("TabInfo.showHiddenConfirm")}
									handleValidate={hasPIView}
									cancel={i18n.t("COC.disagree")}
									validate={i18n.t("COC.agree")}
								/>
							)}
							{assignConv && assistantconfig?.cockpit?.editTabInfo !== false && (
								<Tooltip title={i18n.t("TabInfo.showEditHiddenFields")}>
									<IconButton onClick={() => setOpenConfirmPI()}>
										<EditIcon />
									</IconButton>
								</Tooltip>
							)}

							{openConfirm && (
								<ConfirmationDialog
									open={openConfirm}
									handleClose={handleClosePI}
									title={i18n.t("TabInfo.showHiddenConfirmTitle")}
									message={i18n.t("TabInfo.showHiddenConfirm")}
									handleValidate={hasPI}
									cancel={i18n.t("COC.disagree")}
									validate={i18n.t("COC.agree")}
								/>
							)}
						</Box>
					</Box>
					<Box style={{ display: "flex" }}>
						{PI && (
							<>
								<Button
									onClick={() => setOpenConfirmSave(true)}
									disabled={hasErrors}
									style={{ margin: "2%" }}
									color="primary"
								>
									{i18n.t("CD.save")}
								</Button>
								{openConfirmSave && (
									<ConfirmationDialog
										open={openConfirmSave}
										handleClose={handleCloseSave}
										title={i18n.t("TabInfo.contextSave")}
										message={i18n.t("TabInfo.actionSaveContexte")}
										handleValidate={handleSubmit}
										cancel={i18n.t("COC.disagree")}
										validate={i18n.t("COC.agree")}
									/>
								)}
							</>
						)}
						{PI && (
							<Button onClick={handleCancel} style={{ margin: "2%" }} color="secondary">
								{i18n.t("CD.cancel")}
							</Button>
						)}
					</Box>
				</Box>
				{Object.keys(formDictionary).map((childTop, key) => (
					<>
						{childTop === "contact" && (
							<Accordion
								key={key}
								className="classAccordeonTabInfo"
								expanded={expanded.includes(childTop)}
								onChange={handleChange(childTop)}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Box style={{ display: "flex", alignItems: "center" }}>
										<Typography variant="h7">{formDictionary[childTop][userLangage]}</Typography>
									</Box>
								</AccordionSummary>
								<AccordionDetails>
									<Box
										style={{
											display: "flex",
											flexDirection: "column",
											flexWrap: "wrap",
											justifyContent: "flex-start"
										}}
									>
										{Object.keys(formDictionary[childTop])
											.sort(
												(a, b) =>
													formDictionary[childTop][a]["order"] -
													formDictionary[childTop][b]["order"]
											)
											.map((childTitle, key) => (
												<>
													{formDictionary[childTop][childTitle]?.type !== "level" &&
														typeof formDictionary[childTop][childTitle] === "object" && (
															<>
																<InpuType
																	styleChild={inputStyle}
																	isDisplay={formDictionary[childTop][childTitle]?.display}
																	codes={formDictionary[childTop][childTitle]?.codes}
																	isEdit={formDictionary[childTop][childTitle]?.isEdit}
																	child={`${childTop}.${childTitle}`}
																	label={formDictionary[childTop][childTitle][userLangage]}
																	key={key}
																	PI={PI}
																	whitelistDynamic={
																		formDictionary[childTop][childTitle]?.whitelistDynamic
																	}
																	whitelist={formDictionary[childTop][childTitle]?.whitelist}
																	assignConv={assignConv}
																	type={formDictionary[childTop][childTitle]?.type}
																	value={values[childTop][childTitle]}
																	customErrors={lod_.get(customErrors, `${childTop}.${childTitle}`)}
																	onChange={e =>
																		handleInputChangeValidate(
																			e,
																			formDictionary[childTop][childTitle],
																			`${childTop}.${childTitle}`
																		)
																	}
																></InpuType>
															</>
														)}
												</>
											))}
										{Object.keys(formDictionary[childTop])
											.sort(
												(a, b) =>
													formDictionary[childTop][a]["order"] -
													formDictionary[childTop][b]["order"]
											)
											.map((childTitle, key) => (
												<>
													{typeof formDictionary[childTop][childTitle] === "object" &&
														formDictionary[childTop][childTitle]?.type === "level" && (
															<Accordion
																className="classAccordeonTabInfo"
																defaultExpanded={true}
																style={{ paddingTop: "2px" }}
																key={key}
															>
																<AccordionSummary
																	expandIcon={<ExpandMoreIcon />}
																	aria-controls="panel1a-content"
																	id="panel1a-header"
																>
																	<Typography variant="h7">
																		{formDictionary[childTop][childTitle][userLangage]}
																	</Typography>
																</AccordionSummary>
																<AccordionDetails>
																	<>
																		{formDictionary[childTop][childTitle]?.type === "level" &&
																			typeof formDictionary[childTop][childTitle] === "object" && (
																				<>
																					<Box>
																						<OrderFormDictionaryChild
																							fieldChild={formDictionary[childTop][childTitle]}
																							userLangage={userLangage}
																							root={`${childTop}.${childTitle}`}
																							values={values}
																							PI={PI}
																							handleInputChangeValidate={handleInputChangeValidate}
																							formDictionary={formDictionary[childTop][childTitle]}
																							customErrors={customErrors}
																							handleRemoveProduct={handleRemoveProduct}
																							handleAddProduct={handleAddProduct}
																							assignConv={assignConv}
																						></OrderFormDictionaryChild>
																					</Box>
																				</>
																			)}
																	</>
																</AccordionDetails>
															</Accordion>
														)}
												</>
											))}
									</Box>
								</AccordionDetails>
							</Accordion>
						)}
					</>
				))}

				{Object.keys(formDictionary).map((childTop, key) => (
					<>
						{childTop === "context" && (
							<>
								{Object.keys(formDictionary[childTop])
									.sort(
										(a, b) =>
											formDictionary[childTop][a]["order"] - formDictionary[childTop][b]["order"]
									)
									.map((childTitle, key) => (
										<>
											{typeof formDictionary[childTop][childTitle] === "object" && (
												<Accordion
													key={key}
													className="classAccordeonTabInfo"
													expanded={expanded.includes(childTitle)}
													onChange={handleChange(childTitle)}
												>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
														<Typography variant="h7">
															{formDictionary[childTop][childTitle][userLangage]}
														</Typography>
													</AccordionSummary>
													<AccordionDetails>
														<OrderFormDictionaryChild
															fieldChild={formDictionary[childTop][childTitle]}
															userLangage={userLangage}
															root={`${childTop}.${childTitle}`}
															values={values}
															PI={PI}
															handleInputChangeValidate={handleInputChangeValidate}
															formDictionary={formDictionary[childTop][childTitle]}
															customErrors={customErrors}
															handleRemoveProduct={handleRemoveProduct}
															handleAddProduct={handleAddProduct}
															assignConv={assignConv}
														></OrderFormDictionaryChild>
													</AccordionDetails>
												</Accordion>
											)}
										</>
									))}
							</>
						)}
					</>
				))}
			</Box>
		</>
	);
};

const InpuType = ({
	styleChild,
	child,
	key,
	label,
	value,
	onChange,
	customErrors,
	type,
	PI,
	assignConv,
	isEdit,
	whitelist = false,
	whitelistDynamic = false,
	isDisplay,
	codes = []
}) => {
	const inputValue = value;

	const assistantIDs = useSelector(state => state?.assistantconfig?.assistantID);
	let apiSettings = useSelector(state => state?.assistantconfig?.APIs);
	const crmBackEndpoint = process.env.REACT_APP_CRMBACK;
	const [attachmentLoading, setAttachmentLoading] = useState(false);
	const { currentMessage, selectedConversation } = useSelector(state => state.cockpit);
	const { assistantconfig } = useSelector(state => state);
	const { auth } = useSelector(state => state.userStatus);
	const [valueAuto, setValueAuto] = useState(value);
	const [optionAuto, setOptionAuto] = useState([]);

	async function loadSuggestions(apiKey, searchText, collection, searchFields) {
		let querryData = [];
		searchFields.forEach(field => {
			const query = {};
			query[field] = { $regex: searchText };
			querryData.push(query);
		});

		let data = JSON.stringify({
			query: {
				$or: querryData
			}
		});
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${crmBackEndpoint}/apiExt/v1/ressource/${collection}/items`,
			headers: {
				apiKey,
				assistantID: assistantIDs,
				"Content-Type": "application/json"
			},
			data: data
		};

		let result;
		await axios
			.request(config)
			.then(response => {
				result = response.data;
			})
			.catch(error => {});
		return result || [];
	}

	useEffect(() => {
		if (whitelistDynamic) {
			setValueAuto(value);
		}
	}, [value]);
	// Créer un élément span pour contenir la valeur de l'input
	const spanElement = document.createElement("span");
	spanElement.innerHTML = inputValue;

	// Ajouter l'élément span à la page (mais le cacher)
	spanElement.style.visibility = "hidden";
	spanElement.style.font = "1rem Arial";
	spanElement.style.fontFamily = "Roboto, Helvetica, Arial, sans-serif,monospace"; // Ajouter cette ligne pour prendre en compte les espaces
	document.body.appendChild(spanElement);

	// Récupérer la largeur de l'élément span
	const width = spanElement.offsetWidth;

	// Supprimer l'élément span
	document.body.removeChild(spanElement);

	const inputValueMin = label;

	// Créer un élément span pour contenir la valeur de l'input
	const spanElementMin = document.createElement("span");
	spanElementMin.innerHTML = inputValueMin;

	// Ajouter l'élément span à la page (mais le cacher)
	spanElementMin.style.visibility = "hidden";
	spanElementMin.style.font = "16px Roboto, Helvetica, Arial, sans-serif";
	spanElementMin.style.fontFamily = "monospace"; // Ajouter cette ligne pour prendre en compte les espaces
	document.body.appendChild(spanElementMin);

	// Récupérer la largeur de l'élément span
	const widthMin = spanElementMin.offsetWidth;

	// Supprimer l'élément span
	document.body.removeChild(spanElementMin);
	const [, handleCopy] = useCopyToClipboard(2000);
	const dispatch = useDispatch();
	let readOrEdit = true;

	if (PI && isEdit) {
		readOrEdit = false;
	}
	const copyValue = valueCopy => {
		if (!PI) {
			handleCopy(valueCopy);
			dispatch(infoMsg(i18n.t("COC.CopyToClipboard")));
		}

		//	setSnackbarStatus({ open: true, message: `identifiant copié ` });
	};
	/**
	 *  Past into textfied(richText) or rich editor(htmlText) context informations
	 * @param {function} contactItem
	 */

	const handleAddContactItem = (contactItem, type) => {
		switch (type) {
			case "url":
				contactItem = `<a href="${contactItem}">${contactItem}</a>`;
				break;
			case "email":
				contactItem = `<a href="mailto:${contactItem}">${contactItem}</a>`;
				break;
			case "date":
				var dAujourdhui = new Date(contactItem);
				contactItem = dAujourdhui;
				break;
		}

		if (contactItem !== undefined) {
			insertRichAnswerToEditor(contactItem, []);
		}
	};
	const insertRichAnswerToEditor = (text, ansAttachments, ansSubject) => {
		dispatch({
			type: COC.INSERT_HTML,
			payload: `${text}<br>`
		});
	};

	useEffect(() => {
		setAttachmentLoading(false);
	}, [selectedConversation?.drafts?.attachments]);

	const getOptionLabel = option => {
		const label = whitelistDynamic.params.searchFields.map(key => option[key]).join(" - ");
		return label;
	};

	const onChangeCRM = (event, newValue, option) => {
		if (option === "select-option") {
			setValueAuto(newValue[whitelistDynamic.params.attribute]);
			event = { target: { name: child, value: newValue[whitelistDynamic.params.attribute] } };
			onChange(event);
		}
	};

	const ValueChange = async valueC => {
		setValueAuto(valueC);

		let apiConfig = apiSettings.filter(key => key.name === whitelistDynamic.apiName)[0].config;
		let apiKeyConfig = apiConfig.apiKey;

		let result = await loadSuggestions(
			apiKeyConfig,
			valueC,
			whitelistDynamic.params.collection,
			whitelistDynamic.params.searchFields
		);

		setOptionAuto(result);
	};

	const whiteListItem = () => {
		if (whitelist && !lod_.isEmpty(whitelist)) {
			return (
				<FormControl>
					<InputLabel id="select-label">{label}</InputLabel>
					<Select
						style={{ width: `${width}px`, minWidth: `${widthMin}px`, maxWidth: "300px" }}
						labelId="select-label"
						id="select"
						value={value || ""}
						onChange={onChange}
						name={child}
						disabled={readOrEdit}
					>
						{whitelist.map((option, index) => (
							<MenuItem key={index} value={option}>
								{option}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			);
		} else if (whitelistDynamic && !lod_.isEmpty(whitelistDynamic)) {
			return (
				<div style={{ width: `${width}px`, minWidth: `150px`, maxWidth: "350px" }}>
					<Autocomplete
						id="auto"
						disablePortal
						name={child}
						key={key}
						disabled={readOrEdit}
						options={optionAuto}
						onChange={onChangeCRM}
						getOptionLabel={getOptionLabel}
						inputValue={valueAuto}
						renderOption={(suggestedValue, option) =>
							DisplayItem(suggestedValue, whitelistDynamic.params.searchFields)
						}
						renderInput={params => (
							<TextField {...params} label={label} onChange={e => ValueChange(e.target.value)} />
						)}
					/>
				</div>
			);
		} else {
			return (
				<Box display="flex" alignItems="center">
					<TextField
						style={styleChild}
						onClick={() => copyValue(value)}
						InputProps={{
							readOnly: readOrEdit,
							style: { width: `${width}px`, minWidth: `${widthMin}px`, maxWidth: "300px" }
						}}
						name={child}
						key={key}
						label={label}
						size="small"
						value={value || ""}
						onChange={onChange}
					/>

					{type === "email" && readOrEdit && (
						<DisplayPossibleChannel
							type={type}
							selectedConversation={selectedConversation}
							value={value}
						/>
					)}
				</Box>
			);
		}
	};

	if (isDisplay === false) {
		type = "none";
	}

	if (type === "none") {
		return <></>;
	} else if (type === "phone") {
		return (
			<>
				<div style={{ display: "flex", alignItems: "center" }}>
					{assignConv && selectedConversation.header.type !== "call" && (
						<Tooltip title={i18n.t("TabInfo.addInEditor")} placement="top">
							<IconButton onClick={() => handleAddContactItem(value, type)} size="small">
								<NoteAddOutlinedIcon />
							</IconButton>
						</Tooltip>
					)}

					<Controls.Phone
						name={child}
						style={styleChild}
						label={label}
						key={key}
						defaultCountry={"fr"}
						variant="standard"
						value={value || null}
						InputProps={{
							readOnly: readOrEdit
						}}
						disableDropdown={readOrEdit}
						onClick={() => copyValue(value)}
						onChange={onChange}
						assistantConfig={assistantconfig}
						user={auth.user}
					/>

					{readOrEdit && (
						<DisplayPossibleChannel
							type={type}
							selectedConversation={selectedConversation}
							value={value}
						/>
					)}
					<p style={{ color: "red" }}>{customErrors}</p>
				</div>
			</>
		);
	} else if (type === "url") {
		return (
			<>
				<div style={{ display: "flex" }}>
					{assignConv && selectedConversation.header.type !== "call" && (
						<>
							<Tooltip title={i18n.t("TabInfo.addInEditor")} placement="top">
								<IconButton onClick={() => handleAddContactItem(value, type)} size="small">
									<NoteAddOutlinedIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title={i18n.t("TabInfo.addAttachment")} placement="top">
								<IconButton
									onClick={() => {
										setAttachmentLoading(true);
										dispatch(
											cockpitActions.clickToUpload(
												value,
												auth.user.email,
												assistantconfig.assistantID,
												currentMessage.mID || ""
											)
										);
									}}
									size="small"
								>
									{attachmentLoading && <CircularProgress size="20px" />}
									{!attachmentLoading && <AttachFile />}
								</IconButton>
							</Tooltip>
						</>
					)}

					<Box style={{ display: "flex" }}>
						<p>{label} :</p>
						<a href={value} target="_blank" rel="noreferrer">
							{i18n.t("COC.URLlink")}{" "}
						</a>
					</Box>
				</div>
			</>
		);
	} else if (type === "date") {
		let date = new Date(value);
		let copyValueT = date.toLocaleDateString();
		return (
			<>
				<div style={{ display: "flex" }}>
					{assignConv && selectedConversation.header.type !== "call" && (
						<Tooltip title={i18n.t("TabInfo.addInEditor")} placement="top">
							<IconButton onClick={() => handleAddContactItem(value, type)} size="small">
								<NoteAddOutlinedIcon />
							</IconButton>
						</Tooltip>
					)}
					<MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
						<KeyboardDatePicker
							style={{ width: "90%" }}
							name={child}
							format="dd/MM/yyyy"
							key={key}
							InputProps={{
								readOnly: readOrEdit
							}}
							KeyboardButtonProps={{ disabled: readOrEdit }}
							label={label}
							value={value || null}
							onChange={onChange}
							onClick={() => copyValue(copyValueT)}
							disableToolbar
						/>
					</MuiPickersUtilsProvider>
					<p style={{ color: "red" }}>{customErrors}</p>
				</div>
			</>
		);
	} else if (type === "dateYearMonthDay") {
		return (
			<>
				<div style={{ display: "flex" }}>
					{assignConv && selectedConversation.header.type !== "call" && (
						<Tooltip title={i18n.t("TabInfo.addInEditor")} placement="top">
							<IconButton onClick={() => handleAddContactItem(value, type)} size="small">
								<NoteAddOutlinedIcon />
							</IconButton>
						</Tooltip>
					)}

					<MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
						<KeyboardDatePicker
							style={{ width: "90%" }}
							name={child}
							format="yyyy-MM-dd"
							key={key}
							InputProps={{
								readOnly: readOrEdit
							}}
							KeyboardButtonProps={{ disabled: readOrEdit }}
							label={label}
							value={value || null}
							onChange={onChange}
							onClick={() => copyValue(value)}
							disableToolbar
						/>
					</MuiPickersUtilsProvider>
					<p style={{ color: "red" }}>{customErrors}</p>
				</div>
			</>
		);
	} else if (type === "number") {
		if (typeof value === "string") {
			if (value === "0") {
				value = 0;
			} else {
				parseFloat(value);
			}
		}
		return (
			<>
				{assignConv && selectedConversation.header.type !== "call" && (
					<Tooltip title={i18n.t("TabInfo.addInEditor")} placement="top">
						<IconButton onClick={() => handleAddContactItem(value, type)} size="small">
							<NoteAddOutlinedIcon />
						</IconButton>
					</Tooltip>
				)}
				{lod_.isArray(whitelist) && !lod_.isEmpty(whitelist) ? (
					whiteListItem()
				) : (
					<>
						<TextField
							name={child}
							InputProps={{
								readOnly: readOrEdit,
								style: { width: `${width}px`, minWidth: `${widthMin}px`, maxWidth: "300px" }
							}}
							type="number"
							key={key}
							label={label}
							value={value}
							onChange={onChange}
							onClick={() => copyValue(value)}
						/>
						<p style={{ color: "red" }}>{customErrors}</p>
					</>
				)}
			</>
		);
	} else if (type === "boolean") {
		return (
			<>
				<span>
					<FormGroup row>
						<FormControlLabel
							style={{ alignItems: "flex-start" }}
							labelPlacement="top"
							control={
								<Switch
									name={child}
									key={key}
									size="small"
									onChange={!readOrEdit ? onChange : undefined}
									checked={value}
								/>
							}
							label={label}
						/>
					</FormGroup>
				</span>
				<p style={{ color: "red" }}>{customErrors}</p>
			</>
		);
	} else if (type === "code") {
		return (
			<Box style={{ display: "flex" }}>
				{assignConv && selectedConversation.header.type !== "call" && (
					<Tooltip title={i18n.t("TabInfo.addInEditor")} placement="top">
						<IconButton
							onClick={() =>
								handleAddContactItem(codes.find(obj => obj.value === value)?.label || "", type)
							}
							size="small"
						>
							<NoteAddOutlinedIcon />
						</IconButton>
					</Tooltip>
				)}
				<FormControl
					fullWidth
					onClick={() => copyValue(codes.find(obj => obj.value === value)?.label || "")}
					style={{ paddingBottom: "2%" }}
				>
					<InputLabel id="select-label">{label}</InputLabel>
					<Select
						labelId="select-label"
						id="select"
						label={label}
						value={value || ""}
						onChange={onChange}
						name={child}
						disabled={readOrEdit}
					>
						{codes
							.sort((a, b) => a.label.localeCompare(b.label))
							.map((code, index) => (
								<MenuItem key={index} value={code.value}>
									{code.label}
								</MenuItem>
							))}
					</Select>
					<p style={{ color: "red" }}>{customErrors}</p>
				</FormControl>
			</Box>
		);
	} else {
		return (
			<>
				<div style={{ display: "flex" }}>
					{assignConv && selectedConversation.header.type !== "call" && (
						<Tooltip title={i18n.t("TabInfo.addInEditor")} placement="top">
							<IconButton onClick={() => handleAddContactItem(value, type)} size="small">
								<NoteAddOutlinedIcon />
							</IconButton>
						</Tooltip>
					)}
					<Tooltip
						title={value}
						disableHoverListener={PI}
						disableTouchListener={PI}
						disableFocusListener={PI}
						placement="top"
					>
						<span>{whiteListItem()}</span>
					</Tooltip>
					<p style={{ color: "red" }}>{customErrors}</p>
				</div>
			</>
		);
	}
};

function OrderFormDictionaryChild({
	fieldChild,
	values,
	handleInputChangeValidate,
	userLangage,
	root,
	formDictionary,
	handleRemoveProduct,
	handleAddProduct,
	customErrors,
	PI,
	assignConv,
	contextDictionary
}) {
	const classes = useStyles();
	const assistantconfig = useSelector(state => state.assistantconfig);

	return (
		<>
			<Box>
				{Object.keys(fieldChild).map((child, key) => {
					if (fieldChild[child].type !== "level" && typeof fieldChild[child] === "object") {
						let rootConfig = root === "" ? `${child}` : root + `.${child}`;

						let formConfig = lod_.get(formDictionary, rootConfig);

						if (fieldChild?.type === "level" && fieldChild?.isArray) {
							return (
								<>
									{assistantconfig?.cockpit?.editTabInfo !== false && (
										<Tooltip title={i18n.t("OrderForm.addProduct")}>
											<IconButton
												onClick={() => handleAddProduct(root || child, rootConfig)}
												disabled={!PI}
											>
												<AddRounded color="primary" fontSize="large" />
											</IconButton>
										</Tooltip>
									)}

									{fieldChild?.value.map((product, index) => {
										let rootArray = root + `[${index}]`;
										let rootArrayDico = root + `.value[${index}]`;
										let getTitle = lod_.get(values, rootArray);

										let titleArray = fieldChild[userLangage];

										if (
											!lod_.isNil(getTitle?.labelArray) &&
											typeof getTitle?.labelArray === "string" &&
											getTitle?.labelArray !== ""
										) {
											titleArray = getTitle?.labelArray;
										}

										return (
											<ChildAccordionArrayMapper
												key={index}
												fieldChild={fieldChild.value[index]}
												index={index}
												contextDictionary={contextDictionary}
												title={titleArray}
												classes={classes}
												values={values}
												root={rootArray}
												handleRemoveProduct={handleRemoveProduct}
												rootPath={rootArrayDico}
												PI={PI}
												formDictionary={formDictionary}
												userLangage={userLangage}
												customErrors={customErrors}
												handleInputChangeValidate={handleInputChangeValidate}
												assignConv={assignConv}
											/>
										);
									})}
								</>
							);
						}
					}
				})}
			</Box>
			<Box
				style={{
					display: "flex",
					flexWrap: "wrap",
					flexDirection: "row",
					justifyContent: "flex-start"
				}}
			>
				{Object.keys(fieldChild)
					.sort((a, b) => fieldChild[a]["order"] - fieldChild[b]["order"])
					.map((child, key) => {
						if (fieldChild[child].type !== "level" && typeof fieldChild[child] === "object") {
							const rootConfig = `${root}.${child}`;
							let formConfig = lod_.get(formDictionary, rootConfig);

							// Bug - formConfig seems empty here

							if (!Array.isArray(fieldChild[child])) {
								const inputStyle = { margin: "0.5em 0 0.5em 0" };
								return (
									<Box
										key={key}
										style={{
											padding: "0em 1.5em 0em 0em"
										}}
									>
										<InpuType
											isEdit={fieldChild[child]?.isEdit}
											codes={fieldChild[child]?.codes}
											child={rootConfig}
											isDisplay={fieldChild[child]?.display}
											styleChild={inputStyle}
											label={fieldChild[child][userLangage]}
											key={key}
											assignConv={assignConv}
											PI={PI}
											whitelistDynamic={fieldChild[child]?.whitelistDynamic}
											whitelist={fieldChild[child]?.whitelist}
											type={fieldChild[child]?.type}
											value={lod_.get(values, rootConfig)}
											customErrors={lod_.get(customErrors, rootConfig)}
											onChange={e => handleInputChangeValidate(e, fieldChild[child], rootConfig)}
										></InpuType>
									</Box>
								);
							}
						}
					})}
			</Box>
			{Object.keys(fieldChild)
				.sort((a, b) => fieldChild[a]["order"] - fieldChild[b]["order"])
				.map((child, key) => {
					if (fieldChild[child].type === "level" && typeof fieldChild[child] === "object") {
						return (
							<Accordion
								style={{ paddingTop: "2px", margin: "1px" }}
								key={key}
								className="classAccordeonTabInfo"
								defaultExpanded={true}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									{fieldChild[child][userLangage]}
								</AccordionSummary>
								<AccordionDetails>
									<OrderFormDictionaryChild
										fieldChild={fieldChild[child]}
										userLangage={userLangage}
										root={`${root}.${child}`}
										values={values}
										key={key}
										PI={PI}
										handleInputChangeValidate={handleInputChangeValidate}
										formDictionary={formDictionary}
										customErrors={customErrors}
										handleRemoveProduct={handleRemoveProduct}
										handleAddProduct={handleAddProduct}
										assignConv={assignConv}
									></OrderFormDictionaryChild>
								</AccordionDetails>
							</Accordion>
						);
					}
				})}
		</>
	);
}

const ChildAccordionArrayMapper = ({
	fieldChild,
	classes,
	values,
	handleRemoveProduct,
	root,
	rootPath,
	PI,
	userLangage,
	customErrors,
	handleInputChangeValidate,
	formDictionary,
	handleAddProduct,
	contextDictionary,
	index,
	title,
	assignConv
}) => {
	return (
		<Accordion style={{ margin: "0em 0.5em 1em " }} className="customAccordion" key={index}>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				{!lod_.isNil(index) && (
					<Box style={{ flex: "0 0 50px" }}>
						<div className={classes.numberCircle}>{index + 1}</div>
					</Box>
				)}

				<Typography>
					<span style={{ fontWeight: "bolder" }}>{title} </span>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Box
					style={{
						display: "flex",
						flexWrap: "wrap",
						flexDirection: "row",
						justifyContent: "flex-start"
					}}
				>
					{Object.keys(fieldChild)
						.sort((a, b) => fieldChild[a].order - fieldChild[b].order)
						.map((child, key) => {
							if (fieldChild[child].type !== "level" && typeof fieldChild[child] === "object") {
								let rootPathArrayDico = rootPath === "" ? `${child}` : rootPath + `.${child}`;
								let rootPathArray = root === "" ? `${child}` : root + `.${child}`;
								let formConfig = lod_.get(formDictionary, rootPathArrayDico);
								const inputStyle = { margin: "0.5em 0 0.5em 0" };

								return (
									<Box key={key} style={{ padding: "0em 0.5em 1em 0.5em" }}>
										<InpuType
											isEdit={fieldChild[child]?.isEdit}
											isDisplay={fieldChild[child]?.display}
											codes={fieldChild[child]?.codes}
											child={rootPathArray}
											styleChild={inputStyle}
											label={fieldChild[child][userLangage]}
											key={key}
											assignConv={assignConv}
											PI={PI}
											whitelistDynamic={fieldChild[child]?.whitelistDynamic}
											whitelist={fieldChild[child]?.whitelist}
											type={fieldChild[child]?.type}
											value={lod_.get(values, rootPathArray)}
											customErrors={lod_.get(customErrors, rootPathArray)}
											onChange={e => handleInputChangeValidate(e, fieldChild[child], rootPathArray)}
										></InpuType>
									</Box>
								);
							}
						})}
				</Box>
				{Object.keys(fieldChild)
					.sort((a, b) => fieldChild[a].order - fieldChild[b].order)
					.map((child, key) => {
						if (
							fieldChild[child].type === "level" &&
							fieldChild[child].isArray &&
							typeof fieldChild[child] === "object"
						) {
							return (
								<>
									{fieldChild[child].value.map((childArray, keyArray) => {
										let rootPathArray = root === "" ? `${root}` : root + `.${child}[${keyArray}]`;
										let rootPathArrayDico =
											rootPath === "" ? `${rootPath}` : rootPath + `.${child}.value[${keyArray}]`;

										return (
											<ChildAccordionArrayMapper
												key={key}
												fieldChild={fieldChild[child].value[keyArray]}
												index={keyArray}
												assignConv={assignConv}
												contextDictionary={contextDictionary}
												title={fieldChild[child][userLangage]}
												classes={classes}
												values={values}
												root={rootPathArray}
												handleRemoveProduct={handleRemoveProduct}
												rootPath={rootPathArrayDico}
												PI={PI}
												formDictionary={formDictionary}
												userLangage={userLangage}
												customErrors={customErrors}
												handleAddProduct={handleAddProduct}
												handleInputChangeValidate={handleInputChangeValidate}
											/>
										);
									})}
								</>
							);
						} else if (
							fieldChild[child].type === "level" &&
							!fieldChild[child].isArray &&
							typeof fieldChild[child] === "object"
						) {
							let rootPathArray = root === "" ? `${root}` : root + `.${child}`;
							let rootPathArrayDico = rootPath === "" ? `${rootPath}` : rootPath + `.${child}`;
							return (
								<ChildAccordionArrayMapper
									key={key}
									fieldChild={fieldChild[child]}
									title={fieldChild[child][userLangage]}
									classes={classes}
									userLangage={userLangage}
									root={rootPathArray}
									rootPath={rootPathArrayDico}
									values={values}
									keyArray={key}
									PI={PI}
									assignConv={assignConv}
									handleInputChangeValidate={handleInputChangeValidate}
									formDictionary={formDictionary}
									handleRemoveProduct={handleRemoveProduct}
									handleAddProduct={handleAddProduct}
									customErrors={customErrors}
								></ChildAccordionArrayMapper>
							);
						}
					})}
			</AccordionDetails>
		</Accordion>
	);
};

/**
 * Send Form link and filter by dictionnary
 * @param {Object} contextAssistandID the dictionnary of assistant
 * @param {Object} contextDictionaryValue the context of conversation
 * @param {Boolean} accessPI expression if the user want edit value and view PI info
 * @param {Boolean} viewPI expression if the user want view PI info
 * @param {Object} result the form who will be return by the function
 * @param {String} root root of different attribut of context
 */
function getStatsKeys(
	contextAssistandID,
	contextDictionaryValue,
	accessPI,
	viewPI = false,
	result = {},
	root = ""
) {
	//Foreach context and contact of the assistant
	for (let key in contextAssistandID) {
		//Check if "items" exist and is an object
		let isParent = contextAssistandID[key].type === "level";
		const fieldValue = lod_.get(contextAssistandID, key);

		const type = fieldValue.type;
		const display = fieldValue.display;
		const blacklist = fieldValue.blacklist;
		const whitelistDynamic = fieldValue?.whitelistDynamic;
		const whitelist = fieldValue.whitelist;
		const personalInformation = fieldValue.personalInformation;
		const codes = fieldValue.codes;
		const unique = fieldValue.unique;
		const order = fieldValue?.order;
		const defaultValue = fieldValue?.defaultValue;
		const arrayValue = fieldValue?.isArray;

		let isArrayFormat = false;

		let nextTarget = false;

		//Build root to recup value
		const path = root === "" ? key : `${root}.${key}`;

		//Check if is parent
		if (isParent) {
			//Set All info for Formulaire (value, label, ...)
			let valueResult = { ...fieldValue.label, type };
			if (blacklist) {
				valueResult = { ...valueResult, blacklist };
			}

			if (whitelist) {
				valueResult = { ...valueResult, whitelist };
			}
			if (!lod_.isNil(personalInformation)) {
				valueResult = { ...valueResult, personalInformation };
			}
			if (contextAssistandID[key].isArray) {
				if (contextAssistandID[key].type === "level") {
					isParent = true;
					let testArray = lod_.get(contextDictionaryValue, path);
					if (testArray && lod_.isArray(testArray) && !lod_.isEmpty(testArray)) {
						let resultArray = [];
						for (let indexArrayV1 in testArray) {
							let arrayR = [];
							arrayR = getStatsKeysArrayV2(
								contextAssistandID[key].items,
								contextDictionaryValue,
								accessPI,
								viewPI,
								{},
								path + `[${indexArrayV1}]`
							);

							let resultArrayKey = lod_.get(arrayR, path + `[${indexArrayV1}]`) || [];
							resultArray.push(resultArrayKey);
						}

						valueResult.value = resultArray;
						valueResult.isArray = true;
						lod_.set(result, path, valueResult);
					}
					nextTarget = true;
				} else {
					isParent = false;
					isArrayFormat = true;
				}
			} else {
				isArrayFormat = false;
				isParent = true;
			}
			//Check if value is not null and active to view in front
			if (
				lod_.get(contextDictionaryValue, path) &&
				contextAssistandID[key].active &&
				!accessPI &&
				!nextTarget
			) {
				//Check if is array and is not empty
				if (isArrayFormat) {
					lod_.set(result, path, valueResult);
				}
				//Check if is not a array
				if (!isArrayFormat) {
					lod_.set(result, path, valueResult);
				}
			}
			//Set value null or empty to edit format
			if (accessPI && !nextTarget) {
				lod_.set(result, path, valueResult);
			}
		}

		//Check if is not parent
		if (!isParent) {
			//Set All info for Formulaire (value, label, ...)
			let valueResult = { ...fieldValue.label, type };
			if (blacklist) {
				valueResult = { ...valueResult, blacklist };
			}
			if (whitelist) {
				valueResult = { ...valueResult, whitelist };
			}
			if (whitelistDynamic) {
				valueResult = { ...valueResult, whitelistDynamic };
			}
			if (unique) {
				valueResult = { ...valueResult, unique };
			}
			if (order) {
				valueResult = { ...valueResult, order };
			}
			if (codes) {
				valueResult = { ...valueResult, codes };
			}

			if (!lod_.isNil(display)) {
				valueResult = { ...valueResult, display };
			}

			if (defaultValue) {
				valueResult = { ...valueResult, defaultValue };
			}

			if (arrayValue) {
				valueResult = { ...valueResult, isArray: arrayValue };
			}

			if (!lod_.isNil(personalInformation)) {
				valueResult = { ...valueResult, personalInformation };
			}

			if (isArrayFormat) {
				let valueList = [];
				const isEdit =
					fieldValue.actions.edit === undefined || fieldValue.actions.edit === null
						? true
						: fieldValue.actions.edit;
				//Check if value is not null and active to view in front
				if (lod_.get(contextDictionaryValue, path)) {
					let arrayList = lod_.get(contextDictionaryValue, path);
					//Check if succes get info of array with path
					if (arrayList) {
						//Set All info in array for Formulaire (value, label, ...)
						for (var child in arrayList) {
							let valueObject = {};
							for (var value in arrayList[child]) {
								if (contextAssistandID[key].items[value].active) {
									let valueItems = arrayList[child][value] || null;
									let typeArray = contextAssistandID[key].items[value].type;
									let editArray = contextAssistandID[key].items[value].actions.edit;
									let displayArray = contextAssistandID[key].items[value].display;
									let label = { ...contextAssistandID[key].items[value].label };

									valueObject[value] = {
										value: valueItems,
										type: typeArray,
										isEdit: editArray,
										...label,
										display: displayArray
									};
								}
								valueList[child] = valueObject;
							}

							let valueSet = { ...valueResult, value: valueList };

							if (lod_.get(contextDictionaryValue, path).length !== 0 && !accessPI) {
								lod_.set(result, `${path}`, valueSet);
							}

							if (accessPI) {
								lod_.set(result, `${path}`, valueSet);
							}
						}
					} else {
						//Set value null or empty to edit format
						lod_.set(result, `${path}`, { ...lod_.get(result, `${path}`), value: valueList });
					}
				} else {
					if (isEdit) {
						lod_.set(result, `${path}`, { ...lod_.get(result, `${path}`), value: valueList });
					}
				}
			}
			//Check if is not a array
			if (!isArrayFormat && contextAssistandID[key].active) {
				let value = "";
				value = lod_.get(contextDictionaryValue, path, null);
				if (arrayValue && (value === null || !lod_.isArray(value))) {
					value = "";
				}
				if (type === "string" && (value === null || lod_.isArray(value)) && !arrayValue) {
					value = "";
				}
				if (type === "number" && (value === null || lod_.isArray(value)) && !arrayValue) {
					value = null;
				}

				const isEdit =
					fieldValue.actions?.edit === undefined || fieldValue.actions?.edit === null
						? true
						: fieldValue?.actions.edit;

				//Check if value is not null and active to view in front
				if (!accessPI && (value || value === 0 || value === false)) {
					//Check if value is PI and if user want to see PI
					if (valueResult?.personalInformation && !viewPI) {
						value = "masked";
					}
					valueResult = { ...valueResult, value, isEdit };
					lod_.set(result, path, valueResult);
				}
				//Set value null or empty to edit format
				if (accessPI) {
					valueResult = { ...valueResult, value, isEdit };
					lod_.set(result, path, valueResult);
				}
			}
		} else if (!nextTarget) {
			for (let indexLabel in contextAssistandID[key].items) {
				//Build root for parent
				const nextRoot = root === "" ? `${key}` : root + `.${key}`;

				//Recusive function for parent
				getStatsKeys(
					contextAssistandID[key].items,
					contextDictionaryValue,
					accessPI,
					viewPI,
					result,
					nextRoot
				);
			}
		}
	}
	return result;
}

/**
 * Send Form link and filter by dictionnary
 * @param {Object} contextAssistandID the dictionnary of assistant
 * @param {Object} contextDictionaryValue the context of conversation
 * @param {Boolean} accessPI expression if the user want edit value and view PI info
 * @param {Boolean} viewPI expression if the user want view PI info
 * @param {Object} result the form who will be return by the function
 * @param {String} root root of different attribut of context
 * @param {String} arrayRoot root of different attribut of contextDictionaryValue
 */
function getStatsKeysArrayV2(
	contextAssistandID,
	contextDictionaryValue,
	accessPI,
	viewPI = false,
	result = {},
	root = ""
) {
	for (let key in contextAssistandID) {
		let pathArray = root === "" ? `${root}` : `${root}.${key}`;

		// let valueArray = lod_.get(contextDictionaryValue, pathArray);
		//Check if "items" exist and is an object
		let isParent = contextAssistandID[key].type === "level";

		if (isParent) {
			if (contextAssistandID[key].isArray === true) {
				let testArray = lod_.get(contextDictionaryValue, pathArray);

				let nextRoot = root === "" ? `${key}` : root + `.${key}`;

				let valueObject = {};

				let label = contextAssistandID[key].label;
				let typeArray = contextAssistandID[key].type;

				valueObject[key] = {
					type: typeArray,
					isArray: true,
					...label
				};
				let arrayBack = [];

				for (let indexArrayV2 in testArray) {
					let arrayValue = [];
					arrayValue = getStatsKeysArrayV2(
						contextAssistandID[key].items,
						contextDictionaryValue,
						accessPI,
						viewPI,
						result,
						nextRoot + `[${indexArrayV2}]`
					);
					let pushValue = lod_.get(arrayValue, root) || [];
					arrayBack.push(pushValue[key][indexArrayV2]);
				}

				valueObject[key].value = arrayBack;
				lod_.set(result, pathArray, valueObject[key]);
			} else {
				let valueObject = {};

				let label = contextAssistandID[key].label;
				let orderArray = contextAssistandID[key]?.order;

				let typeArray = contextAssistandID[key].type;

				valueObject[key] = {
					type: typeArray,
					order: orderArray,

					...label
				};

				if (!accessPI && lod_.get(contextDictionaryValue, pathArray))
					lod_.set(result, pathArray, valueObject[key]);

				if (accessPI) lod_.set(result, pathArray, valueObject[key]);

				let nextRoot = root === "" ? `${key}` : root + `.${key}`;

				getStatsKeysArrayV2(
					contextAssistandID[key].items,
					contextDictionaryValue,
					accessPI,
					viewPI,
					result,
					nextRoot
				);
			}
		} else {
			let valueObject = {};
			let valueItems = lod_.get(contextDictionaryValue, pathArray, null);
			let label = contextAssistandID[key].label;
			let typeArray = contextAssistandID[key].type;
			let orderArray = contextAssistandID[key]?.order;
			let editArray = contextAssistandID[key]?.actions?.edit || true;
			let isArray = contextAssistandID[key].isArray || false;
			let displayArray = contextAssistandID[key].display;

			valueObject[key] = {
				value: valueItems,
				type: typeArray,
				isEdit: editArray,
				order: orderArray,
				isArray,
				...label,
				display: displayArray
			};
			if (valueItems && !accessPI) lod_.set(result, pathArray, valueObject[key]);

			if (accessPI) lod_.set(result, pathArray, valueObject[key]);
		}
	}
	return result;
}

const DisplayItem = (suggestedValue, shownFields = ["name"]) => {
	return (
		<Box>
			{shownFields.map((key, index) => {
				return (
					<>
						{index === 0 && (
							<Box key={`shownFields_${index}`} fontWeight="fontWeightRegular">
								{lod_.get(suggestedValue, key)}
							</Box>
						)}
						{index !== 0 && (
							<Box key={`shownFields_${index}`} fontStyle="oblique" fontWeight="fontWeightLight">
								{lod_.get(suggestedValue, key)}
							</Box>
						)}
					</>
				);
			})}
		</Box>
	);
};

const DisplayPossibleChannel = ({ type, selectedConversation, value }) => {
	const assistantconfig = useSelector(state => state.assistantconfig);
	const { isCockpitArchived } = useSelector(state => state.cockpit);

	const dispatch = useDispatch();

	const [possibleChannels, setPossibleChannels] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleDropDownMenu = event => {
		return setAnchorEl(event.currentTarget);
	};

	const handleCloseEngageDropDown = () => {
		setAnchorEl(null);
	};

	const handleEngagedialog = channel => {
		// New engage Epic https://faibrikplatform.atlassian.net/browse/FBDB-577 for all channels
		const channelCode = channel?.code;

		if (channelCode) {
			if (type === "phone") {
				let data = {
					header: {
						assistantID: selectedConversation?.header?.assistantID,
						fID: selectedConversation?.header?.fID
					},
					payload: {
						channelCode: channelCode,
						phone: value
					}
				};
				dispatch(cockpitActions.launchActionFrontV2("triggerOutboundCall", data, null, "event"));
			} else {
				dispatch(
					engageActions.createEngageConversation(
						channelCode,
						{
							engageLinkContact: value,
							byPassTimeOut: true
						},
						null,
						null,
						null,
						selectedConversation?.header?.fID
					)
				);
			}
		}
	};

	const handleClickEngage = event => {
		if (Array.isArray(possibleChannels) && possibleChannels.length === 1) {
			handleEngagedialog(possibleChannels[0]);
		} else {
			handleDropDownMenu(event);
		}
	};

	useEffect(() => {
		if (assistantconfig && assistantconfig.channels) {
			let typeChannel = "";
			let channelByAssistant;
			if (!lod_.isNil(type) && !lod_.isEmpty(type)) {
				switch (type) {
					case "email":
						typeChannel = "ML";
						break;
					case "phone":
						typeChannel = "PH";
						break;
					default:
						typeChannel = "ML";
						break;
				}
				channelByAssistant = assistantconfig.channels.filter(
					a => a.engage === true && a.type === typeChannel
				);
			} else {
				channelByAssistant = assistantconfig.channels.filter(a => a.engage === true);
			}

			if (channelByAssistant.length > 0) {
				if (channelByAssistant[0] !== undefined) {
					channelByAssistant = channelByAssistant.filter(a => {
						if (
							a.engage === true &&
							(lod_.isNil(a?.engageFilter) || lod_.isEmpty(a?.engageFilter))
						) {
							return a;
						} else if (
							a.engage === true &&
							(!lod_.isNil(a?.engageFilter) || !lod_.isEmpty(a?.engageFilter))
						) {
							const keysPath = Object.keys(a.engageFilter);
							const path = keysPath[0];
							const value = a?.engageFilter[path];
							const valueContext = lod_.get(selectedConversation, path);
							if (!lod_.isNil(value) && !lod_.isNil(valueContext)) {
								if (
									lod_.isString(value) &&
									lod_.isString(valueContext) &&
									value.toLocaleLowerCase() === valueContext.toLocaleLowerCase()
								) {
									return a;
								} else if (value === valueContext) {
									return a;
								}
							}
						}
					});
					let engageableChannels = [];
					channelByAssistant.forEach(aChannel => {
						if (!engageableChannels.includes(aChannel.code)) {
							engageableChannels.push(aChannel);
						}
					});
					setPossibleChannels(engageableChannels);
					if (engageableChannels.length == 1) {
						cockpitActions.saveDefaultChannel(engageableChannels[0], false);
					}
				}
			}
		}
	}, [assistantconfig, selectedConversation.header.fID]);

	return (
		<Box>
			{possibleChannels !== null && !isCockpitArchived && possibleChannels.length !== 0 && (
				<Box pl="3%" pr="3%" textAlign="center">
					<IconButton
						size="small"
						ml={2}
						mx={2}
						onClick={e => {
							handleClickEngage(e);
						}}
					>
						<AddIcon />
					</IconButton>
				</Box>
			)}

			{possibleChannels !== null && possibleChannels.length > 1 && anchorEl && (
				<EngageDropDown
					possibleChannels={possibleChannels}
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
					engageFromContext={true}
					handleClose={handleCloseEngageDropDown}
					handleOpenEngageDialog={handleEngagedialog}
				/>
			)}
		</Box>
	);
};

export default ContextCallinterface;
