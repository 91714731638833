/* eslint-disable react/display-name */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */

import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import i18n from "i18n";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { TagsList } from "components/Tags/TagsList";
import SearchIcon from "@material-ui/icons/Search";
import _lod from "lodash";
import { InputAdornment, TextField, Typography } from "@material-ui/core";

/**
 *
 * @returns
 */
export function AssignationAction({
	openDialogAssign,
	setOpenDialogAssign,
	assignAllItems,
	selected,
	setSelected
}) {
	const { users } = useSelector(state => state.userGroup);

	const [search, setSearch] = React.useState("");

	/**
	 *
	 * @param {*} event
	 */
	const handleSearchChange = event => {
		setSearch(event.target.value);
	};

	const closeDialog = () => {
		setOpenDialogAssign(false);
		setSearch("");
		setSelected([]);
	};

	const confirm = () => {
		setOpenDialogAssign(false);
		assignAllItems();
	};

	return (
		<Box>
			<Dialog
				fullWidth
				maxWidth="md"
				open={openDialogAssign}
				onClose={() => closeDialog()}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{i18n.t("COC.assignManyTicket")}</DialogTitle>
				<DialogContent dividers>
					<Typography style={{ fontSize: "20px", fontWeight: "bold" }}>Choix agent</Typography>
					<Box>
						<TextField
							fullWidth
							label=""
							variant="outlined"
							size="small"
							onChange={handleSearchChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								)
							}}
						/>
					</Box>
					<TagsList
						list={users
							.filter(user => {
								if (search.trim().length >= 0) {
									let fullSearch = (user.name || "") + " " + (user.surname || "") + " " + user._id;
									return fullSearch.toLowerCase().includes(search.toLowerCase());
								} else {
									return true;
								}
							})
							.sort((a, b) => a.name.localeCompare(b.name))}
						labelField={"name"}
						surnameField={"surname"}
						titleField={"_id"}
						valueField={"_id"}
						isForm={true}
						selected={selected}
						setSelected={setSelected}
						isUser
						multiple={false}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => confirm()} color="primary" disabled={selected.length === 0}>
						{`${i18n.t("COC.Apply")}`}
					</Button>

					<Button onClick={() => closeDialog()} color="secondary">
						{`${i18n.t("COC.Cancel")}`}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
