import { Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMesssageItemSelector } from "redux/selectors/cockpitSelectors";
import { shallowEqual } from "helpers/utilities";
import cockpitActions from "redux/actions/cockpitActions";
import { getLabelOf } from "helpers/utilities";
import i18n from "i18n";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import { errorMsg } from "redux/reducers/snackMsgsReducers";
import UpdateConvIntentDialog from "../Convs/UpdateConvIntentDialog";
import { setElementFeedback } from "redux/reducers/conversationReducers";

/**
 * Update message's intent
 * @param {*} param0
 * @returns
 */
export const UpdateIntent = ({ message, msgIndex }) => {
	const dispatch = useDispatch();
	const { intents, userStatus, selectedAssistantID, convHistory, selectedConversation } =
		useSelector(getMesssageItemSelector, shallowEqual);
	const [openUpdateConvIntentDialog, setOpenUpdateConvIntentDialog] = useState(false);
	const [currentMIDUpdateIntent, setCurrentMIDUpdateIntent] = useState("");
	const conversation = convHistory ? convHistory : selectedConversation;
	const conversationfID = conversation?.header.fID;

	/**
	 *
	 * @param {*} mID
	 */
	const handleOpenUpdateConvIntentDialog = mID => {
		setCurrentMIDUpdateIntent(mID);
		setOpenUpdateConvIntentDialog(true);
	};

	/**
	 *
	 */
	const handleCloseUpdateConvIntentDialog = () => {
		setOpenUpdateConvIntentDialog(false);
	};

	/**
	 *
	 * @param {*} data
	 */
	const handleSetFeedbackSuccess = data => {
		//TODO: this is bad: to edit the message we must update the store
		message.meta.intent = data.intent || message.meta.intent;
		dispatch(infoMsg(data.msg));
	};

	/**
	 *
	 * @param {*} msg
	 */
	const handleSetFeedbackFailure = msg => {
		dispatch(errorMsg(msg));
	};

	/**
	 * Modifier l'intention en ajoutant un feedback (négatif)
	 */
	const handleUpdateMessageIntent = (selectedIntent, isGlobalIntent) => {
		let type = "intent";
		let action = "set";
		let value = "change";
		let reviewingAgent = {
			nickname: userStatus.auth.user.nickname,
			name: userStatus.auth.user.name,
			email: userStatus.auth.user.email
		};
		let successMessage = i18n.t("COC.sendFeedbackSuccessSetSentimentNegative");
		let failMessage = i18n.t("COC.sendFeedbackFail");
		let mID = message.header.mID;

		//alert feedback changed
		dispatch(
			setElementFeedback({
				element: "intent",
				message: mID,
				feedback: "change"
			})
		);

		//TODO: use api middleWare
		dispatch(
			cockpitActions.sendFeedback(
				type,
				action,
				value,
				userStatus.auth.user,
				message,
				msgIndex,
				selectedAssistantID,
				conversationfID,
				conversation._id,
				reviewingAgent,
				handleSetFeedbackSuccess,
				successMessage,
				handleSetFeedbackFailure,
				failMessage,
				mID,
				selectedIntent,
				isGlobalIntent
			)
		);
	};

	return (
		<>
			<Tooltip title={i18n.t("COC.UpdateConvIntent")} placement="top">
				<p
					style={{
						cursor: "pointer",
						marginBottom: 0,
						paddingRight: 4
					}}
					onClick={() => handleOpenUpdateConvIntentDialog(message.header.mID)}
				>
					{message.meta?.intent
						? getLabelOf(message.meta.intent)
						: getLabelOf(message.understand?.topic)}
				</p>
			</Tooltip>
			<UpdateConvIntentDialog
				intents={intents}
				open={openUpdateConvIntentDialog}
				mID={currentMIDUpdateIntent}
				topic={false}
				handleUpdateMessageIntent={handleUpdateMessageIntent}
				handleCloseUpdateConvIntentDialog={handleCloseUpdateConvIntentDialog}
			/>
		</>
	);
};
