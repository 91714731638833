/* eslint-disable react/prop-types */
/*  eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  no-unused-vars */
import { Box, ListItem, Paper, Snackbar, DialogTitle } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import cockpitActions from "redux/actions/cockpitActions";
import contactActions from "redux/actions/contactActions";

import HistoricList from "../../../helpers/HistoricList";
import HistoricListCold from "../../../helpers/HistoricListCold";
import { formatDate, handleSpecialDisplayDate } from ".././../../helpers/utilities";
import ConfirmationDownloadDialog from "./ConfirmationDownloadDialog";
import ConfirmationDeleteConvDialog from "./ConfirmationDeleteConvDialog";

const endPoint = process.env.REACT_APP_AMBACK + "/api/v1/";

const TabActionContact = props => {
	const useStyles = makeStyles(theme => ({
		paper: {
			top: "10%",
			backgroundColor: theme.palette.background.paper,
			left: "10%",
			overflow: "scroll",
			height: 600,
			display: "block",
			padding: theme.spacing(2, 4, 3)
		}
	}));

	const classes = useStyles();

	const [snackbarStatus, setSnackbarStatus] = React.useState({
		open: false,
		message: ""
	});
	const [displaySpinner, setDisplpaySpinner] = useState(false);
	const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [conversations, setConversations] = useState([]);
	const [loading, setLoading] = useState(false);
	const [openHistory, setOpenHistory] = React.useState(false);
	const [action, setAction] = useState(props.action);
	const user = props.userStatus && props.userStatus.auth && props.userStatus.auth.user;

	useEffect(() => {
		if (props.action) return setAction(props.action);
	}, []);

	const handleCloseHistory = (event, reason) => {
		if (reason === "backdropClick") {
			setOpenHistory(false);
		}
	};

	useEffect(() => {
		(async () => {
			await getHistoricConversations();
		})();
	}, []);
	/**
	 * Fetch conversation for selectectContact
	 */
	const getHistoricConversations = async () => {
		setLoading(true);
		let ML_id = props.contact.email ? props.contact.email : false;
		let FRUFromContact = props.contact && props.contact.FRU;
		if (user && (ML_id || FRUFromContact)) {
			let url = `${endPoint}${props.selectedAssistantID}/contact/history/${ML_id}/${FRUFromContact}`;

			const res = await axios.get(url, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "POST",
				data: props.contact
			});

			if (res) {
				setConversations(res.data && res.data.allConversations);
			}
			setLoading(false);
		}
	};
	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarStatus({ open: false, message: "" });
	};

	const handleDisplayModal = () => {
		setOpenHistory(true);
	};

	const handleDisplayDownloadDialog = () => {
		if (displaySpinner || props.isDownloading) return;
		else setIsDownloadDialogOpen(!isDownloadDialogOpen);
	};
	const handleDisplayDeleteDialog = () => {
		setIsDeleteDialogOpen(true);
	};
	const handleCloseDeleteDialog = () => {
		return setIsDeleteDialogOpen(!isDeleteDialogOpen);
	};
	const handleDisplayDialog = () => {
		switch (action) {
			case "contactDownloadConversationAttachmentZIP":
				handleDisplayDownloadDialog();
				break;
			case "contactDisplayHistory":
				handleDisplayModal();
				break;
			case "contactDeleteConversations":
				handleDisplayDeleteDialog();
				break;
			default:
				return;
			// code block
		}
	};
	return (
		<div>
			<ListItem>
				<Box
					width={1}
					style={{
						cursor: "pointer"
					}}
					onClick={handleDisplayDialog}
				>
					<Paper elevation={3}>
						<Box display="flex" p={1} my={1} alignItems="center" justifyContent="space-between">
							{action === "contactDownloadConversationAttachmentZIP" && (
								<ConfirmationDownloadDialog
									isDownloadDialogOpen={isDownloadDialogOpen}
									handleDisplayDialog={handleDisplayDownloadDialog}
									contact={props.contact}
									action={props.action}
									expectFile={true}
									language={props.language}
									setDisplpaySpinner={setDisplpaySpinner}
									displaySpinner={displaySpinner}
									t={props.t}
									isDownloading={props.isDownloading}
									setIsDownloading={props.setIsDownloading}
									setSnackbarStatus={setSnackbarStatus}
								/>
							)}
							{action === "contactDeleteConversations" && isDeleteDialogOpen && (
								<ConfirmationDeleteConvDialog
									isDeleteDialogOpen={isDeleteDialogOpen}
									handleDisplayDeleteDialog={handleDisplayDeleteDialog}
									handleCloseDeleteDialog={handleCloseDeleteDialog}
									contact={props.contact}
									action={props.action}
									language={props.language}
									setDisplpaySpinner={setDisplpaySpinner}
									displaySpinner={displaySpinner}
									t={props.t}
									setSnackbarStatus={setSnackbarStatus}
									handleCloseContactModal={props.handleCloseContactModal}
									getContacts={props.getContacts}
								/>
							)}
							<Box m={1} display="flex" marginLeft="5px">
								<div className="logoContainer">
									<img
										className="logoActions"
										alt={props.name}
										src={props.logo}
										style={{ objectFit: "contain" }}
									></img>
								</div>
							</Box>

							<Box>{props.t(`TabAction.${props.action}`)} </Box>

							<Box fontWeight="fontWeightMedium" display="flex">
								{displaySpinner ? (
									<CircularProgress size={20} />
								) : (
									<PlayArrowOutlinedIcon size="small" />
								)}
							</Box>
						</Box>
						<Box
							bgcolor="#d8d6d6"
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							{action === "contactDisplayHistory" &&
								openHistory &&
								props.numberConversation >= 1 && (
									<Dialog
										onClose={handleCloseHistory}
										aria-labelledby="simple-dialog-title"
										maxWidth="md"
										fullWidth={true}
										open={openHistory}
									>
										<DialogTitle
											id="simple-modal-title"
											style={{
												borderBottom: "1px solid",
												borderColor: "#d8d6d6",
												padding: "16px"
											}}
										>
											Conversation(s)
										</DialogTitle>
										<div className={classes.paper}>
											{conversations.map((conv, index) => {
												return (
													<HistoricList
														t={props.t}
														conversation={conv}
														number={index}
														key={`historicList${index}`}
														history={conversations}
														formatDate={formatDate}
														handleSpecialDisplayDate={handleSpecialDisplayDate}
													/>
												);
											})}
											{props.conversationsCold.map((conv, index) => {
												return (
													<HistoricListCold conversation={conv} key={`historicList${index}`} />
												);
											})}
										</div>
									</Dialog>
								)}
						</Box>
					</Paper>
				</Box>
			</ListItem>
			<Snackbar
				anchorOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				open={snackbarStatus.open}
				onClose={handleCloseSnackbar}
				autoHideDuration={2000}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity={snackbarStatus.severity ? snackbarStatus.severity : "info"}
				>
					{snackbarStatus.message}
				</Alert>
			</Snackbar>
		</div>
	);
};
// eslint-disable-next-line no-unused-vars
function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch),
		contactActions: bindActionCreators(contactActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(TabActionContact);
