/* eslint-disable no-fallthrough */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import lod_ from "lodash";
import { useSelector } from "react-redux";
import TabAction from "helpers/TabAction";
import { Box, Card, Divider } from "@material-ui/core";
import ConversationAction from "./ConversationAction";
import ReplyAssistantModal from "../ConvActions/ReplyAssistant";
import AnalyseDocumentLlmModal from "../ConvActions/AnalyseDocumentLlm";

/**
 *
 */
export const ActionsComponent = ({
	rcvAttAll,
	sntAttAll,
	popoverCloseMenu,
	popoverActionsHandleClose
}) => {
	const { sortedBuiltInAnswers } = useSelector(state => state.answers);
	const { selectedConversation, isCockpitArchived } = useSelector(state => state.cockpit);
	const { selectedAssistantID, assistantconfig } = useSelector(state => state);

	const [conversationActions, setConversationActions] = useState([]);
	const [longActions, setLongActions] = useState([]);

	const [actionModal, setActionModal] = useState({
		action: "",
		open: false,
		data: {}
	});

	/**
	 * Handle action result when user clicks on an "view result" in action
	 * @param {*} action
	 * @param {*} data
	 */
	const handleActionResult = data => {
		setActionModal({
			action: data.code,
			open: true,
			data: data
		});
	};

	const closeModal = () => {
		setActionModal({
			action: "",
			open: false,
			data: {}
		});
	};

	const getOpenModal = action => {
		return actionModal.action === action && actionModal.open;
	};

	const getModalData = action => {
		return actionModal.action === action ? actionModal.data : {};
	};

	useEffect(() => {
		if (assistantconfig?.actions) {
			let conversationActions = assistantconfig.actions.filter(e => e.type === "conversation");
			if (!lod_.isEmpty(conversationActions)) {
				const actionsSorted = lod_.sortBy(conversationActions, ["displayOrder"]);
				setConversationActions(actionsSorted);
			}
		}
	}, [assistantconfig.actions, selectedAssistantID]);

	useEffect(() => {
		if (selectedConversation.actions) {
			let actions = lod_.cloneDeep(selectedConversation.actions);
			setLongActions(actions.reverse() ?? []);
		}
	}, [selectedConversation.actions, selectedAssistantID]);

	return (
		<>
			<Card
				style={{
					padding: 10,
					minWidth: "30vw"
				}}
			>
				{/* Actions in progress */}
				{!lod_.isEmpty(longActions) &&
					longActions.map((action, index) => {
						return (
							<ConversationAction
								key={index}
								action={action}
								onResult={data => {
									handleActionResult(data);
									popoverActionsHandleClose();
								}}
							/>
						);
					})}
				{!lod_.isEmpty(longActions) && (
					<Box mt={3} mb={3}>
						<Divider />
					</Box>
				)}
				{/* Display actions */}
				{!lod_.isEmpty(conversationActions) &&
					conversationActions.map(action => {
						// If user is in cockpitArchived tab, display only filtered actions
						if (isCockpitArchived && !action?.filter?.archived) {
							return;
						}
						if (!isCockpitArchived && action?.filter?.archivedOnly) {
							return;
						}

						// Check if action is valid for the channel
						if (
							action.valid_for_channel_code &&
							!action.valid_for_channel_code.includes(selectedConversation.header.channel)
						) {
							return;
						}
						return (
							<TabAction
								key={`${action.name}-${action.index}`}
								name={action.name}
								description={action.description}
								multipleAction={action.multipleAction}
								selectedConversation={selectedConversation}
								logo={action.logo}
								action={action.action}
								events={action.events}
								rcvAttAll={rcvAttAll}
								sntAttAll={sntAttAll}
								builtInAnswers={sortedBuiltInAnswers}
								queryParams={action.queryParams}
								actionObject={action}
								popoverCloseMenu={popoverCloseMenu}
								popoverActionsHandleClose={popoverActionsHandleClose}
							/>
						);
					})}
			</Card>
			{/* Reply assistant action */}
			<ReplyAssistantModal
				data={getModalData("replyAssistant")}
				open={getOpenModal("replyAssistant")}
				onClose={closeModal}
			/>
			{/* Analyse document LLM action */}
			<AnalyseDocumentLlmModal
				data={getModalData("analyseDocumentLlm")}
				open={getOpenModal("analyseDocumentLlm")}
				onClose={closeModal}
			/>
		</>
	);
};
