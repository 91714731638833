/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useState } from "react";
import lod_ from "lodash";
import { Box, IconButton, Menu, Snackbar, Tooltip } from "@material-ui/core";
import ContactEditDialog from "../pages/Contacts/ContactEditDialog";
import GetChannelImage from "./GetChannelImage";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getSchemaInternationalised } from "../pages/Contacts/schemaContact";
import { MenuItem } from "@mui/material";
import Fade from "@material-ui/core/Fade";
import i18n from "i18n";
import { getChannelUserInfoMembershipDisplayerSelector } from "redux/selectors/cockpitSelectors";
import cockpitActions from "../redux/actions/cockpitActions";
import { traceError, UPDATE_MARKETPLACE_FORBIDDEN } from "./utilities";
import EngageEditContact from "../pages/Engage/EngageEditContact";
import { ArrowDownwardOutlined, ArrowUpwardOutlined, DraftsOutlined } from "@material-ui/icons";
import { directions } from "pages/Cockpit/constants";

const endPoint = process.env.REACT_APP_AMBACK + "/api/v1/";

//display channel image + profile picture if exists + email or first and last name + membership if exists
const ChannelUserInfoMembershipDisplayer = (
	{ contactNameOrEmail, FRU, assistantID, channelImage, fID, channel, displayDialog, connected },
	ref
) => {
	const dispatch = useDispatch();
	let { assistantconfig, selectedConversation, user } = useSelector(
		getChannelUserInfoMembershipDisplayerSelector,
		shallowEqual
	);
	const [contact, setContact] = useState(false);
	const [schema, setSchema] = useState();
	const [snackbarStatus, setSnackbarStatus] = React.useState({
		open: false,
		message: ""
	});
	const [uiSchema, setUiSchema] = useState();
	const [, setLoading] = useState(false);
	const [contactDialogStatus, setContactDialogStatus] = React.useState({
		open: false
	});
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [marketplaces, setMarketplaces] = useState([]);
	const { channels } = useSelector(state => state.assistantconfig);
	useEffect(() => {
		setMarketplaces(
			lod_
				.uniq(assistantconfig?.channels?.map(channel => channel.sourceMarketplace))
				.filter(marketplace => !lod_.isNil(marketplace))
		);
		setAnchorEl(null);
		setContact(false);
	}, [selectedConversation]);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		// Run one time at component start
		let loadSchema = getSchemaInternationalised(i18n.t);
		setSchema(loadSchema.schema);
		setUiSchema(loadSchema.uiSchema);
	}, []);

	const handleCloseEditContactDialog = () => {
		setContactDialogStatus({ open: false });
	};

	const handleShowContact = value => {
		setContact(value);
		setContactDialogStatus({ open: true });
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarStatus({ open: false, message: "" });
	};

	// isEngageEdit: defines if we can edit the contact. This happens only for engage conversation
	// when this component is called with displayDialog true
	const isEngageEdit = displayDialog && selectedConversation.header.substate === "draft";

	//handle display contact profil
	async function handleDisplayContact(user, assistantID, handleShowContact) {
		if (contact === false && !lod_.isNil(FRU)) {
			try {
				let url = `${endPoint}${assistantID}/contact/${FRU}`;
				const res = await axios.get(url, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: { FRU, email: user.email }
				});
				if (res) {
					handleShowContact(res.data);
				}
			} catch (error) {
				// console.error(i18n.t("CONTACT.contactIsNotAvailable"));
				traceError(
					`handleDisplayContact-With ${user} for ${assistantID} show=${handleShowContact} failed: ${error.message}`
				);
			}
		} else {
			setContactDialogStatus({ open: true });
		}
	}

	/**
	 * Returns conversation direction icon ( inbound or outbound )
	 * @returns
	 */
	const getConvDirection = () => {
		if (selectedConversation.header.direction === directions.INBOUND) {
			return (
				<Tooltip title={i18n.t("COC.inbound")}>
					<Box style={{ position: "relative", display: "flex" }}>
						<ArrowDownwardOutlined
							style={{ position: "absolute", top: -8, left: 2, fontSize: "20px" }}
						/>

						<DraftsOutlined />
					</Box>
				</Tooltip>
			);
		} else if (selectedConversation.header.direction === directions.OUTBOUND) {
			return (
				<Tooltip title={i18n.t("COC.outbound")}>
					<Box style={{ position: "relative" }} marginLeft={2}>
						<ArrowUpwardOutlined
							style={{ position: "absolute", top: -8, left: 2, fontSize: "20px" }}
						/>

						<DraftsOutlined />
					</Box>
				</Tooltip>
			);
		}
	};
	return (
		<Box
			fontWeight="fontWeightRegular"
			display="flex"
			flexDirection="row"
			flexGrow={1}
			alignItems="center"
		>
			{channelImage && (
				<Box component="span" mr={0.5}>
					<img
						src={GetChannelImage(channels, channelImage, channel)}
						alt="marketplace-logo"
						width="25"
						height="25"
						aria-controls="fade-menu"
						aria-haspopup="true"
						className="logo-channel"
					/>
				</Box>
			)}
			{selectedConversation?.header?.direction && displayDialog && getConvDirection()}
			{!isEngageEdit ? (
				// Classic case, we display the name of the contact
				contactNameOrEmail && (
					<Box
						component="span"
						mx={0.5}
						style={{ cursor: "pointer", fontFamily: "Roboto", fontSize: "16px" }}
						onClick={() => handleDisplayContact(user, assistantID, handleShowContact)}
					>
						{contactNameOrEmail.length > 30
							? contactNameOrEmail.substring(0, 30) + " ..."
							: contactNameOrEmail}
					</Box>
				)
			) : (
				// Specific engage case, we can edit the contact
				<EngageEditContact />
			)}
			<UserConnectionTabletLC channel={channel} connected={connected} />
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={snackbarStatus.open}
				onClose={handleCloseSnackbar}
				autoHideDuration={1500}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity={snackbarStatus.severity ? snackbarStatus.severity : "info"}
				>
					{snackbarStatus.message}
				</Alert>
			</Snackbar>
			{contact?.FRU !== "" && contact?._id !== "" && displayDialog && contactDialogStatus.open && (
				<ContactEditDialog
					schema={schema}
					uiSchema={uiSchema}
					handleCloseEditContactDialog={handleCloseEditContactDialog}
					open={contactDialogStatus.open}
					mode="edit"
					contact={contact}
					getContacts={() => null}
					setLoading={setLoading}
					contactID={contact?._id}
					edit={assistantconfig?.cockpit?.updateContactFromConversation === true ? true : false}
				/>
			)}
		</Box>
	);
};

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//const MemoizedChannelUserInfoMembershipDisplayer = memo(ChannelUserInfoMembershipDisplayer);
export default ChannelUserInfoMembershipDisplayer;

/**
 * Tablet green if user connected to LC, red if not
 * Not dispayed in cockpitArchived section
 * @param {*} channel
 * @returns
 */
export const UserConnectionTabletLC = ({ channel, connected }) => {
	const { isCockpitArchived } = useSelector(state => state.cockpit);

	return (
		channel === "LC" &&
		!isCockpitArchived && (
			<Box
				m={1}
				style={{
					backgroundColor: connected ? "green" : "red",
					width: 10,
					height: 10,
					borderRadius: "50%"
				}}
			/>
		)
	);
};
