/* eslint-disable react/prop-types */
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	makeStyles
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import cockpitActions from "../../../redux/actions/cockpitActions";
import Typography from "@material-ui/core/Typography";
import i18n from "i18n";
import { TagsList } from "components/Tags/TagsList";
import { getConversationGroups } from "helpers/utilities";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.background.paper,
		overflow: "auto",
		maxHeight: 300
	},
	listSection: {
		backgroundColor: "inherit"
	},
	ul: {
		backgroundColor: "inherit",
		padding: 0
	}
}));
/**
 * Not used in v1.8
 * @param {} param0
 * @returns
 */
const EscalationDialog = ({ handleCloseEscalationDialog, open, showEscalationSuccessPopup }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { groups } = useSelector(state => state.userGroup);
	const { selectedConversation } = useSelector(state => state.cockpit);
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);
	let conversationGroups = getConversationGroups();

	const handleEscalation = (selectedConversation, escalationGroupID) => {
		dispatch(
			cockpitActions.escalateConversation(
				selectedConversation,
				selectedConversation.header.resolverGroup,
				escalationGroupID,
				showEscalationSuccessPopup
			)
		);
		handleCloseEscalationDialog();
	};

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={open}
			onClose={handleCloseEscalationDialog}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{i18n.t("COC.Escalation")}</DialogTitle>
			<DialogContent dividers>
				<DialogContentText>
					{i18n.t("COC.convGroup")}
					<TagsList
						list={conversationGroups}
						labelField={"name"}
						titleField={"description"}
						valueField={"_id"}
						align="left"
					/>
				</DialogContentText>
				<Box display="flex" alignItems="center" justifyContent="center" width={1}></Box>
				{selectedConversation && groups && groups.length > 0 && (
					<List className={classes.root} subheader={<li />}>
						{groups
							.filter(group => {
								if (
									selectedConversation.header.resolverGroup.includes(group._id) ||
									group.assistantID !== selectedAssistantID
								) {
									return false;
								} else return true;
							})
							.map(group => (
								<React.Fragment key={`group-${group.name}`}>
									<ListItem>
										<ListItemText
											primary={
												<React.Fragment>
													{group.name + " - "}
													<Typography component="span" variant="body2" color="textSecondary">
														{group.description}
													</Typography>
												</React.Fragment>
											}
										/>
										<ListItemSecondaryAction>
											<Tooltip placement="top" title={`Escalader au groupe: ` + group.name}>
												<IconButton
													edge="end"
													onClick={() => handleEscalation(selectedConversation, group._id)}
												>
													<ArrowUpwardIcon />
												</IconButton>
											</Tooltip>
										</ListItemSecondaryAction>
									</ListItem>
									<Divider />
								</React.Fragment>
							))}
					</List>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseEscalationDialog} color="primary">
					{`${i18n.t("COC.Cancel")}`}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EscalationDialog;
