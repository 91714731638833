/* eslint-disable no-unused-vars */
import C from "../../constants/userGroup.js";
import store from "../store.js";

import axios from "axios";

// require("dotenv").config();

const endPoint = process.env.REACT_APP_AMBACK;

let request;

const actions = {
	updateGroups: function (assistant) {
		if (store.getState().userStatus.auth) {
			let user = store.getState().userStatus.auth.user;
			if (user) {
				let data = {
					email: user.email,
					assistant: assistant
				};
				return dispatch => {
					axios(endPoint + "/api/groups", {
						headers: {
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email
						},
						method: "POST",
						data
					})
						.then(groups => {
							dispatch({
								type: C.UPDATE_GROUPS,
								payload: groups.data
							});
						})
						.catch(error => {});
				};
			}
		}
	},
	updateUsersGroups: function (assistant) {
		return dispatch => {
			request = performance.now();

			const currentRequest = request;

			if (store.getState().userStatus.auth) {
				let user = store.getState().userStatus.auth.user;
				if (user) {
					let data = {
						email: user.email,
						assistant: assistant
					};

					dispatch({ type: C.RESET_USERS_GROUPS });

					axios(endPoint + "/api/usersgroups", {
						headers: {
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email
						},
						method: "POST",
						data: data
					})
						.then(groups => {
							if (currentRequest === request) {
								dispatch({
									type: C.UPDATE_USERSGROUPS,
									payload: groups.data
								});
							}
						})
						.catch(error => {});
				}
			}
		};
	}
};

export default actions;
