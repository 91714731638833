const errorsReducer = function (currentState = {}, action) {
	switch (action.type) {
		case "SHOW_DISCONNECT_MSG": {
			return { ...currentState, ...action.payload };
		}

		default:
			return currentState;
	}
};

export default errorsReducer;
