/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PhoneIcon from "@material-ui/icons/Phone";

import ForwardIcon from "@material-ui/icons/Forward";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/styles";
import ContactEditDialog from "./ContactEditDialog";
import { getSchemaInternationalised } from "./schemaContact";
import { api } from "redux/actions/api";
import { useDispatch } from "react-redux";
import i18n from "i18n";
import lod_ from "lodash";
import cockpitActions from "../../redux/actions/cockpitActions";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableContainer,
	IconButton,
	Checkbox,
	Collapse,
	TablePagination,
	CircularProgress,
	Tooltip
} from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import { KeyboardArrowDown, KeyboardArrowUp, List } from "@material-ui/icons";
import AddToListDialog from "./AddToListDialog";
import CreateListDialog from "./CreateListDialog";
import MuiPhoneNumber from "material-ui-phone-number";
import SnackbarStore from "pages/Cockpit/SnackbarStore";

const useStyles = makeStyles({
	root: {
		flexDirection: "column-reverse"
	},
	ajouter: {
		position: "absolute",
		zIndex: 9999,
		width: "50%",
		display: "flex",
		margin: "auto",
		alignItems: "center",
		paddingTop: "2em"
	},
	selectedRowCount: { display: "none" },
	stickyLoader: {
		position: "sticky",
		top: "0",
		width: "100%",
		zIndex: "1000"
	},
	bgLoader: {
		position: "absolute",
		width: "100%",
		height: "68vh",
		backgroundColor: "rgba(0,0,0,0.5)"
	},
	progress: {
		position: "absolute",
		left: "50%",
		top: "50%",
		transform: "translate(-50%, -50%)"
	},
	customCard: {
		// height: "88vh"
	},
	customTable: {
		height: "80vh",
		border: "2px solid #00000021",
		borderRadius: "3px",
		position: "relative"
	},
	customPaddingCell: {
		padding: "6px 0px 6px 10px"
	},
	rowReverse: {
		flex: 1,
		display: "flex",
		flexDirection: "row-reverse",
		justifyContent: "flex-start"
	}
});

function Contact(props) {
	const [page, setPage] = React.useState(0);
	const [pageList, setPageList] = React.useState(0);
	const contactListref = useRef(null);

	const [countRows, setCountRows] = React.useState(0);
	const [rows, setRows] = useState([]);
	const [loadingRows, setLoadingRows] = useState(true);

	const [lastRemovedContactList, setLastRemovedContactList] = useState(null);

	const [rowsBeforeMapping, setRowsBeforeMapping] = useState();
	const [rowsList, setRowsList] = useState([]);
	const [rowsListCount, setRowsListCount] = React.useState(0);

	const [search, setSearch] = useState(false);
	const [searchList, setSearchList] = useState(false);

	const [contactID, setContactID] = useState();
	const [contactDialogStatus, setContactDialogStatus] = React.useState({
		open: false,
		contact: null
	});
	const [directAdded, setDirectAdded] = useState(null);
	const [addToListStatus, setAddToListStatus] = React.useState(false);
	const [createList, setCreateList] = React.useState(false);
	const [checkedList, setCheckedList] = useState([]);
	const [schema, setSchema] = useState();
	const [isEmpty, setIsEmpty] = useState(false);
	const [uiSchema, setUiSchema] = useState();
	const user = props.userStatus && props.userStatus.auth && props.userStatus.auth.user;
	const limit = 10; //TODO put these in config ? Abdel

	const classes = useStyles();
	const dispatch = useDispatch();

	/**
	 * Replace "_id" by an id because datagrid need "id"
	 */
	useEffect(() => {
		if (rowsBeforeMapping) {
			const rows = rowsBeforeMapping.map(row => {
				return { ...row, id: row._id };
			});
			setRows(rows);
		}
	}, [rowsBeforeMapping]);

	useEffect(() => {
		// Timeout search
		const timeoutId = setTimeout(async () => {
			await getContacts();
		}, 500);
		return () => clearTimeout(timeoutId);
	}, [search, page, props.selectedAssistantID]);

	useEffect(() => {
		// Run one time at component start
		let loadSchema = getSchemaInternationalised(i18n.t);
		setSchema(loadSchema.schema);
		setUiSchema(loadSchema.uiSchema);
	}, []);

	const handlePageChange = (event, newPage) => {
		setLoadingRows(true);
		setPage(newPage);
		resetCheckedData();
	};

	const handlePageListContactChange = (event, newPage) => {
		setPageList(newPage);
		//resetCheckedData();
	};

	/**
	 * Close contact edit's dialog
	 */
	const handleCloseEditContactDialog = () => {
		setContactDialogStatus({ open: false, contact: null });
	};

	/**
	 * GET contacts
	 */
	const getContacts = async () => {
		if (!props.selectedAssistantID) return;

		const curPage = page + 1;
		let url = `/api/v1/${props.selectedAssistantID}/contacts?page=${curPage}&limit=${limit}&exclude=list`;

		if (search) {
			let searchInput = search;
			if (searchInput.startsWith("+")) {
				// Probably a phone. Stored in DB without spaces
				searchInput = searchInput.replaceAll(" ", "");
			}

			url += `&search=${encodeURIComponent(searchInput)}`;
		}

		dispatch(
			api({
				type: "getContacts",
				url,
				onSuccess: res => {
					setLoadingRows(false);
					setCountRows(res.totalCount);
					setRowsBeforeMapping(res.elements);

					return { type: "SUCCESS" };
				},
				onFailure: error => {
					return { type: "SUCCESS" }; // ????
				}
			})
		);
	};

	/**
	 * GET lists
	 */
	const getLists = async () => {
		if (!props.selectedAssistantID) return;

		const curPage = pageList + 1;
		let url = `/api/v1/${props.selectedAssistantID}/contacts?page=${curPage}&limit=${limit}&include=list`;

		if (searchList) {
			url += `&search=${searchList}`;
		}

		dispatch(
			api({
				type: "getLists",
				url: url,
				onSuccess: res => {
					setRowsListCount(res.totalCount);
					setRowsList(res.elements);
					return { type: "SUCCESS" };
				},
				onFailure: error => {
					return { type: "SUCCESS" }; // ????
				}
			})
		);
	};

	/**
	 * EDIT contact
	 */
	const handleEdit = async row => {
		setContactDialogStatus({ open: true, mode: "edit", contact: row });
		setIsEmpty(false);
	};
	const handleCreate = async () => {
		setContactDialogStatus({ open: true, mode: "create", contact: null });
		setIsEmpty(true);
	};
	const handleSearchChange = async e => {
		setLoadingRows(true);
		const value = e.target.value;
		setSearch(value);
		resetCheckedData();
	};

	const handleSearchContactsListChange = async e => {
		//setLoadingRows(true);
		const value = e.target.value;
		setSearchList(value);
		//resetCheckedData();
	};

	/**
	 * Open add to list dialog
	 * @param {*} row
	 */
	const handleAddList = async row => {
		if (row.email) {
			setDirectAdded(row);
		}
		setAddToListStatus(true);
	};

	/**
	 * Close add to list dialog
	 */
	const handleCloseAddListDialog = async () => {
		setDirectAdded(null);
		setAddToListStatus(false);
	};

	/**
	 * Close create list dialog
	 */
	const handleCloseCreateList = async () => {
		setDirectAdded(null);
		setCreateList(false);
	};

	/**
	 * Open create list dialog
	 */
	const handleCreateList = async () => {
		setCreateList(true);
	};

	/**
	 * Refresh list
	 */
	const refreshLists = async () => {
		await getLists();
		resetCheckedData();
	};

	const handleCheckBox = async row => {
		if (row.checked != undefined) {
			row.checked = !row.checked;
		} else {
			row.checked = true;
		}

		const newRows = rows.map(r => {
			if (r.id === row.id) {
				return row;
			}
			return r;
		});

		setRowsBeforeMapping(newRows);

		if (row.checked) {
			setCheckedList([...checkedList, row]);
		} else {
			const newCheckedList = checkedList.filter(r => r.id !== row.id);
			setCheckedList(newCheckedList);
		}
	};

	function resetCheckedData() {
		handleDeselectAll();
		setCheckedList([]);
		setDirectAdded(null);
	}

	function handleDeselectAll() {
		rows.forEach(row => {
			row.checked = false;
		});
	}

	useEffect(() => {
		(async () => {
			await getContacts();
			await getLists();
		})();
	}, [page, props.selectedAssistantID]);

	useEffect(() => {
		(async () => {
			await getLists();
		})();
	}, [pageList, props.selectedAssistantID]);

	useEffect(() => {
		// Timeout search
		const timeoutId = setTimeout(async () => {
			await getContacts();
			setPage(0);
		}, 500);
		return () => clearTimeout(timeoutId);
	}, [search]);

	useEffect(() => {
		// Timeout search
		const timeoutId = setTimeout(async () => {
			await getLists();
			setPageList(0);
		}, 500);
		return () => clearTimeout(timeoutId);
	}, [searchList]);

	const columns = [
		{ field: "email", headerName: props.t("CONTACT.email"), flex: 2 },
		{ field: "company", headerName: props.t("CONTACT.company") },
		{ field: "phone", headerName: props.t("CONTACT.phone") }
	];

	for (let col of columns) {
		col.sortable = false;
		if (!col.flex) {
			col.flex = 1;
		}
	}

	return (
		<Grid container style={{ flex: 1 }}>
			<div style={{ flex: "8", marginRight: 15 }}>
				<GridContainer>
					<GridItem xs={12}>
						<Card
							product
							className={classes.cardHover + " " + classes.customCard}
							style={{ height: "98vh" }}
						>
							<CardHeader color="primary" icon style={{ padding: 5, paddingTop: 15 }}>
								<Grid container justifyContent="space-between" alignItems="center">
									<Box>
										<TextField
											label={props.t("ANSWERS.search")}
											variant="outlined"
											size="small"
											onChange={handleSearchChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<SearchIcon />
													</InputAdornment>
												)
											}}
										/>
									</Box>

									<TablePagination
										rowsPerPageOptions={[limit]}
										component="div"
										count={countRows}
										rowsPerPage={limit}
										page={page}
										onPageChange={handlePageChange}
										labelDisplayedRows={page => {
											// return page.page + 1 + "/" + (Math.floor(page.count / limit) + 1);
											return (
												page.page * limit +
												1 +
												"-" +
												Math.min(page.page * limit + limit, countRows) +
												" / " +
												countRows
											);
										}}
									/>
								</Grid>
							</CardHeader>
							<CardBody>
								<TableContainer className={classes.customTable}>
									<Table
										ref={contactListref}
										size="small"
										stickyHeader
										aria-label="simple table"
										style={{ height: rows.length === 10 ?? "100%" }}
									>
										<TableHead>
											<TableRow>
												<TableCell key={0}></TableCell>
												{columns.map((column, index) => (
													<TableCell key={index + 1} align="left">
														{column.headerName}
													</TableCell>
												))}
												<TableCell align="center">{props.t("CONTACT.actions")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody style={{ position: "relative" }}>
											{loadingRows && (
												<TableRow key="loaderTableRow" className={classes.stickyLoader}>
													<TableCell className={classes.bgLoader}>
														<div className={classes.progress}>
															<CircularProgress style={{ color: "white" }}></CircularProgress>
														</div>
													</TableCell>
												</TableRow>
											)}

											{rows &&
												rows.map((row, index) => (
													<TableRow key={index}>
														<TableCell>
															<Checkbox
																checked={row.checked != undefined ? row.checked : false}
																onChange={() => {
																	handleCheckBox(row);
																}}
															></Checkbox>
														</TableCell>
														<TableCell className={classes.customPaddingCell}>
															<Grid container>
																<Grid style={{ fontWeight: "bold", marginRight: 5 }}>
																	{row.first}
																</Grid>
																<Grid style={{ fontWeight: "bold" }}>{row.last}</Grid>
															</Grid>

															<span>{row.email}</span>
														</TableCell>
														<TableCell className={classes.customPaddingCell}>
															<span>{row.company}</span>
														</TableCell>
														<TableCell className={classes.customPaddingCell}>
															<MuiPhoneNumber
																name="phone-number"
																defaultCountry={"fr"}
																value={row.phone}
																disabled={true}
																disableDropdown={true}
																className="contact-phone-display"
																style={{ border: "none" }}
															/>
														</TableCell>
														<TableCell align="center" className={classes.customPaddingCell}>
															<Tooltip title={props.t("CONTACT.addTo")} placement="top">
																<IconButton
																	onClick={() => {
																		handleAddList(row);
																	}}
																>
																	<ForwardIcon />
																</IconButton>
															</Tooltip>

															<Tooltip title={props.t("CONTACT.edit")} placement="top">
																<IconButton onClick={() => handleEdit(row)}>
																	<EditIcon />
																</IconButton>
															</Tooltip>
														</TableCell>
													</TableRow>
												))}
										</TableBody>
									</Table>
								</TableContainer>
							</CardBody>
							<CardFooter className={classes.rowReverse} style={{ padding: 5 }}>
								<Box>
									<Button onClick={handleCreate} startIcon={<AddIcon />} color="primary">
										{props.t("CONTACT.add")}
									</Button>
								</Box>

								{checkedList.length > 0 && (
									<Button
										style={{ marginRight: 15 }}
										onClick={handleAddList}
										startIcon={<List />}
										color="primary"
									>
										{props.t("CONTACT.addSelectionToList")}
									</Button>
								)}
							</CardFooter>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
			<div style={{ flex: "4" }}>
				<GridContainer>
					<GridItem xs={12}>
						<Card
							product
							className={classes.cardHover + " " + classes.customCard}
							style={{ height: "98vh" }}
						>
							<CardHeader color="primary" icon style={{ padding: 5, paddingTop: 15 }}>
								<Grid container justifyContent="space-between" alignItems="center">
									<Box>
										<TextField
											label={props.t("ANSWERS.search")}
											variant="outlined"
											size="small"
											onChange={handleSearchContactsListChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<SearchIcon />
													</InputAdornment>
												)
											}}
										/>
									</Box>

									<TablePagination
										className={classes.customPagination + " paginationRemovePadding"}
										rowsPerPageOptions={[limit]}
										component="div"
										count={rowsListCount}
										rowsPerPage={limit}
										page={pageList}
										onPageChange={handlePageListContactChange}
										labelDisplayedRows={page => {
											// return page.page + 1 + "/" + (Math.floor(page.count / limit) + 1);
											if (rowsListCount == 0) return "";
											return (
												page.page * limit +
												1 +
												"-" +
												Math.min(page.page * limit + limit, rowsListCount) +
												" / " +
												rowsListCount
											);
										}}
									/>
								</Grid>
							</CardHeader>
							<CardBody style={{ paddingBottom: 10 }}>
								<TableContainer className={classes.customTable}>
									<Table size="small" stickyHeader aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell key={0}>{props.t("CONTACT.listName")}</TableCell>
												<TableCell key={1} align="right">
													{props.t("CONTACT.actions")}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{rowsList &&
												rowsList.map((list, index) => {
													return <Row key={index} row={list} t={props.t}></Row>;
												})}
										</TableBody>
									</Table>
								</TableContainer>
							</CardBody>
							<CardFooter className={classes.rowReverse} style={{ paddingBottom: 5 }}>
								<Box>
									<Button
										style={{ marginRight: 5 }}
										onClick={handleCreateList}
										startIcon={<AddIcon />}
										color="primary"
									>
										{props.t("CONTACT.add")}
									</Button>
								</Box>
							</CardFooter>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
			{contactDialogStatus.open && (
				<ContactEditDialog
					schema={schema}
					uiSchema={uiSchema}
					handleCloseEditContactDialog={handleCloseEditContactDialog}
					open={contactDialogStatus.open}
					mode={contactDialogStatus.mode}
					contact={contactDialogStatus.contact}
					getContacts={getContacts}
					isEmpty={isEmpty}
					contactID={contactDialogStatus.contact?._id}
					t={i18n.t}
					edit={true}
				/>
			)}

			{addToListStatus && (
				<AddToListDialog
					handleCloseAddListDialog={handleCloseAddListDialog}
					selectedAssistantID={props.selectedAssistantID}
					handleCreateList={handleCreateList}
					selectedContacts={checkedList}
					refreshLists={refreshLists}
					directAdded={directAdded}
					resetCheckedData={resetCheckedData}
					t={props.t}
				/>
			)}

			{createList && (
				<CreateListDialog
					handleCloseAddListDialog={handleCloseCreateList}
					selectedAssistantID={props.selectedAssistantID}
					selectedContacts={checkedList}
					refreshLists={refreshLists}
					resetCheckedData={resetCheckedData}
					t={props.t}
				/>
			)}
			<SnackbarStore />
		</Grid>
	);

	function handleDeleteContact(row, email) {
		setLastRemovedContactList(row);

		let rowArray = row.email.split(",");
		rowArray.splice(rowArray.indexOf(email), 1);

		var contactsString = rowArray
			.map(function (contact) {
				return contact;
			})
			.join(",");

		dispatch(
			api({
				type: "handleDeleteContact",
				url: "/api/v1/lists",
				method: "PUT",
				data: {
					assistantID: props.selectedAssistantID,
					name: row.name,
					emails: contactsString,
					replace: true
				},
				onSuccess: () => {
					refreshLists();
					return { type: "SUCCESS" };
				},
				onFailure: () => {
					return { type: "ERROR" };
				}
			})
		);
	}

	function handleDeleteList(row) {
		dispatch(
			api({
				type: "handleDeleteList",
				url: "/api/v1/" + props.selectedAssistantID + "/lists/" + row._id,
				method: "DELETE",
				onSuccess: () => {
					refreshLists();
					return { type: "SUCCESS" };
				},
				onFailure: () => {
					return { type: "ERROR" };
				}
			})
		);
	}

	function Row(props) {
		const { row } = props;
		const [open, setOpen] = React.useState(false);

		if (
			lastRemovedContactList != null &&
			lastRemovedContactList.name == row.name &&
			open == false
		) {
			setOpen(true);
		}

		return (
			<React.Fragment>
				<TableRow
					style={{ backgroundColor: open ? "#0000003d" : "" }}
					onClick={() => {
						setLastRemovedContactList(null);
						setOpen(!open);
					}}
				>
					<TableCell>
						<IconButton aria-label="expand row" size="medium">
							{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
						</IconButton>
						<span>{row.name}</span>
					</TableCell>
					<TableCell align="right">
						<IconButton
							style={{ padding: 2 }}
							onClick={e => {
								e.stopPropagation();
								handleDeleteList(row);
							}}
						>
							<DeleteIcon />
						</IconButton>
					</TableCell>
				</TableRow>
				<TableRow style={{ backgroundColor: open ? "#37373724" : "" }}>
					<TableCell colSpan={2} style={{ paddingBottom: 0, paddingTop: 0, marginLeft: 50 }}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Box sx={{ margin: 1 }}>
								<Table size="small" aria-label="purchases">
									<TableHead>
										<TableRow>
											<TableCell>{props.t("CONTACT.email")}</TableCell>
											<TableCell align="right">{props.t("CONTACT.actions")}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{row.email.length > 0 &&
											row.email.split(",").map(
												(email, index) =>
													email.trim() !== "" && (
														<TableRow key={index} className="rowContactListHover">
															<TableCell className={classes.customPaddingCell}>{email}</TableCell>
															<TableCell className={classes.customPaddingCell} align="right">
																<Tooltip title={props.t("CONTACT.delete")} placement="top">
																	<IconButton
																		style={{ padding: 2 }}
																		onClick={() => {
																			handleDeleteContact(row, email);
																		}}
																	>
																		<DeleteIcon />
																	</IconButton>
																</Tooltip>
															</TableCell>
														</TableRow>
													)
											)}

										{row.email.length == 0 && (
											<TableRow>
												<TableCell>{props.t("CONTACT.emptyList")}</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

export default compose(withTranslation(), connect(mapStateToProps))(Contact);
