/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable eqeqeq */
/* eslint consistent-return: 2*/
/*  eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars  */
import { Box, Tab, Tabs } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import TabActionContact from "./TabActionContact";
import cockpitActions from "redux/actions/cockpitActions";

import lod_ from "lodash";

function ContactActions(props) {
	const [rightTab, setRightTab] = useState(0);
	const [language, setLanguage] = useState(props.userStatus.auth.user.language);
	const [isDownloading, setIsDownloading] = useState(false);
	const [crmActions, setCrmActions] = useState([]);

	/**
	 * Filter actions from assistantConfig  to  keep only : type : "crm",
	 *
	 */
	useEffect(() => {
		if (props.assistantconfig && props.assistantconfig.actions) {
			let crmActions = props.assistantconfig.actions.filter(e => e.type === "crm");
			if (!lod_.isEmpty(crmActions)) {
				//sort crmActions to use displayOrder
				const actionsSorted = lod_.sortBy(crmActions, ["displayOrder"]);
				setCrmActions(actionsSorted);
			}
		}
	}, [props.assistantconfig.actions, props.selectedAssistantID]);

	return (
		<Grid
			container
			justifyContent="center"
			item
			xs={6}
			style={
				isDownloading
					? { pointerEvents: "none", justifyContent: "center" }
					: { borderLeft: "1px solid", justifyContent: "center" }
			}
		>
			<Box
				style={{
					display: "flex",
					flexDirection: "column"
				}}
			>
				<Box display="flex" alignItems="center" justifyContent="center" mt={1}>
					<Tabs
						value={rightTab}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
					>
						<Tab style={{ minWidth: 20, fontSize: "12px" }} label={props.t("COC.listActions")} />
					</Tabs>
				</Box>
				<Box flex={1} overflow="auto">
					{props.numberConversation >= 1 && !lod_.isEmpty(crmActions) ? (
						crmActions.map(e => {
							/* WE MUST BE CAREFULL HERE SINCE SOME ACTION DISPLAY SHOULD BE LIMITED BY THE USER ROLE AND/OR PERMS */
							return (
								<TabActionContact
									key={`${e.name}-${e.index}`}
									name={e.name}
									contact={props.contact}
									description={e.description}
									logo={e.logo}
									action={e.action}
									language={language}
									isDownloading={isDownloading}
									setIsDownloading={setIsDownloading}
									numberConversation={props.numberConversation}
									handleCloseContactModal={props.handleCloseContactModal}
									getContacts={props.getContacts}
									conversationsCold={props.conversationsCold}
								/>
							);
						})
					) : (
						<p
							style={{
								display: "flex",
								justifyContent: "center",
								paddingTop: "10px"
							}}
						>
							{props.t("CONTACT.noAction")}
						</p>
					)}
				</Box>
			</Box>
		</Grid>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(ContactActions);
