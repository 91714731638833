export function cleanHTMLSpecificPatterns(htmlText) {
	try {
		let mustCleanComment = htmlText.indexOf("<!--") !== -1;
		let mustCleanSuneditor = htmlText.indexOf("se-component") !== -1;
		let mustCleanCid = htmlText.indexOf(`src="cid`) !== -1;
		let mustCleanOPTag = htmlText.includes("<o:p>&nbsp;</o:p>") || htmlText.includes("<o:p></o:p>");

		let mustClean = mustCleanComment || mustCleanSuneditor || mustCleanCid || mustCleanOPTag;

		if (mustClean) {
			/**
			 * First remove \n as it seems to perturb next regex cleaning multiline to working
			 */
			const replaceLineReturnContent = "NgUnoArDeNDIchrANDI";

			htmlText = htmlText.replaceAll("<o:p>&nbsp;</o:p>", "");
			htmlText = htmlText.replaceAll("<o:p></o:p>", "");

			/**
			 * Remove comment in HTML text -> they sometime produce display errors
			 * could also use const script = new RegExp('(<style type="text/css">(.|\n|\r)*</style>)', "gm");
			 */
			// eslint-disable-next-line no-control-regex
			const comment = new RegExp("<!--(.*?)-->", "gm");
			htmlText = htmlText.replace(comment, "");

			/**
			 * Remove Suneditor class that generates issues when re-injecting in Suneditor
			 */
			const suneditorTags = new RegExp("(x_)?se-component", "gim");
			htmlText = htmlText.replace(suneditorTags, "");

			/**
			 * Remove empty class that can lead to crash, see FBDB-942
			 */
			htmlText = htmlText.replaceAll(`class=""`, "");

			/**
			 * Remove img with cid source
			 */

			htmlText = htmlText.replace(/<img[^>]+>/gm, occur => {
				const cidOccur = occur.indexOf("cid:") !== -1;
				if (cidOccur) {
					// Remove img with src cid
					return "";
				} else {
					// If regular image don't touch
					return occur;
				}
			});

			/**
			 * Restore the \n to keep format
			 */
			htmlText = htmlText.replaceAll(replaceLineReturnContent, "\n");

			return htmlText;
		}
		// eslint-disable-next-line no-empty
	} catch {}
	return htmlText;
}
