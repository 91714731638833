/* eslint-disable react-hooks/exhaustive-deps */
import lod_ from "lodash";
import { useCallback, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import cockpitActions from "../../redux/actions/cockpitActions";

const Autosave = props => {
	const saveAnswerToDBB = useCallback(newAnswer => {
		props.AutoSaveDraft(newAnswer);
	}, []);

	const debouncedSave = useCallback(
		lod_.debounce(async newAnswer => {
			await saveAnswerToDBB(newAnswer);
		}, 1000),
		[]
	);

	useEffect(() => {
		if (props.answer) {
			debouncedSave(props.answer);
		}
	}, [props.answer, debouncedSave]);

	return null;
};

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Autosave);
