import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import cockpitActions from "redux/actions/cockpitActions";
import contactActions from "redux/actions/contactActions";

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/* Dialog which handle download conversations/pj for the selected contact */
function ConfirmationDialog(props) {
	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		setOpen(props.isDownloadDialogOpen);
	}, [props.isDownloadDialogOpen]);

	const callApiContactActions = () => {
		if (props.displaySpinner) return;
		props.setDisplpaySpinner(true);
		props.setIsDownloading(true);
		props.contactActions.doAnAction(
			props.contact,
			props.action,
			true,
			props.language,
			actionSucceed,
			actionFailed
		);
	};

	const actionSucceed = expectFile => {
		props.setDisplpaySpinner(false);
		props.setIsDownloading(false);
		if (expectFile) {
			props.setSnackbarStatus({ open: true, message: props.t("COC.DownloadSucceed") });
		} else {
			props.setSnackbarStatus({ open: true, message: props.t("COC.ActionSucceed") });
		}
	};

	const actionFailed = errorCode => {
		props.setDisplpaySpinner(false);
		props.setIsDownloading(false);
		if (errorCode) {
			props.setSnackbarStatus({
				open: true,
				message: props.t(`TabAction.${errorCode}`),
				severity: "error"
			});
		} else {
			props.setSnackbarStatus({
				open: true,
				message: props.t(`TabAction.downloadFailed`),
				severity: "error"
			});
		}
	};

	return (
		<div>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">{props.t("CONTACT.disclaimer")}</DialogTitle>
				<DialogContent>
					<DialogContentText>{props.t("CONTACT.warning")}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose} color="primary">
						{props.t("CONTACT.disagree")}
					</Button>
					<Button onClick={callApiContactActions} color="primary" autoFocus>
						{props.t("CONTACT.agree")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

/* // eslint-disable-next-line no-unused-vars
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
} */

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch),

		contactActions: bindActionCreators(contactActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(ConfirmationDialog);
