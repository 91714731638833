/* eslint-disable react/display-name */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React, { useEffect, useRef, useState } from "react";
import useStyles from "../../CockpitStyleJs/styleFilter";
import Tooltip from "@material-ui/core/Tooltip";
import C from "../../../../constants/cockpit.js";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n";

/**
 *
 * @returns
 */
export const LCFilter = () => {
	const [hoveredConnected, setHoveredConnected] = useState(false);
	const [openConnected, setOpenConnected] = useState(false);

	const dispatch = useDispatch();
	const { convConnectedFilter } = useSelector(state => state.cockpit);
	const classes = useStyles();
	let connectedSet = (convConnectedFilter !== null) === true;
	const prevOpenConnected = useRef(openConnected);
	const anchorRefConnected = useRef(null);

	/**
	 *
	 */
	useEffect(() => {
		if (hoveredConnected) {
			const timer = hoveredConnected && setTimeout(handleToggleConnected, 100);
			return () => {
				clearTimeout(timer);
			};
		} else {
			setOpenConnected(false);
		}
	}, [hoveredConnected]);

	/**
	 *
	 */
	useEffect(() => {
		if (prevOpenConnected.current === true && openConnected === false) {
			anchorRefConnected.current.focus();
		}
		prevOpenConnected.current = openConnected;
	}, [openConnected]);

	/**
	 *
	 * @param {*} value
	 */
	const setConvConnectedFilter = value => {
		dispatch({
			type: C.SET_CONV_CONNECTED_FILTER,
			payload: value
		});
	};

	/**
	 *
	 */
	const handleToggleConnected = () => {
		setOpenConnected(prevOpen => !prevOpen);
	};

	/**
	 *
	 * @param {*} event
	 * @returns
	 */
	const handleCloseConnected = event => {
		if (anchorRefConnected.current && anchorRefConnected.current.contains(event.target)) {
			return;
		}
		setOpenConnected(false);
	};

	/**
	 *
	 * @param {*} event
	 */
	function handleListKeyDownConnected(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenConnected(false);
		}
	}

	/**
	 *
	 * @param {*} event
	 * @param {*} newValue
	 */
	const handleConvConnectedFilter = (event, newValue) => {
		dispatch({
			type: C.SET_CONV_CONNECTED_FILTER,
			payload: newValue
		});
	};

	/**
	 * find the right icon to display connected filter
	 */
	const ConnectedIconActive = () => {
		let connected = convConnectedFilter;
		switch (connected) {
			case false:
				return (
					<Box
						m={0.7}
						style={{
							backgroundColor: "red",
							width: 10,
							height: 10,
							borderRadius: "50%"
						}}
						onClick={() => setConvConnectedFilter(null)}
					/>
				);
			case true:
				return (
					<Box
						m={0.7}
						style={{
							backgroundColor: "green",
							width: 10,
							height: 10,
							borderRadius: "50%"
						}}
						onClick={() => setConvConnectedFilter(null)}
					/>
				);
			default:
				return (
					<Box
						m={0.7}
						style={{
							backgroundColor: "grey",
							width: 10,
							height: 10,
							borderRadius: "50%"
						}}
						onClick={() => setConvConnectedFilter(null)}
					/>
				);
		}
	};

	return (
		<Tooltip title={i18n.t("COC.connectedFilter")} placement="top">
			<Box
				className={classes.filterButton}
				mt={1}
				onMouseEnter={() => setHoveredConnected(true)}
				onMouseLeave={() => setHoveredConnected(false)}
			>
				<ToggleButton
					onClick={() => setConvConnectedFilter(null)}
					aria-haspopup="menu"
					aria-controls={openConnected ? "menu-list-grow-connected" : undefined}
					ref={anchorRefConnected}
					value={openConnected}
					size="small"
					style={connectedSet ? { background: "#0000001f" } : {}}
				>
					<ConnectedIconActive />
				</ToggleButton>
				<Popper
					open={openConnected}
					anchorEl={anchorRefConnected.current}
					className={classes.dropDown}
					role={undefined}
					transition
					disablePortal
				>
					{() => (
						<ClickAwayListener onClickAway={handleCloseConnected}>
							<MenuList
								mt={0}
								mb={0}
								pt={0}
								autoFocusItem={openConnected}
								id="menu-list-grow-connected"
								onKeyDown={handleListKeyDownConnected}
							>
								<Paper>
									<ToggleButtonGroup
										orientation="vertical"
										value={convConnectedFilter}
										exclusive
										onChange={handleConvConnectedFilter}
										size="small"
									>
										<Tooltip title={i18n.t("convItemConnected.disconnected")} placement="right">
											<ToggleButton value={false}>
												<Box
													m={0.7}
													style={{
														backgroundColor: "red",
														width: 10,
														height: 10,
														borderRadius: "50%"
													}}
												/>
											</ToggleButton>
										</Tooltip>
										<Tooltip title={i18n.t("convItemConnected.connected")} placement="right">
											<ToggleButton value={true}>
												<Box
													m={0.7}
													style={{
														backgroundColor: "green",
														width: 10,
														height: 10,
														borderRadius: "50%"
													}}
												/>
											</ToggleButton>
										</Tooltip>
									</ToggleButtonGroup>
								</Paper>
							</MenuList>
						</ClickAwayListener>
					)}
				</Popper>
			</Box>
		</Tooltip>
	);
};
