/**
 * PIKA: context
 */
import React, { createContext, useContext } from "react";
import Cookies from "universal-cookie";
import userStatusActions from "../redux/actions/userStatusActions";
import assistantsActions from "../redux/actions/assistantsActions.js";
import assistantconfigActions from "../redux/actions/assistantconfigActions.js";
import userGroupActions from "../redux/actions/userGroupActions";
import selectedAssistantActions from "../redux/actions/selectedAssistantActions";
import store from "../redux/store.js";
import i18n from "../i18n";
import axios from "axios";
import { updateAnswerItems } from "redux/reducers/answersReducers";
import { traceError } from "../helpers/utilities";
const fAibrikContext = createContext();
// require("dotenv").config();
const endPoint = process.env.REACT_APP_AMBACK;
const cookies = new Cookies();

export const ProvideAuthFaibrik = props => {
	/**
	 * Check if the selected user has the given permission like 'write_answer'
	 * @param {string} perm
	 */

	const fAibrikStatus = {
		has: function (perm) {
			let perms = store.getState().userStatus.auth.user.perms;
			if (!perms) return false;
			return perms[perm];
		},

		fetching: function (user, cb) {
			if (!user) {
				traceError(`fetching-No user to authorize`);
				return;
			}

			axios(endPoint + "/api/authorize", {
				headers: {
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "POST",
				data: {
					name: user.nickname,
					email: user.email
				}
			})
				.then(responseData => {
					responseData = responseData.data;

					if (responseData.authorized) {
						if (responseData.firstConnexion) {
							cb(true, responseData.name, responseData.surname);
						} else {
							cb(false);
						}

						user.perms = responseData.perms;
						user.fetched = true;

						//update userStatus in redux store
						store.dispatch(
							userStatusActions.addUserData(user, function () {
								//update assistants
								const assistantIDs = responseData.assistantIDs;
								store.dispatch(assistantsActions.updateAssistants(assistantIDs));

								let currentAssistant = cookies.get("fAIbrik.selectedAssistantID");
								if (!currentAssistant || currentAssistant === "undefined") {
									currentAssistant = assistantIDs[0];
								}

								let hideReleaseNotesForSession = sessionStorage.getItem("releaseNoteDisplayed");
								if (hideReleaseNotesForSession === undefined) {
									hideReleaseNotesForSession = false;
								}
								store.dispatch(
									userStatusActions.hideSessionReleaseNote(hideReleaseNotesForSession)
								);

								store.dispatch(selectedAssistantActions.updateSelectedAssistant(currentAssistant));
								store.dispatch({
									type: "ws/set_human_request",
									payload: false
								});
								store.dispatch(assistantconfigActions.getConfigByAssistant(currentAssistant));
								store.dispatch(updateAnswerItems(currentAssistant));
								store.dispatch(userGroupActions.updateGroups(currentAssistant));
								store.dispatch(userGroupActions.updateUsersGroups(currentAssistant));

								//set current language
								if (
									store.getState().userStatus.auth.user &&
									store.getState().userStatus.auth.user.language
								) {
									let newLanguage = store.getState().userStatus.auth.user.language.toLowerCase();
									i18n.changeLanguage(newLanguage);
								}
							})
						);
					} else {
						//TODO : Display alert with error
						traceError(`fetching-Context authorization failed`);
					}
				})
				.catch(err => {
					traceError(`fetching-Context failed ${err.message}`);
				});
		}
	};

	const provideValues = {
		fAibrikStatus
	};

	return <fAibrikContext.Provider value={provideValues} {...props} />;
};
export const useFAibrikContext = () => useContext(fAibrikContext);
