/* eslint-disable no-case-declarations */
import C from "../../constants/selectedAssistant.js";

const userSelectedAssistantReducers = function (currentState = {}, action) {
	switch (action.type) {
		case C.UPDATE_SELECTEDASSISTANT:
			const assistantID = action.payload;
			return assistantID;

		default:
			return currentState;
	}
};

export default userSelectedAssistantReducers;
