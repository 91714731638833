import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	CircularProgress,
	IconButton,
	ListItem,
	Paper
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import MenuText from "components/Typography/MenuText";
import React, { useEffect, useState } from "react";
import { Anchorme } from "react-anchorme";
import { getACleanURI, getLabelOf } from "../../../helpers/utilities";
import useStyles from "../CockpitStyleJs/styleTabInfo.js";
import lod_ from "lodash";
import AttachmentsList from "helpers/AttachmentsList";
import { sanitize } from "dompurify";
import { Subject } from "../Message/MessageItem";
import { useSelector } from "react-redux";

/**
 *
 * @param {object} ans The answer item (1 object = 1 alternative)
 * @param {number} indexaccordion The index of the accordion
 * @param {function} handleReplaceReview The function to replace the review
 * @param {function} handleReplaceAnswer The function to replace the answer
 * @param {string} loadingBuiltin The loading builtin
 * @returns
 */
export default function BuiltInAnswer({
	ans,
	indexaccordion,
	handleReplaceReview,
	handleReplaceAnswer,
	loadingBuiltin
}) {
	const user = useSelector(state => state.userStatus?.auth?.user);
	const { language } = useSelector(state => state.answers);
	const { selectedConversation } = useSelector(state => state.cockpit);

	const [expandedAcordion, setExpandedAccordion] = useState(false);

	const ongoingConv = selectedConversation?.header.state === "ongoing";
	const resolvedConv = selectedConversation?.header.state === "resolved";
	const assignedConv = selectedConversation?.agent?.uid === user.email;
	const displayActions = (ongoingConv || resolvedConv) && assignedConv;
	const classes = useStyles();

	const [content, setContent] = useState({});

	let loadingElement;
	if (`panel_${indexaccordion}` === loadingBuiltin) {
		loadingElement = (
			<div className="builtin-answer-load-ressource">
				<CircularProgress></CircularProgress>
			</div>
		);
	}

	const getAnswerType = () => {
		switch (ans.version) {
			case "2.0":
				return ans.format;
			case "1.0":
			default:
				return ans.type;
		}
	};

	useEffect(() => {
		// Open accordion
		setExpandedAccordion(ans.open || false);

		// Set content of the answer
		switch (ans.version) {
			case "2.0": {
				let ansContent = lod_.get(ans.content, language);
				setContent(ansContent);
				break;
			}
			case "1.0":
			default: {
				let ansContent = lod_.find(ans.content, { language })?.content;
				setContent(ansContent);
				break;
			}
		}
	}, [ans]);

	return (
		<Accordion
			className={classes.root}
			onClick={() => {
				setExpandedAccordion(!expandedAcordion);
			}}
			key={indexaccordion}
			expanded={expandedAcordion}
		>
			<div className={classes.addAnswer}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls={`panel_${indexaccordion}bh-content`}
					id={`panel_${indexaccordion}d-header`}
					classes={{ root: classes.customAccordionSummary }}
					key={indexaccordion}
				>
					{
						<h5 style={{ fontSize: "16px", fontFamily: "Roboto", fontWeight: "normal" }}>
							{ans.description
								? SliceWord(getLabelOf(ans.description), 47)
								: SliceWord(getLabelOf(ans.name), 47)}
						</h5>
					}
				</AccordionSummary>
				{displayActions && (
					<div>
						<IconButton
							onClick={e => {
								let format = ans.type;

								switch (ans.version) {
									case "2.0":
										format = ans.format;
										break;
									case "1.0":
									default:
										break;
								}

								if (format === "review") {
									handleReplaceReview(content);
								} else {
									switch (ans.version) {
										case "2.0":
											handleReplaceAnswer(
												indexaccordion,
												ans.code,
												ans.name,
												user,
												lod_.cloneDeep(content),
												ans.alternativeIndex
											);
											break;
										case "1.0":
										default:
											handleReplaceAnswer(
												indexaccordion,
												ans.uid,
												ans.name,
												user,
												lod_.cloneDeep(content),
												null
											);
											break;
									}
								}
								e.stopPropagation();
							}}
							size="small"
						>
							<NoteAddOutlinedIcon />
						</IconButton>
					</div>
				)}
				{loadingElement}
			</div>
			<AccordionDetails className={classes.AccordionDetails}>
				<ListItem style={{ padding: "8px" }}>
					<Box width={1}>
						{!lod_.isNil(content?.attachments) && content.attachments.length > 0 && (
							<div
								style={{
									position: "absolute",
									top: 10,
									right: 10
								}}
							>
								<AttachmentsList attachments={content.attachments} />
							</div>
						)}
						<Paper
							elevation={3}
							onClick={e => {
								e.stopPropagation();
							}}
						>
							<Box display="flex" p={1} alignItems="center">
								<Box
									flexGrow={1}
									alignItems="right"
									// maxWidth="240px"
									style={{ overflow: "hidden" }}
								>
									{content && <RenderAnswerByType content={content} type={getAnswerType()} />}
								</Box>
							</Box>
						</Paper>
					</Box>
				</ListItem>
			</AccordionDetails>
		</Accordion>
	);
}

const RenderAnswerByType = ({ content, type }) => {
	switch (type) {
		case "review":
			return <div style={{ whiteSpace: "pre-line" }}>{content.text}</div>;
		case "menutext":
			return <MenuText menuTitle={content.title} menuText={content.choice} />;
		case "menuyesno":
			return content.title;
		case "richtext":
			return (
				<Anchorme target="_blank" rel="noreferrer noopener">
					<div>{content.text}</div>
				</Anchorme>
			);
		case "askemail":
		case "htmltext":
			return (
				<Anchorme target="_blank" rel="noreferrer noopener">
					{content.subject?.length > 0 && <Subject urgent={false} topic={content.subject} />}
					<div
						dangerouslySetInnerHTML={{
							__html: sanitize(content.text)
						}}
					/>
				</Anchorme>
			);
		case "document":
		case "video":
			return (
				<Anchorme target="_blank" rel="noreferrer noopener" truncate={50}>
					{getACleanURI(content.url)}
				</Anchorme>
			);
		case "image":
			return <img src={getACleanURI(content.url)} />;
		case "location":
			return content.addressName;
		default:
			return "";
	}
};
/**
 *
 * @param {function} String Cut the word if it is too long and cannot be displayed
 */

function SliceWord(str, length) {
	if (str.length > length) {
		return str.slice(0, length);
	} else {
		return str;
	}
}
