import i18n from "i18next";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getHeaderConvItemSelector } from "redux/selectors/cockpitSelectors";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import cockpitActions from "../../../redux/actions/cockpitActions";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import AvTimerOutlinedIcon from "@material-ui/icons/AvTimerOutlined";
import { Box } from "@mui/system";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import { displayCorrectName } from "helpers/utilities";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import EscalationDialog from "./EscalationDialog";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import { getConvItemSelector } from "redux/selectors/cockpitSelectors";
import KeyboardTabIcon from "@material-ui/icons/KeyboardTab";
import AndroidIcon from "@material-ui/icons/Android";
import { Button, IconButton, MenuItem, Typography } from "@mui/material";
import {
	Divider,
	ListItemIcon,
	ListItemText,
	Menu,
	Tooltip,
	Popover,
	Badge
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
	AppsOutlined,
	BlockOutlined,
	DeleteOutline,
	UnarchiveOutlined,
	PersonAddDisabled
} from "@material-ui/icons";
import { ActionsComponent } from "../CockpitTabInfo/Actions";
import { getChannelUserInfoMembershipDisplayerSelector } from "redux/selectors/cockpitSelectors";
/**
 *
 * @returns
 */
export const RejectConversation = ({ setDisplayHistory, setConvHistoryFromBack, conversation }) => {
	const dispatch = useDispatch();
	const { selectedConversation } = useSelector(getHeaderConvItemSelector, shallowEqual);

	const conversationToReject = conversation || selectedConversation;
	/**
	 * Reject a conversation by changing is state from ongoing to waiting
	 */
	const rejectConversation = (event, conv) => {
		if (conv !== null && conv !== undefined) {
			dispatch(cockpitActions.rejectConversation(conv));
			//TODO: store them in the store
			if (setDisplayHistory) setDisplayHistory(false);
			if (setConvHistoryFromBack) setConvHistoryFromBack(null);
		}

		//To prevent the click event to reach the list item
		if (event) event.stopPropagation();
	};

	const frontUserCanSwitch = (
		<Tooltip title={i18n.t("COC.CancelCoverTicket")} placement="top">
			<IconButton size="small" onClick={event => rejectConversation(event, conversationToReject)}>
				<ArrowBackIcon />
			</IconButton>
		</Tooltip>
	);

	const roleOfAgent = useSelector(state => state?.userStatus?.auth?.user?.role);
	let canRejectConversation = useSelector(
		state => state?.assistantconfig?.cockpit?.agentCanRejectConversation
	);

	if (typeof canRejectConversation !== "boolean") {
		canRejectConversation = false;
	}

	if (canRejectConversation && roleOfAgent !== "agent") {
		return frontUserCanSwitch;
	} else if (!canRejectConversation) {
		return frontUserCanSwitch;
	} else {
		return <div />;
	}
};

/**
 * function to display dropdownMenu to send response  and choose state / substate
 */
const DropDownMenu = ({ anchorEl, setAnchorEl }) => {
	const dispatch = useDispatch();
	const { selectedConversation } = useSelector(getHeaderConvItemSelector, shallowEqual);
	const { assistantconfig } = useSelector(state => state);
	const [openModal, setOpen] = React.useState(false);
	const [modalState, setModalState] = React.useState("");
	const [modalSubstate, setModalSubstate] = React.useState("");

	const handleCloseDropDown = () => {
		setAnchorEl(null);
	};

	function negativeAnswer() {
		handleClose();
	}

	function positiveAnswer() {
		dispatch(
			cockpitActions.updateConversationState(modalState, modalSubstate, selectedConversation)
		);
		handleClose();
	}

	const handleOpen = (state, substate) => {
		setOpen(true);
		setModalState(state);
		setModalSubstate(substate);
	};

	const handleClose = () => {
		setOpen(false);
		setModalState("");
		setModalSubstate("");
	};

	const handleConv = (state, substate, success = null) => {
		setAnchorEl(null);
		if (
			selectedConversation.header.substate === "error" ||
			selectedConversation.header.substate === "sending"
		) {
			handleOpen(state, substate);
		} else {
			dispatch(
				cockpitActions.updateConversationState(state, substate, selectedConversation, success)
			);
		}
	};

	return (
		<div>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				variant="menu"
				open={Boolean(anchorEl)}
				onClose={handleCloseDropDown}
			>
				<MenuItem onClick={() => handleConv("ongoing", "waiting_client")}>
					<ListItemIcon>
						<AvTimerOutlinedIcon fontSize="small" mt={1} />
					</ListItemIcon>
					<ListItemText primary={i18n.t("COC.client")} />
				</MenuItem>
				<MenuItem onClick={() => handleConv("ongoing", "waiting_action")}>
					<ListItemIcon>
						<BallotOutlinedIcon fontSize="small" mt={1} />
					</ListItemIcon>
					<ListItemText primary={i18n.t("COC.action")} />
				</MenuItem>
				<Tooltip
					title={
						assistantconfig?.cockpit?.topicMandatory &&
						(!selectedConversation.meta.topic ||
							selectedConversation.meta.topic === "nointent" ||
							!assistantconfig.intents.some(obj => obj.name === selectedConversation.meta.topic))
							? i18n.t("COC.noTopic")
							: ""
					}
				>
					<span>
						<MenuItem
							onClick={() => handleConv("resolved", "resolved")}
							disabled={
								assistantconfig?.cockpit?.topicMandatory &&
								(!selectedConversation.meta.topic ||
									selectedConversation.meta.topic === "nointent" ||
									!assistantconfig.intents.some(
										obj => obj.name === selectedConversation.meta.topic
									))
							}
						>
							<ListItemIcon>
								<DoneOutlineOutlinedIcon fontSize="small" mt={1} />
							</ListItemIcon>
							<ListItemText primary={i18n.t("COC.resolved")} />
						</MenuItem>
					</span>
				</Tooltip>
				<MenuItem onClick={() => handleConv("resolved", "watchlist")}>
					<ListItemIcon>
						<RemoveRedEyeOutlinedIcon fontSize="small" mt={1} />
					</ListItemIcon>
					<ListItemText primary={i18n.t("COC.watchlist")} />
				</MenuItem>

				<Divider />

				<MenuItem
					onClick={() =>
						handleConv("hidden", "spam", () => {
							dispatch(infoMsg(i18n.t("COC.infoMoveToHidden")));
						})
					}
				>
					<ListItemIcon>
						<BlockOutlined fontSize="small" mt={1} />
					</ListItemIcon>
					<ListItemText primary={i18n.t("COC.spam")} />
				</MenuItem>

				<MenuItem
					onClick={() =>
						handleConv("hidden", "spam", () => {
							dispatch(infoMsg(i18n.t("COC.infoMoveToHidden")));
							dispatch(
								cockpitActions.updateContactToCRM(
									{ FRU: selectedConversation.contact.FRU, blacklist: true },
									assistantconfig.assistantID
								)
							);
						})
					}
				>
					<ListItemIcon>
						<PersonAddDisabled fontSize="small" mt={1} />
					</ListItemIcon>
					<ListItemText primary={i18n.t("COC.blacklist")} />
				</MenuItem>
			</Menu>
			<Dialog
				open={openModal}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				style={{ width: "30%" }}
			>
				<DialogContent>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						{i18n.t("modal.warnConvStatus") +
							displayCorrectName(selectedConversation.header.substate)}
					</Typography>
					<Typography id="modal-modal-description">
						{i18n.t("modal.askDefineNewStatus") + displayCorrectName(modalSubstate)}
					</Typography>
				</DialogContent>
				<DialogActions style={{ justifyContent: "space-around" }}>
					<Button variant="outlined" color="error" onClick={negativeAnswer}>
						{i18n.t("modal.negative")}
					</Button>
					<Button variant="outlined" color="primary" onClick={positiveAnswer}>
						{i18n.t("modal.positive")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

/**
 *
 * @returns
 */
export const UpdateConversationState = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClickDropDown = event => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<Box component="span" mx={0.5}>
			<Tooltip title={i18n.t("COC.defineStatus")} placement="top">
				<IconButton onClick={handleClickDropDown} size="small" aria-haspopup="menu">
					<ArchiveOutlinedIcon />
				</IconButton>
			</Tooltip>
			<DropDownMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
		</Box>
	);
};

/**
 *
 * @returns
 */
export const ActionConversation = () => {
	const [openAction, setOpenAction] = useState(false);
	const [anchorAction, setAnchorAction] = useState(null);

	let { selectedConversation } = useSelector(
		getChannelUserInfoMembershipDisplayerSelector,
		shallowEqual
	);

	let actionsCount = selectedConversation?.actions?.length || 0;

	const [visible, setVisible] = useState(true);

	const handleClose = () => {
		setOpenAction(false);
		setAnchorAction(null);
	};
	const openActionMenu = event => {
		setAnchorAction(event.currentTarget);
		setOpenAction(true);
		setVisible(true);
	};

	const handleCloseMenu = () => {
		setVisible(false);
	};
	return (
		<>
			<Tooltip title={i18n.t("COC.listActions")}>
				<IconButton onClick={openActionMenu}>
					<Badge badgeContent={actionsCount} color="error">
						<AppsOutlined />
					</Badge>
				</IconButton>
			</Tooltip>
			<Popover
				id="translate"
				open={openAction}
				anchorEl={anchorAction}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				style={{
					display: visible ? "unset" : "none"
				}}
			>
				<ActionsComponent
					rcvAttAll={[]}
					sntAttAll={[]}
					popoverCloseMenu={handleCloseMenu}
					popoverActionsHandleClose={handleCloseMenu}
				/>
			</Popover>
		</>
	);
};

/**
 *
 * @returns
 */
export const AssignConversation = ({ conversation, isBot }) => {
	const dispatch = useDispatch();
	const { selectedConversation, switchTabOnAssignation } = useSelector(
		getConvItemSelector,
		shallowEqual
	);
	const conversationToAssign = conversation || selectedConversation;

	/**
	 * Check conflict between conversation and selectedConversation
	 * when the conv is assigned
	 */
	/**
	 * Assign a conversation by changing is state from waiting to ongoing
	 */

	const assignConversation = (event, conv) => {
		dispatch(cockpitActions.assignConversation(conv));

		//To prevent the click event to reach the list item
		if (event) event.stopPropagation();
	};

	return (
		<Box>
			{isBot ? (
				<IconButton
					style={{ padding: 0 }}
					onClick={event => assignConversation(event, conversationToAssign)}
				>
					<Tooltip title={i18n.t("COC.CoverAssistantTicket")} placement="top">
						<AndroidIcon
							style={{ color: conversationToAssign?.header?.humanRequest ? "red" : "black" }}
						/>
					</Tooltip>
				</IconButton>
			) : (
				<Tooltip title={i18n.t("COC.assignTicket")} placement="top">
					<IconButton
						size="small"
						onClick={event => {
							assignConversation(event, conversationToAssign);
						}}
					>
						{switchTabOnAssignation ? <ArrowForwardIcon /> : <KeyboardTabIcon />}
					</IconButton>
				</Tooltip>
			)}
		</Box>
	);
};

/**
 *
 * @returns
 */
export const DeescalateConversation = () => {
	const dispatch = useDispatch();
	const { selectedConversation } = useSelector(getHeaderConvItemSelector, shallowEqual);

	/**
	 *
	 * @param {*} conv
	 */
	const desescalateConversation = conv => {
		dispatch(
			cockpitActions.deescalateConversation(
				conv,
				conv.header.escalationGroup,
				conv.header.resolverGroup,
				() => dispatch(infoMsg(i18n.t("COC.descalate")))
			)
		);
	};
	return (
		<Box component="span" mx={0.5}>
			<Tooltip title={"Desescalader la conversation"} placement="top">
				<IconButton onClick={() => desescalateConversation(selectedConversation)} size="small">
					<ArrowDownwardIcon />
				</IconButton>
			</Tooltip>
		</Box>
	);
};

/**
 *
 * @returns
 */
export const EscalateConversation = () => {
	const dispatch = useDispatch();

	const { selectedConversation } = useSelector(getHeaderConvItemSelector, shallowEqual);
	const [openEscalationDialog, setOpenEscalationDialog] = useState(false);

	const handleOpenEscalationDialog = () => {
		setOpenEscalationDialog(true);
	};
	const handleCloseEscalationDialog = () => {
		setOpenEscalationDialog(false);
	};

	return (
		<>
			<Box component="span" mx={0.5}>
				<Tooltip title={i18n.t("COC.Escalation")} placement="top">
					<IconButton onClick={handleOpenEscalationDialog} size="small">
						{" "}
						<ArrowUpwardIcon />{" "}
					</IconButton>
				</Tooltip>
			</Box>
			{openEscalationDialog && (
				<EscalationDialog
					conversation={selectedConversation}
					handleCloseEscalationDialog={handleCloseEscalationDialog}
					open={openEscalationDialog}
					showEscalationSuccessPopup={() => dispatch(infoMsg(i18n.t("COC.escalatedConv")))}
				/>
			)}
		</>
	);
};

export const ReopenHiddenConversation = () => {
	const dispatch = useDispatch();
	const { selectedConversation } = useSelector(getHeaderConvItemSelector, shallowEqual);

	const handleReopenConversation = () => {
		dispatch(
			cockpitActions.updateConversationState(
				"waiting",
				"expecting_answer",
				selectedConversation,
				() => {
					dispatch(infoMsg(i18n.t("COC.infoMoveToWaiting")));
				}
			)
		);
	};

	return (
		<Box>
			<Tooltip
				title={i18n.t("COC.moveToWaiting")}
				placement="top"
				onClick={handleReopenConversation}
			>
				<IconButton size="small">
					<UnarchiveOutlined />
				</IconButton>
			</Tooltip>
		</Box>
	);
};

export const DeleteDraft = () => {
	const dispatch = useDispatch();
	const { selectedConversation } = useSelector(getHeaderConvItemSelector, shallowEqual);

	const deleteDraft = () => {
		dispatch(
			cockpitActions.deleteDraft(selectedConversation, () => {
				dispatch(infoMsg(i18n.t("COC.deletedDraft")));
			})
		);
	};

	return (
		<Box>
			<Tooltip title={i18n.t("COC.deleteDraft")} placement="top" onClick={deleteDraft}>
				<IconButton size="small">
					<DeleteOutline />
				</IconButton>
			</Tooltip>
		</Box>
	);
};
