/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import cockpitActions from "redux/actions/cockpitActions";
import _lod from "lodash";
import labelsActions from "../../../redux/actions/labelsActions.js";
import { getLabelOf } from "../../../helpers/utilities.js";
import LoopRoundedIcon from "@material-ui/icons/LoopRounded";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.background.paper,
		overflow: "auto"
	},
	listSection: {
		backgroundColor: "inherit"
	},
	ul: {
		backgroundColor: "inherit",
		padding: 0
	}
}));

function UpdateConvIntentDialog(props) {
	const classes = useStyles();

	const [search, setSearch] = React.useState("");
	const [intentList, setIntentList] = React.useState([]);
	const [isGlobalIntent, setIsGlobalIntent] = React.useState(true);

	useEffect(() => {
		if (props.selectedAssistantID) {
			if (!_lod.isNil(props.intents)) {
				setIntentList(props.intents);
			}
		}
		// eslint-disable-next-line
	}, [props.selectedAssistantID, props.assistantconfig.intents]);

	const handleSearchChange = event => {
		const value = event.target.value;
		setSearch(value);
	};

	const handleUpdateMessageIntent = (selectedIntent, topicCategory) => {
		if (props.topic) {
			// this is when PrioritySentimentDisplayer is the parent
			props.handleUpdateMessageIntent(selectedIntent, topicCategory);
		} else {
			props.handleUpdateMessageIntent(selectedIntent, isGlobalIntent);
		}
		handleClose();
	};

	const toggleGlobalIntent = event => {
		const value = event.target.value;

		if (isGlobalIntent === false) {
			setIsGlobalIntent(true);
		} else {
			setIsGlobalIntent(false);
		}
	};

	const handleClose = () => {
		setIsGlobalIntent(false);
		props.handleCloseUpdateConvIntentDialog();
	};

	useEffect(() => {
		setSearch("");
	}, [props.open]);

	const modalName = props.topic ? props.t("COC.ModifyTopic") : props.t("COC.ModifyIntent");
	const listTitle = props.topic ? props.t("COC.topics") : props.t("COC.intents");

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={props.open}
			onClose={props.handleCloseUpdateConvIntentDialog}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{modalName}</DialogTitle>
			<DialogContent style={{ height: "65vh", display: "flex", flexDirection: "column" }} dividers>
				<Box display="flex" alignItems="center" justifyContent="center" width={1}>
					<Tabs
						value={0}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
					>
						<Tab label={listTitle} />
					</Tabs>
				</Box>
				{/* Intent search bar */}
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between"
					paddingTop="12px"
					paddingBottom="12px"
				>
					<TextField
						fullWidth
						label=""
						variant="outlined"
						size="small"
						onChange={handleSearchChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							)
						}}
					/>
				</Box>
				{/* Mapping to display intents list */}
				{intentList && intentList.length > 0 && (
					<List className={classes.root} subheader={<li />}>
						{intentList
							.filter(intent => {
								if (!search.trim()) {
									// If search is empty, return all intents
									return true;
								} else {
									// If search is not empty, return intents that contain the search string
									return getLabelOf(intent.name).toLowerCase().includes(search.toLowerCase());
								}
							})
							.map(intent => {
								let intentLabel = getLabelOf(intent.name);
								return {
									...intent,
									label: intentLabel
								};
							})
							.sort((a, b) => a.label.localeCompare(b.label))
							.map((intent, index) => (
								<React.Fragment key={`intent-${index}`}>
									<ListItem>
										<ListItemText
											primary={
												<React.Fragment>
													{intent.label}
													<Typography component="span" variant="body2" color="textSecondary" />
												</React.Fragment>
											}
											secondary={intent.category}
										/>

										<ListItemSecondaryAction>
											<Tooltip placement="top" title={`${props.t("COC.ReplaceIntent")}`}>
												<IconButton
													onClick={() => handleUpdateMessageIntent(intent.name, intent.category)}
													edge="end"
												>
													<LoopRoundedIcon />
												</IconButton>
											</Tooltip>
										</ListItemSecondaryAction>
									</ListItem>
									<Divider />
								</React.Fragment>
							))}
					</List>
				)}
			</DialogContent>
			<DialogActions>
				{!props.topic ? (
					<Box
						display="flex"
						flexDirection="row"
						flexGrow="1"
						alignItems="center"
						justifyContent="flex-start"
					>
						<IconButton onClick={toggleGlobalIntent}>
							<Checkbox checked={isGlobalIntent} />
						</IconButton>

						<p style={{ marginBottom: 0 }}>{`${props.t("COC.GlobalConvIntent")}`}</p>
					</Box>
				) : null}
				<Button onClick={handleClose} color="secondary" style={{ marginRight: 27 }}>
					{`${props.t("COC.Cancel")}`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch),
		labelsActions: bindActionCreators(labelsActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(UpdateConvIntentDialog);
