/* eslint-disable react/prop-types */
/* eslint-disable   no-unused-vars */
import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import CloseButton from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import cockpitActions from "redux/actions/cockpitActions";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { MySnackbar, invalidFileExtensions, getACleanURI } from "./../helpers/utilities";
import { withTranslation } from "react-i18next";
import i18n from "i18n";
import { Attachment } from "./AttachmentsList";
import { Grid } from "@material-ui/core";

const EditorAttachments = props => {
	const [snackbarStatus, setSnackbarStatus] = useState({
		open: false,
		message: ""
	});

	return (
		<>
			<MySnackbar
				style={{ top: "65px" }}
				open={snackbarStatus.open}
				onClose={() => setSnackbarStatus({ open: false })}
				autoHideDuration={1500}
				message={snackbarStatus.message}
				transition="slide"
				direction="left"
				timeout={{
					enter: 500
				}}
			/>
			<Grid
				container
				ref={props.childRef}
				style={{
					width: "100%",
					maxHeight: "12vh",
					overflowY: "hidden",
					overflowX: "auto",
					flexWrap: "nowrap"
				}}
			>
				{props.cockpit.attachments.length > 0 &&
					props.cockpit.attachments.map((attachment, index) => {
						return (
							<Attachment
								progress={attachment.progress}
								fileName={attachment.name}
								key={index}
								attachmentId={attachment.id}
								size={attachment.size}
								path={attachment.path}
								contentType={attachment.contentType}
								mimeType={attachment.mimeType}
								url={getACleanURI(attachment.url)}
							/>
						);
					})}
			</Grid>
		</>
	);
};

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(EditorAttachments);
