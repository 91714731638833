/* eslint-disable react/prop-types */
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles
} from "@material-ui/core";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import lod_ from "lodash";
import React, { forwardRef, memo, useEffect } from "react";
import cockpitActions from "../../../redux/actions/cockpitActions";
import i18n from "i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getRedirectionDialogSelector } from "redux/selectors/cockpitSelectors.js";
import { getConversationGroups } from "helpers/utilities";
import { TagsList } from "components/Tags/TagsList";

/**
 *
 */
const RedirectionDialogGroups = forwardRef(
	({ showRedirectionSuccessPopup, handleCloseGroupRedirectionDialog, open }, ref) => {
		const dispatch = useDispatch();
		const { selectedConversation } = useSelector(state => state.cockpit);
		let { userStatus } = useSelector(getRedirectionDialogSelector, shallowEqual);
		const { groups } = useSelector(state => state.userGroup);
		const [selected, setSelected] = React.useState([]);

		let conversationGroups = getConversationGroups();

		/**
		 *
		 */
		useEffect(() => {
			setSelected(conversationGroups);
		}, [groups]);

		/**
		 *
		 */
		const handleGroupRedirection = () => {
			if (lod_.has(userStatus, "auth.user")) {
				dispatch(
					cockpitActions.redirectConversationToGroup(
						selectedConversation.header,
						selectedConversation.header.state,
						selectedConversation.header.resolverGroup,
						selected.map(group => group._id),
						showRedirectionSuccessPopup
					)
				);
				handleCloseGroupRedirectionDialog();
			}
		};

		return (
			<Dialog
				fullWidth
				maxWidth="md"
				open={open}
				onClose={handleCloseGroupRedirectionDialog}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{i18n.t("COC.Redirect")}</DialogTitle>
				<DialogContent dividers>
					{selectedConversation && groups?.length > 0 && (
						<TagsList
							list={groups}
							labelField={"name"}
							titleField={"description"}
							valueField={"_id"}
							isForm={true}
							selected={selected}
							setSelected={setSelected}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseGroupRedirectionDialog} color="primary">
						{`${i18n.t("COC.Cancel")}`}
					</Button>

					<Button
						disabled={selected.length === 0}
						onClick={() => handleGroupRedirection()}
						color="primary"
					>
						{`${i18n.t("COC.ApplyRedirect")}`}
						<CallSplitIcon />
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
);

const MemoizedRedirectionDialogGroups = memo(RedirectionDialogGroups);
export default MemoizedRedirectionDialogGroups;
