/* eslint-disable no-unused-vars */
import C from "../../constants/userStatus.js";
import store from "../store.js";
import axios from "axios";
import lod_ from "lodash";
import Cookies from "universal-cookie";
import { api } from "./api.js";

// require("dotenv").config();
const endPoint = process.env.REACT_APP_AMBACK;

const actions = {
	addUserData: function (user, cb) {
		return dispatch => {
			axios(endPoint + "/api/profile", {
				headers: {
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "POST",
				data: {
					email: user.email
				}
			})
				.then(additionalUserData => {
					dispatch({
						type: C.ADD_USER_DATA,
						payload: {
							user: {
								...user,
								...additionalUserData.data
							}
						}
					});
					cb();
				})
				.catch(error => {});
		};
	},

	saveUserdata: function (profilToUpdate, successCallback, errorCallback) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			let assistantID = store.getState().selectedAssistantID;
			const data = {
				email: user.email,
				user: profilToUpdate,
				assistantID
			};
			return dispatch => {
				axios(endPoint + "/api/saveprofile", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				})
					.then(res => {
						dispatch({
							type: C.SAVE_USER_DATA,
							payload: profilToUpdate
						});

						if (successCallback) {
							successCallback(profilToUpdate);
						}
					})
					.catch(err => {
						if (errorCallback) {
							errorCallback();
						}
					});
			};
		}
	},
	saveUsersFilter: function (userEmails, dataToUpdate, successCallback, errorCallback) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			let assistantID = store.getState().selectedAssistantID;
			const data = {
				email: user.email,
				userEmails,
				dataToUpdate,
				assistantID
			};

			return dispatch => {
				axios(endPoint + "/api/saveFilterOnProfiles", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				})
					.then(res => {
						dispatch({
							type: C.SAVE_USER_PROFILE,
							payload: dataToUpdate
						});

						if (successCallback) {
							successCallback(dataToUpdate);
						}
					})
					.catch(err => {
						if (errorCallback) {
							errorCallback();
						}
					});
			};
		}
	},
	resetPassword: (infos, successCb, failCb) => {
		let user = store.getState().userStatus.auth.user;

		if (user.sub) {
			infos = {
				...infos,
				auth0Id: user.sub
			};
		}

		if (user.email) {
			infos = {
				...infos,
				_id: user.email
			};
		}

		const data = {
			email: user.email,
			user: infos
		};

		return () => {
			axios
				.put(`${endPoint}/api/resetPassword`, data, {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					}
				})
				.then(res => {
					if (res.status === 200) {
						successCb();
					} else {
						failCb();
					}
				})
				.catch(err => {
					failCb();
				});
		};
	},

	hideReleaseNote: function (isReleaseNoteHiddenSelected, version, successCb) {
		let user = store.getState().userStatus.auth.user;
		let data = { isReleaseNoteHiddenSelected, version };
		if (user) {
			return function (dispatch) {
				axios({
					url: endPoint + "/api/v1/hideReleaseNote",
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data
				})
					.then(res => {
						if (res.status === 200) {
							dispatch({
								type: C.HIDE_RELEASE_NOTE,
								payload: {
									cockpit_release_note: {
										dont_show_at_opening: isReleaseNoteHiddenSelected,
										version: version
									}
								}
							});
							successCb();
						}
					})
					.catch(error => {
						return error;
					});
			};
		}
	},

	hideSessionReleaseNote: function (hideReleaseNotesForSession) {
		let user = store.getState().userStatus.auth.user;
		let data = { hideReleaseNotesForSession };

		sessionStorage.setItem("releaseNoteDisplayed", true);
		if (user) {
			return function (dispatch) {
				dispatch({
					type: C.HIDE_SESSION_RELEASE_NOTE,
					payload: data
				});
			};
		}
	},

	saveOptionUser: function (data) {
		let user = store.getState().userStatus.auth.user;
		return dispatch => {
			dispatch(
				api({
					type: "cockpit/saveOptionUser",
					url: "/api/v1/saveOptionUser",
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: { ...data, user },
					onSuccess: res => {
						dispatch({
							type: C.SAVE_USER_DATA,
							payload: res
						});
					}
				})
			);
		};
	}
};

export default actions;
