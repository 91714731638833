import { createSelector } from "reselect";
import lod_ from "lodash";

export const getCockpitReducer = state => state.cockpit;
export const getUserReducer = state => state.userStatus;
export const getS3Reducer = state => state.s3;
export const getAnswers = state => state.answers;
export const getAssistantConfig = state => state.assistantconfig;
export const getSelectedAssistantID = state => state.selectedAssistantID;
export const getUserGroup = state => state.userGroup;
export const getWSReducer = state => state.ws;

export const getConvsContainerSelector = createSelector(
	[getCockpitReducer, getAssistantConfig, getSelectedAssistantID],
	(
		{
			conversations,
			isCockpitArchived,
			nbArchivedConvs,
			nbWaitingConvs,
			nbOngoingConvs,
			nbColdConvs,
			nbLiveConvs,
			nbResolvedConvs,
			nbHiddenConvs,
			leftTab,
			nbCurrentConvs,
			convIntentFilter,
			convGroupFilter,
			convUserFilter,
			convSearchFilter,
			convStartDateFilter,
			convEndDateFilter,
			convPriorityFilter,
			convSentimentFilter,
			convSubstateFilter,
			convConnectedFilter,
			convEscalationFilter,
			convLanguageFilter,
			convChannelFilter,
			selectedConversation
		},
		{ channels },
		selectedAssistantID
	) => ({
		conversations,
		isCockpitArchived,
		hasLiveChat: lod_.some(channels, { type: "LC" }),
		nbWaitingConvs,
		nbOngoingConvs,
		nbLiveConvs,
		nbResolvedConvs,
		nbArchivedConvs,
		nbColdConvs,
		nbHiddenConvs,
		leftTab,
		nbCurrentConvs,
		convIntentFilter,
		convGroupFilter,
		convUserFilter,
		convSearchFilter,
		convStartDateFilter,
		convEndDateFilter,
		convPriorityFilter,
		convSentimentFilter,
		convSubstateFilter,
		convConnectedFilter,
		convEscalationFilter,
		convLanguageFilter,
		convChannelFilter,
		selectedAssistantID,
		selectedConversation
	})
);

export const getChannelUserInfoMembershipDisplayerSelector = createSelector(
	[getAssistantConfig, getCockpitReducer, getUserReducer],
	(assistantconfig, { selectedConversation, isCold }, userStatus) => ({
		assistantconfig,
		selectedConversation,
		user: userStatus?.auth?.user,
		isCold
	})
);
export const getHeaderConvItemSelector = createSelector(
	[getCockpitReducer, getUserReducer, getAssistantConfig],
	(
		{ selectedConversation, convHistory, historyLoading, countHistory },
		userStatus,
		assistantconfig
	) => ({
		selectedConversation,
		convHistory,
		userStatus,
		historyLoading,
		countHistory,
		assistantconfig
	})
);

export const getRedirectionDialogSelector = createSelector(
	[getSelectedAssistantID, getUserGroup, getUserReducer, getCockpitReducer],
	(selectedAssistantID, userGroup, userStatus, { selectedConversation }) => ({
		selectedAssistantID,
		userGroup,
		userStatus,
		resolverGroup: selectedConversation?.header?.resolverGroup
	})
);

export const getCockpitSelector = createSelector(
	[getCockpitReducer, getUserReducer, getAssistantConfig, getSelectedAssistantID],
	(
		{
			conversations,
			selectedConversation,
			convHistory,
			leftTab,
			attachments,
			convLoading,
			isCockpitArchived,
			isCold,
			isConvListMinimized
		},
		userStatus,
		assistantConfig,
		selectedAssistantID
	) => ({
		conversations,
		selectedConversation,
		convHistory,
		leftTab,
		attachments,
		convLoading,
		userStatus,
		isCockpitArchived,
		isCold,
		assistantConfig,
		selectedAssistantID,
		isConvListMinimized
	})
);

export const getCockpitTabInfoSelector = createSelector(
	[getCockpitReducer, getUserReducer, getAnswers, getAssistantConfig, getSelectedAssistantID],
	(
		{ conversations, selectedConversation, convHistory, leftTab, isCold },
		userStatus,
		answers,
		assistantconfig,
		selectedAssistantID
	) => ({
		isCold,
		conversations,
		selectedConversation,
		convHistory,
		selectedAssistantID,
		leftTab,
		userStatus,
		answers,
		assistantconfig
	})
);

export const getMesssageItemSelector = createSelector(
	[getAssistantConfig, getCockpitReducer, getUserReducer, getSelectedAssistantID, getAnswers],
	(
		{ channels, cockpit, intents, limit, signature, actions },
		{ selectedConversation },
		userStatus,
		selectedAssistantID
	) => {
		return {
			channels,
			cockpit,
			intents,
			actions,
			limit,
			selectedConversation,
			userStatus,
			selectedAssistantID,
			fontSize: userStatus?.auth?.user?.fontSize || 18,
			userSignature: signature?.userSignature,
			assistantSignature: signature?.assistantSignature
		};
	}
);

export const getEditMesssageItemSelector = createSelector(
	[
		getCockpitReducer,
		getUserReducer,
		getS3Reducer,
		getSelectedAssistantID,
		getAssistantConfig,
		getWSReducer
	],
	(
		{
			selectedConversation,
			postDraftLoading,
			addedContent,
			topicDraft,
			convSending,
			currentMessage,
			attachments,
			urgent,
			htmlToInsert
		},
		{ auth },
		{ isUploading },
		selectedAssistantID,

		{ cockpit, signature },
		{ compositeUploadUrl }
	) => {
		const user = auth?.user;

		return {
			selectedConversation,
			postDraftLoading,
			addedContent,
			topicDraft,
			convSending,
			currentMessage,
			attachments,
			urgent,
			htmlToInsert,
			user,
			isUploading,
			selectedAssistantID,
			autoForward: cockpit?.autoForward,
			userSignature: signature?.userSignature,
			assistantSignature: signature?.assistantSignature,
			compositeUploadUrl
		};
	}
);

export const getConvItemSelector = createSelector(
	[getAssistantConfig, getCockpitReducer, getUserReducer],
	(assistantconfig, { selectedConversation, isCold }, userStatus) => ({
		selectedConversation,
		userLanguage: userStatus?.auth?.user?.language?.toLowerCase() || "fr",
		dateFormat: assistantconfig?.cockpit?.datetime_format?.format,
		email: userStatus?.auth?.user?.email,
		role: userStatus?.auth?.user?.role,
		switchTabOnAssignation: lod_.isNil(assistantconfig?.cockpit?.switchTabOnAssignation)
			? true
			: assistantconfig.cockpit.switchTabOnAssignation,
		isCold
	})
);

export const getStepValidateProofDisputeSelector = createSelector(
	[getCockpitReducer, getSelectedAssistantID],
	({ selectedConversation }, selectedAssistantID) => ({
		dispute: selectedConversation.context.dispute,
		fID: selectedConversation.header.fID,
		bl: selectedConversation.context.proof?.bl,
		pod: selectedConversation.context.proof?.pod,
		selectedAssistantID
	})
);
