import { Tooltip } from "@material-ui/core";
import { AssignmentOutlined, AttachFileOutlined, InfoOutlined } from "@material-ui/icons";
import { Tab, Tabs } from "@mui/material";
import i18n from "i18n";
import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getHeaderConvItemSelector } from "redux/selectors/cockpitSelectors";

export const TabInfoTabsCALL = ({ rightTab, handleChangeRightTab, callInfo }) => {
	const { selectedConversation, countHistory } = useSelector(
		getHeaderConvItemSelector,
		shallowEqual
	);

	useEffect(() => {
		if (callInfo === true) {
			handleChangeRightTab(null, 0);
		} else {
			handleChangeRightTab(null, 3);
		}
	}, [selectedConversation?.header?.fID]);
	if (callInfo === true) {
		return (
			<Tabs
				value={[0, 2, 3].includes(rightTab) ? rightTab : 0}
				onChange={handleChangeRightTab}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				style={{
					width: "100%"
				}}
			>
				<Tab
					value={0}
					style={{ minWidth: 20, fontFamily: "Roboto", fontSize: "14px", flex: 1 }}
					icon={
						<Tooltip title={i18n.t("COC.Contact")} placement="top">
							<InfoOutlined />
						</Tooltip>
					}
				/>
				<Tab
					value={2}
					style={{ minWidth: 20, fontFamily: "Roboto", fontSize: "14px", flex: 1 }}
					icon={
						<Tooltip title={i18n.t("COC.File")} placement="top">
							<AttachFileOutlined />
						</Tooltip>
					}
				/>
				<Tab
					value={3}
					style={{ minWidth: 20, fontFamily: "Roboto", fontSize: "14px", flex: 1 }}
					label={selectedConversation?.notes?.length > 0 ? selectedConversation?.notes?.length : ""}
					icon={
						<Tooltip title={i18n.t("COC.Notes")} placement="top">
							<AssignmentOutlined />
						</Tooltip>
					}
				/>
			</Tabs>
		);
	} else {
		return (
			<Tabs
				value={[3, 5].includes(rightTab) ? rightTab : 3}
				onChange={handleChangeRightTab}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				data-component-name="TabInfoTabsCALL"
			>
				<Tab
					value={3}
					style={{ minWidth: 20, fontSize: "12px" }}
					label={`${i18n.t("COC.Notes")}(${selectedConversation.notes?.length || 0})`}
				/>
				<Tab
					value={5}
					style={{ minWidth: 20, fontSize: "12px" }}
					label={`${i18n.t("COC.history")}(${countHistory})`}
				/>
			</Tabs>
		);
	}
};
