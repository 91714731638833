import React from "react";
import { Icon, List, ListItem } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";
import { compose } from "redux";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { useAuth0 } from "react-auth0-wrapper";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import { Dashboard, Settings } from "@material-ui/icons";

const toAdmin = process.env.REACT_APP_ADMINMANAGER;
const toAnalytics = process.env.REACT_APP_ANALYTICS;

function SettingsMenu(props) {
	const { classes, state } = props;
	const { logout } = useAuth0();

	const role = props.userStatus && props.userStatus.auth && props.userStatus.auth.user.role;

	function redirectToAdmin() {
		let loader = document.getElementsByTagName("BODY")[0];
		loader.className = "loading";
		setTimeout(() => {
			loader.className = "";
		}, 3000);
		window.open(toAdmin, "_blank");
	}
	function redirectToAnalytics() {
		let loader = document.getElementsByTagName("BODY")[0];
		loader.className = "loading";
		setTimeout(() => {
			loader.className = "";
		}, 3000);
		window.open(toAnalytics, "_blank");
	}

	const navLinkClasses = classes.itemLink;

	const itemText =
		classes.itemText +
		" " +
		cx({
			[classes.itemTextMini]: state?.miniActive
		});

	return (
		<>
			<List className={classes.collapseList} component="div" disablePadding>
				<ListItem className={classes.item}>
					<NavLink
						className={navLinkClasses}
						to={"#"}
						onClick={() => props.handleOpenDisplayReleaseNotes()}
					>
						<Icon className={classes.itemIcon} style={{ color: "white" }}>
							<NotificationsNoneOutlinedIcon />
						</Icon>
						<ListItemText
							className={itemText}
							disableTypography={true}
							primary={"Notifications"}
							style={{ color: "white" }}
						/>
					</NavLink>
				</ListItem>
				{role && (role === "supervisor" || role === "administrator" || role === "soloUser") && (
					<ListItem className={classes.item}>
						<NavLink className={navLinkClasses} to={"#"} onClick={() => redirectToAdmin()}>
							<Icon className={classes.itemIcon} style={{ color: "white" }}>
								<Settings />
							</Icon>
							<ListItemText
								className={itemText}
								disableTypography={true}
								primary={"Admin"}
								style={{ color: "white" }}
							/>
						</NavLink>
					</ListItem>
				)}
				{role && (role === "supervisor" || role === "soloUser") && (
					<ListItem className={classes.item}>
						<NavLink className={navLinkClasses} to={"#"} onClick={() => redirectToAnalytics()}>
							<Icon className={classes.itemIcon} style={{ color: "white" }}>
								<Dashboard />
							</Icon>
							<ListItemText
								className={itemText}
								disableTypography={true}
								primary={"Analytics"}
								style={{ color: "white" }}
							/>
						</NavLink>
					</ListItem>
				)}
				<ListItem className={classes.item}>
					<NavLink
						className={navLinkClasses}
						to={"#"}
						onClick={() => logout({ returnTo: window.location.origin })}
					>
						<Icon className={classes.itemIcon} style={{ color: "white" }}>
							<PowerSettingsNewOutlinedIcon />
						</Icon>
						<ListItemText
							className={itemText}
							disableTypography={true}
							primary={props.t("manageButton.logout")}
							style={{ color: "white" }}
						/>
					</NavLink>
				</ListItem>
			</List>
		</>
	);
}

function mapStateToProps(state) {
	return state;
}

export default compose(
	withTranslation(),

	connect(mapStateToProps)
)(SettingsMenu);
