import { createAction } from "@reduxjs/toolkit";

const onAppFocus = createAction("ws/onAppFocus");
const onAppBlur = createAction("ws/onAppBlur");
const wsConnected = createAction("ws/connected");
const wsDisconnected = createAction("ws/disconnected");
const wsEvent = createAction("ws/event");

export { onAppFocus, onAppBlur, wsConnected, wsDisconnected, wsEvent };

export const EVENT_PING = "EVENT_PING";
