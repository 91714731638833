/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from "react";

import Box from "@material-ui/core/Box";
import ConvItemRenderer from "./ConvItemRenderer";
import C from "../../../constants/cockpit.js";
import cockpitActions from "../../../redux/actions/cockpitActions";
import {
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache,
	InfiniteLoader,
	List as VirtualList
} from "react-virtualized";
import { useDispatch, useSelector } from "react-redux";
import { getConversationTypeByLeftTab } from "helpers/utilities";
import { getCountByConversationType } from "helpers/utilities";
import ConvItemRendererCOLD from "./cold/ConvItemRendererCOLD";

const cache = new CellMeasurerCache({
	fixedWidth: true,
	defaultHeight: 60
});

var loadMoreRowsTimer = null;
const loadMoreRowsDelay = 1500;
var loadMoreRowsStart = 0;

const renderRow = (index, parent, key, style, conversations, handleSelectConversation, isCold) => {
	return (
		<CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
			{({ measure, registerChild }) => (
				<div ref={registerChild} style={style}>
					{isCold ? (
						<ConvItemRendererCOLD
							measure={measure}
							conv={conversations[index]}
							handleSelectConversation={handleSelectConversation}
						/>
					) : (
						<ConvItemRenderer
							measure={measure}
							conv={conversations[index]}
							handleSelectConversation={handleSelectConversation}
						/>
					)}
				</div>
			)}
		</CellMeasurer>
	);
};

/**
 * List of all conversations with scroll pagination.
 * Here we send filters in the back for the query, sort fields are specified in cockpitActions.getScrollPaginatedConversations
 */
const Convs = ({ handleSelectConversation }) => {
	const listRef = useRef();
	const dispatch = useDispatch();
	/**
	 * PROPS
	 */
	const {
		conversations,
		selectedConversation,
		scrollToSelectedConversation,
		leftTab,
		convAscFilter,
		convAgentFilter,
		convSupervisorFilter,
		convSearchFilter,
		convIntentFilter,
		convGroupFilter,
		convUserFilter,
		convStartDateFilter,
		convEndDateFilter,
		convPriorityFilter,
		convSentimentFilter,
		convSubstateFilter,
		convConnectedFilter,
		convEscalationFilter,
		convLanguageFilter,
		convChannelFilter,
		isCockpitArchived,
		isCold,
		nbCurrentConvs,
		convsReload,
		convDirectionFilter,
		convNoBotFilter
	} = useSelector(state => state.cockpit);
	const { auth } = useSelector(state => state.userStatus);
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);
	/**
	 * OTHER VARS
	 */
	const prevUserRef = useRef();
	const prevIntentRef = useRef();
	const prevGroupRef = useRef();

	let totalCount = getCountByConversationType();
	const leftTabType = getConversationTypeByLeftTab(leftTab);
	if (nbCurrentConvs[0] === leftTabType) totalCount = nbCurrentConvs[1] + 1;
	/**
	 * On assistantID or tab change
	 */
	useEffect(async () => {
		if (selectedAssistantID) {
			await resetLoad("leftTab, selectedAssistantID");
		}
	}, [leftTab, selectedAssistantID]);

	/**
	 * To reload from ConvsContainerTabs, on Tab click
	 */
	useEffect(async () => {
		if (convsReload) {
			dispatch({ type: C.SET_CONVS_RELOAD, payload: false });
			await resetLoad("convsReload");
		}
	}, [convsReload]);

	/**
	 * On filters change
	 */
	useEffect(async () => {
		if (auth?.user) {
			await resetLoad("many filters");
		}
	}, [
		convSearchFilter,
		convChannelFilter,
		convEscalationFilter,
		convLanguageFilter,
		convPriorityFilter,
		convSentimentFilter,
		convStartDateFilter,
		convEndDateFilter,
		convAgentFilter,
		convSupervisorFilter,
		convSubstateFilter,
		convConnectedFilter,
		convDirectionFilter,
		convNoBotFilter
	]);

	// ------- To avoid useless multiple renders
	// -------
	/** On INTENT change if filter is ON */
	useEffect(async () => {
		if (prevIntentRef.current && prevIntentRef.current !== convIntentFilter) {
			await resetLoad("convIntentFilter");
		}
		prevIntentRef.current = convIntentFilter;
	}, [convIntentFilter]);

	/** On USER change if filter is ON */
	useEffect(async () => {
		if (prevUserRef.current && prevUserRef.current !== convUserFilter) {
			await resetLoad("convUserFilter");
		}
		prevUserRef.current = convUserFilter;
	}, [convUserFilter]);

	/** On DIRECTION change if filter is SELECTED */
	useEffect(async () => {
		if (typeof convDirectionFilter === "string") {
			await resetLoad("convDirectionFilter");
		}
	}, [convDirectionFilter]);

	/** On GROUP change if filter is ON */
	useEffect(async () => {
		if (prevGroupRef.current && prevGroupRef.current !== convGroupFilter) {
			await resetLoad("convGroupFilter");
		}
		prevGroupRef.current = convGroupFilter;
	}, [convGroupFilter]);

	/** On ASC change*/
	useEffect(async () => {
		if (typeof convAscFilter === "boolean") {
			await resetLoad("convAscFilter");
		}
	}, [convAscFilter]);
	// -------
	// -------

	/**
	 *
	 */
	useEffect(() => {
		if (scrollToSelectedConversation) {
			const index = conversations
				.map(conv => conv.header.fID)
				?.indexOf(selectedConversation?.header?.fID);
			if (index >= 0) {
				listRef.current?.scrollToRow(index);
			}
			dispatch({
				type: C.CLEAR_SCROLL_TO_SELECTED_CONVERSATION
			});
		}
	}, [scrollToSelectedConversation]);

	/**
	 *
	 * @returns
	 */
	function isRowLoaded({ index }) {
		return !!conversations[index];
	}

	/**
	 * This function is called each time we change filters, assistantID or tab
	 */
	const resetLoad = async origin => {
		// if there is a timer, clean it ...
		if (loadMoreRowsTimer !== null) {
			// console.log("PIKA -> resetLoad timer cleaned -", origin);
			clearTimeout(loadMoreRowsTimer);
		}
		// ... and recreate it, until ...
		// console.log("PIKA -> resetLoad wait -", origin);
		loadMoreRowsTimer = setTimeout(async () => {
			// console.log("PIKA -> resetLoad doing the call -", origin);
			// timer expired and then call the function
			await loadMoreRows({
				startIndex: 0,
				stopIndex: 9,
				count: true
			});
			// console.log("PIKA -> resetLoad called and timer cleaned -", origin);
			loadMoreRowsTimer = null;
		}, loadMoreRowsDelay);
	};

	/**
	 * This function is called onMount and onScroll by InfiniteLoader (cf below)
	 */
	async function loadMoreRows({ startIndex, stopIndex, count }) {
		if (selectedAssistantID) {
			const tab = getConversationTypeByLeftTab(leftTab, isCockpitArchived);

			const limit = stopIndex - startIndex;
			if (limit > 0) {
				if (isCold && isCockpitArchived) {
					await dispatch(cockpitActions.getScrollPaginatedColdConversations(limit, startIndex));
				} else if (tab !== "cold") {
					// console.log(
					// 	"PIKA -> calling getScrollPaginatedConversations with params",
					// 	JSON.stringify({ tab, limit, startIndex, count })
					// );
					const now = new Date().getTime();
					if (now - loadMoreRowsStart > loadMoreRowsDelay) {
						// console.log("PIKA -> loadMoreRows - in between calls is", now - loadMoreRowsStart);
						await dispatch(
							cockpitActions.getScrollPaginatedConversations(tab, limit, startIndex, count)
						);
						loadMoreRowsStart = now;
					}
				}
			}
		}
	}

	return (
		<Box height="100%" data-component-name="Convs">
			{
				<InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows} rowCount={totalCount}>
					{({ onRowsRendered, registerChild }) => (
						<AutoSizer>
							{({ height, width }) => (
								<VirtualList
									ref={registerChild}
									width={width}
									height={height}
									rowCount={conversations.length}
									deferredMeasurementCache={cache}
									rowHeight={cache.rowHeight}
									onRowsRendered={onRowsRendered}
									rowRenderer={({ key, index, style, parent }) =>
										renderRow(
											index,
											parent,
											key,
											style,
											conversations,
											handleSelectConversation,
											isCold
										)
									}
								/>
							)}
						</AutoSizer>
					)}
				</InfiniteLoader>
			}
		</Box>
	);
};
export default Convs;
