/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import i18n from "i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, compose } from "redux";
import cockpitActions from "../../../../redux/actions/cockpitActions";
import { Box, IconButton } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import EngageDropDown, { EngagePhone, EngageTask } from "../../../Engage/EngageDropDown";
import C from "../../../../constants/cockpit.js";

function SearchFilter({ handleEngagedialog, possibleChannels }) {
	const { isCockpitArchived, convSearchFilter, isCold } = useSelector(state => state.cockpit);
	const dispatch = useDispatch();
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);

	const [openPhone, setOpenPhone] = useState(false);
	const [openTask, setOpenTask] = useState(false);

	const [anchorEl, setAnchorEl] = useState(null);
	const [text, setText] = useState("");

	const [selectedChannelCode, setSelectedChannelCode] = useState(null);

	useEffect(() => {
		setText(convSearchFilter ? convSearchFilter : "");
	}, [selectedAssistantID]);

	useEffect(() => {
		setText(convSearchFilter);
	}, [convSearchFilter]);

	useEffect(() => {
		handleConvSearchFilter("");
	}, [isCockpitArchived]);

	useEffect(() => {
		if (text !== null) {
			const delay = setTimeout(() => {
				handleConvSearchFilter(text);
			}, 2000);
			return () => clearTimeout(delay);
		}
	}, [text]);

	const handleConvSearchFilter = value => {
		dispatch({
			type: C.SET_CONV_SEARCH_FILTER,
			payload: value.trim()
		});
	};

	const handleDropDownMenu = event => {
		return setAnchorEl(event.currentTarget);
	};

	const handleCloseEngageDropDown = () => {
		setAnchorEl(null);
	};

	const handleClosePhone = () => {
		setOpenPhone(false);
		setAnchorEl(null);
	};

	const handleCloseTask = () => {
		setOpenTask(false);
		setAnchorEl(null);
	};

	const openEngage = channel => {
		return handleEngagedialog(channel);
	};

	const handleClickEngage = event => {
		if (Array.isArray(possibleChannels) && possibleChannels.length === 1) {
			if (possibleChannels[0]?.type === "PH") {
				setSelectedChannelCode(possibleChannels[0]?.code);
				setOpenPhone(true);
			} else if (possibleChannels[0]?.type === "CA" && possibleChannels[0]?.subtype === "task") {
				setSelectedChannelCode(possibleChannels[0]?.code);
				setOpenTask(true);
			} else {
				handleEngagedialog(possibleChannels[0]);
			}
		} else {
			handleDropDownMenu(event);
		}
	};

	return (
		<Box display="flex" mx={1} mt={1}>
			<Box flexGrow={0.8}>
				<TextField
					onChange={e => setText(e.target.value)}
					value={text}
					fullWidth
					variant="outlined"
					size="small"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
						endAdornment: (
							<IconButton size="small" onClick={() => setText("")}>
								{convSearchFilter?.trim().length > 0 && <ClearIcon />}
							</IconButton>
						)
					}}
				/>
			</Box>
			{possibleChannels !== null && !isCockpitArchived && (
				<Box pl="3%" pr="3%" textAlign="center">
					<IconButton
						size="small"
						ml={2}
						mx={2}
						onClick={e => {
							handleClickEngage(e);
						}}
					>
						<AddIcon />
					</IconButton>
					<p> {i18n.t("COC.NewEmail")}</p>
				</Box>
			)}
			{possibleChannels !== null && possibleChannels.length > 1 && anchorEl && (
				<EngageDropDown
					possibleChannels={possibleChannels}
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
					handleClose={handleCloseEngageDropDown}
					handleOpenEngageDialog={openEngage}
					// initialDraft={}  No initial draft in this case, as we want to create an empty conversation
				/>
			)}

			{openPhone && (
				<EngagePhone
					handleClosePhone={handleClosePhone}
					openPhone={openPhone}
					dispatch={dispatch}
					channelCode={selectedChannelCode}
				/>
			)}

			{openTask && (
				<EngageTask
					handleCloseTask={handleCloseTask}
					openTask={openTask}
					dispatch={dispatch}
					channelCode={selectedChannelCode}
				/>
			)}
		</Box>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(SearchFilter);
