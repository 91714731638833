import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Popover, RadioGroup, FormControlLabel, Radio, FormLabel } from "@material-ui/core";
import cockpitActions from "../../redux/actions/cockpitActions";
import lod_ from "lodash";

const SearchDataInConversationSelect = ({ anchorEl, handleClose, handleSubmit, actionObject }) => {
	const { user, selectedConversation } = useSelector(state => {
		return {
			user: state.userStatus.auth.user,
			selectedConversation: state.cockpit.selectedConversation
		};
	});
	const nextActionsFront = actionObject.config?.nextActionsFront;
	const searchLabel = actionObject.config?.searchLabel;
	const mappingOut = actionObject.config?.mappingOut;
	const listFields = actionObject.config?.listFields;
	const selectValue = lod_.get(selectedConversation, listFields) || {};
	const externalId = user?.external_id;
	const dispatch = useDispatch();

	const [selectedValue, setSelectedValue] = React.useState("");

	const nextActionsWithPayload = nextActionsFront.map(act => {
		return { action: act, payload: externalId ? { externalId: externalId } : {} };
	});

	const handleRadioChange = event => {
		const selectedEsiValue = event.target.value;
		if (mappingOut && selectedEsiValue) {
			const data = {
				header: {
					assistantID: selectedConversation?.header?.assistantID,
					fID: selectedConversation?.header?.fID
				},
				payload: {}
			};
			data.payload[mappingOut] = selectedEsiValue;

			dispatch(
				cockpitActions.launchActionFrontV2("updateConvContextPartial", data, nextActionsWithPayload)
			);
		}

		setSelectedValue(selectedEsiValue);
		handleClose();
		//handleSubmit({}, actionObject.action);
	};

	return (
		<Popover
			open={true}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "center",
				horizontal: "right"
			}}
		>
			<Box display="flex" flexDirection="column" alignItems="center" padding={2}>
				<Box mt={1}>
					<FormLabel style={{ color: "black" }} id="searchLabel">
						{searchLabel}
					</FormLabel>
				</Box>
				<hr style={{ width: "100%", color: "black", borderWidth: "1px" }} />

				<RadioGroup
					value={selectedValue}
					onChange={handleRadioChange}
					aria-labelledby="searchLabel"
				>
					{Object.entries(selectValue).map(([key, value]) => (
						<FormControlLabel
							key={key}
							value={value}
							control={<Radio />}
							label={`${key}: ${value}`}
							style={{ color: "black" }}
						/>
					))}
				</RadioGroup>
			</Box>
		</Popover>
	);
};

export default SearchDataInConversationSelect;
