import React, { useEffect, useRef, useState } from "react";

import DropMenu from "components/Menu/DropMenu";
import { useDispatch, useSelector } from "react-redux";
import cockpitActions from "../../../redux/actions/cockpitActions";
import DictionaryExplorer from "../DictionaryExplorer";
import { getValidTypesForText } from "helpers/utilities";

// if there is to little text selected, don't put popover
const MINIMUM_TEXT_TO_SELECT = 2;

const TextSelectActionPopover = ({ target, forceClose }) => {
	const [selectedText, setSelectedText] = useState("");
	const [actionsText, setActionsText] = useState([]);
	const [openExtraDialog, setOpenExtraDialog] = useState(false);
	const [validTypes, setValidTypes] = useState([]);

	const selectedConversation = useSelector(state => state.cockpit.selectedConversation);
	const dispatch = useDispatch();
	const outerRef = useRef();

	const actions = useSelector(state => state?.assistantconfig?.actions);

	function handleActionText(actionConfig) {
		switch (actionConfig.action) {
			case "analyseTextEnrich":
				if (typeof selectedText?.text === "string") {
					handleAnalyseTextEnrich(actionConfig, selectedText.text);
				}
				break;

			case "setTextToContext":
				setOpenExtraDialog(outerRef);
				break;
			default:
				break;
		}
	}

	function handleSetTextToContext(path) {
		const data = {
			header: {
				assistantID: selectedConversation?.header?.assistantID,
				fID: selectedConversation?.header?.fID
			},
			payload: {}
		};

		data.payload[path] = selectedText.text;

		setOpenExtraDialog(false);
		dispatch(cockpitActions.launchActionFrontV2("updateConvDictionaryPartial", data));
		setSelectedText("");
	}

	const handleAnalyseTextEnrich = (actionConfig, text) => {
		const data = {
			header: {
				assistantID: selectedConversation?.header?.assistantID,
				fID: selectedConversation?.header?.fID
			},
			payload: {
				text
			}
		};

		dispatch(cockpitActions.launchActionFrontV2("analyseTextEnrich", data));
		setSelectedText("");
	};

	useEffect(() => {
		if (forceClose) {
			setSelectedText("");
		}
	}, [forceClose]);

	useEffect(() => {
		const actionsTextCompute = actions?.filter(act => act.type === "text") || [];

		setActionsText(actionsTextCompute);

		function handleMouseUp() {
			const selection = window.getSelection();
			const text = selection.toString();

			const textNotEmpty = text.trim().length >= MINIMUM_TEXT_TO_SELECT;

			const diplayCondition =
				!selection.isCollapsed && textNotEmpty && actionsTextCompute?.length > 0;

			if (diplayCondition) {
				const range = selection.getRangeAt(0);
				const rect = range.getBoundingClientRect();

				setSelectedText({
					text: selection.toString(),
					x: rect.right + 6,
					y: rect.top - 4
				});
			} else if (selection.isCollapsed && textNotEmpty && actionsTextCompute?.length > 0) {
				setSelectedText({
					text: selection.toString(),
					x: target.current.getBoundingClientRect().left,
					y: target.current.getBoundingClientRect().top + -50
				});
			} else {
				setSelectedText("");
			}
		}

		if (target?.current) {
			target.current.addEventListener("mouseup", handleMouseUp);
		}

		return () => {
			if (target?.current) {
				target.current.removeEventListener("mouseup", handleMouseUp);
			}
		};
	}, []);

	useEffect(() => {
		// When selected text changes, recompute which types are available for the string.
		// It can be phone, string, email..
		setValidTypes(getValidTypesForText(selectedText.text));
	}, [selectedText]);

	return (
		selectedText &&
		selectedConversation && (
			<div
				ref={outerRef}
				style={{
					position: "fixed",
					left: selectedText.x,
					top: selectedText.y,
					backgroundColor: "white",
					color: "rgba(0, 0, 0, 0.38)",
					border: "1px solid rgba(0, 0, 0, 0.12)",
					padding: "5px",
					zIndex: 1000,
					borderRadius: "4px"
				}}
			>
				<DropMenu handleAction={handleActionText} items={actionsText} />
				<DictionaryExplorer
					open={openExtraDialog}
					position={{ left: selectedText.x, top: selectedText.y }}
					handleClick={dictionaryPath => {
						handleSetTextToContext(dictionaryPath);
					}}
					handleClose={() => {
						setOpenExtraDialog(false);
					}}
					validTypes={validTypes}
				/>
			</div>
		)
	);
};

export default TextSelectActionPopover;
