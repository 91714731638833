import {
	Dialog,
	Button,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	ListItem,
	DialogActions,
	Typography,
	FormControl
} from "@material-ui/core";
import i18n from "i18n";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import lod_ from "lodash";
import { Autocomplete } from "@material-ui/lab";

import cockpitActions from "../../../redux/actions/cockpitActions";
import DisplayItem from "../DisplayItem";
import { loadSuggestions } from "../business/searchDataInCrmCommons";
import { infoMsg } from "redux/reducers/snackMsgsReducers";

// The name of the action front managed on NewOrchestrator
const actionFrontName = "SIEquoteManager";
// Name of the ressource on CRM side
const ressourceNameCrm = "quotation";

/**
 * Display a dialog to create and manage a quote
 * This dialog open/close state is managed by the parent component
 * @param {Function} props.handleClose - Function to close the dialog
 */
function SIEQuoteManagerDialog({ handleClose }) {
	const dispatch = useDispatch();
	const { selectedConversation } = useSelector(state => state.cockpit);

	const [quoteNumber, setQuoteNumber] = useState("");
	const [quoteNumberLink, setQuoteNumberLink] = useState("");

	// Check if the current conversation has a quotationID
	// If it has, it means that the conversation is already linked to a quote and we can propose to dissociate
	// If it hasn't, it means that the conversation is not linked to a quote and we can propose to create one or link to an existing one
	const conversationHasQuotationID = Boolean(selectedConversation?.context?.quotation?.quotationID);

	const dialogTitle = conversationHasQuotationID
		? "Dissocier le devis"
		: "Ajouter un numéro de devis";

	/**
	 * Validate the dialog content and launch the appropriate action front
	 * @param {String} type - The type of action to launch
	 */
	function validateModal(type) {
		switch (type) {
			case "add":
				createQuote(type, quoteNumber);
				break;
			case "link":
				createQuote(type, quoteNumberLink);
				break;
			case "unlink":
				dissociateQuote();
				break;
		}
		handleClose();
	}

	/**
	 * Create a quote from the current conversation with or without a quote number as input
	 * @param {String} type - The type of action to launch
	 * @param {String} quoteNumberInput - The quote number input value
	 */
	function createQuote(type, quoteNumberInput) {
		const data = {
			header: {
				assistantID: selectedConversation?.header?.assistantID,
				fID: selectedConversation?.header?.fID
			},
			payload: {
				subaction: "create",
				quoteNumber: quoteNumberInput
			}
		};
		dispatch(cockpitActions.launchActionFrontV2(actionFrontName, data));

		// Inform client about what is happening
		let userMessage = "";

		if (type === "add") {
			if (quoteNumberInput) {
				userMessage = `Création du devis en générant un nouveau numéro`;
			} else {
				userMessage = `Création du devis avec le numéro ${quoteNumberInput}`;
			}
		} else if (type === "link") {
			userMessage = `Association du devis ${quoteNumberInput} à la conversation`;
		}

		if (userMessage) {
			dispatch(infoMsg(userMessage));
		}
	}

	/**
	 * Dissociate the current conversation from the quote it is linked to
	 */
	function dissociateQuote() {
		const data = {
			header: {
				assistantID: selectedConversation?.header?.assistantID,
				fID: selectedConversation?.header?.fID
			},
			payload: {
				subaction: "delete",
				quoteNumber: selectedConversation.context.quotation.quotationID
			}
		};
		dispatch(cockpitActions.launchActionFrontV2(actionFrontName, data));

		// Inform client about what is happening
		dispatch(
			infoMsg(
				`Dissociation du devis ${selectedConversation.context.quotation.quotationID} de la conversation`
			)
		);
	}

	return (
		<Dialog
			fullWidth={true}
			onClose={handleClose}
			maxWidth={conversationHasQuotationID ? "sm" : "md"}
			open={true}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
			<DialogContent dividers>
				<Grid container spacing={3} style={{ padding: "12px" }}>
					{conversationHasQuotationID ? (
						<DialogContentWithQuote
							quoteNumber={selectedConversation.context.quotation.quotationID}
						/>
					) : (
						<DialogContentNoQuote
							validateModal={validateModal}
							quoteNumber={quoteNumber}
							setQuoteNumber={setQuoteNumber}
							quoteNumberLink={quoteNumberLink}
							setQuoteNumberLink={setQuoteNumberLink}
						/>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button style={{ color: "red" }} onClick={handleClose}>
					{i18n.t("TabAction.cancel")}
				</Button>
				{conversationHasQuotationID && (
					<Button
						color="primary"
						autoFocus
						onClick={() => validateModal("unlink")}
						style={{ alignSelf: "end" }}
					>
						Dissocier le devis de la conversation
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}

/**
 * Display the dialog content when the conversation is not linked to a quote
 * @param {String} props.quoteNumber - The quote number input value
 * @param {Function} props.setQuoteNumber - The quote number input value setter
 */
function DialogContentNoQuote({ validateModal, quoteNumber, setQuoteNumber, setQuoteNumberLink }) {
	return (
		<Grid container direction="row" alignItems="center">
			<DialogContentAddNewQuote
				validateModal={validateModal}
				quoteNumber={quoteNumber}
				setQuoteNumber={setQuoteNumber}
			/>
			<Typography>{"OU"}</Typography>
			<DialogContentLinkExistingQuote
				validateModal={validateModal}
				setQuoteNumber={setQuoteNumberLink}
			/>
		</Grid>
	);
}

/**
 * Display the dialog content when the conversation is not linked to a quote and the user choose to create a new one
 * @param {String} props.quoteNumber - The quote number input value
 * @param {Function} props.setQuoteNumber - The quote number input value setter
 */
function DialogContentAddNewQuote({ validateModal, quoteNumber, setQuoteNumber }) {
	return (
		<ListItem style={{ flex: "1", flexDirection: "column", alignItems: "start" }}>
			<Typography variant="h6">Créer un devis</Typography>
			<Typography>
				{
					"Si aucun numéro de devis n'est renseigné, un devis sera créé avec un numéro généré automatiquement."
				}
			</Typography>
			<br></br>
			<TextField
				label="Numéro de devis (optionel)"
				value={quoteNumber}
				onChange={e => setQuoteNumber(e.target.value)}
				variant="outlined"
				fullWidth
			/>
			<br></br>
			<Button
				color="primary"
				autoFocus
				onClick={() => validateModal("add")}
				style={{ alignSelf: "end" }}
			>
				Créer un devis
			</Button>
		</ListItem>
	);
}

/**
 * Display the dialog content when the conversation is not linked to a quote and the user choose to link to an existing one
 * @param {Function} props.setQuoteNumber - The quote number input value setter
 */
function DialogContentLinkExistingQuote({ validateModal, setQuoteNumber }) {
	const [searchField, setSearchField] = useState("");
	const [suggestions, setSuggestions] = useState([]);
	const [disable, setDisable] = useState(true);

	const { user, selectedConversation } = useSelector(state => {
		return {
			user: state.userStatus.auth.user,
			selectedConversation: state.cockpit.selectedConversation
		};
	});

	const quotationIDPath = "quotation.quotationID";
	const shownFields = [quotationIDPath, "createdAt", "customer.group", "quotation.amount"];
	const sort = "createdAt:desc";

	useEffect(() => {
		if (searchField) {
			const timeoutId = setTimeout(async () => {
				const suggestionsFetched = await loadSuggestions(
					selectedConversation.header.assistantID,
					user,
					searchField,
					ressourceNameCrm,
					sort
				);
				setSuggestions(suggestionsFetched);
			}, 500);
			return () => clearTimeout(timeoutId);
		}
	}, [searchField]);

	function handleAutocompleteChange(event, newInputValue, reason) {
		if (newInputValue && reason === "select-option") {
			const choosedQuotationID = lod_.get(newInputValue, quotationIDPath);
			setQuoteNumber(choosedQuotationID);
			setDisable(false);
		}
		if (reason === "clear") {
			setQuoteNumber("");
			setDisable(true);
		}
	}
	return (
		<ListItem style={{ flex: "1", flexDirection: "column", alignItems: "start" }}>
			<Typography variant="h6">Lier à un devis existant</Typography>
			<Typography>
				{"Recherchez un numéro de devis existant afin de lier cette conversation à celui-ci."}
			</Typography>
			<br></br>
			<FormControl fullWidth>
				<Autocomplete
					id="autocomplete-recipient"
					freeSolo
					options={suggestions}
					style={{ minWidth: "300px" }}
					getOptionLabel={option => lod_.get(option, quotationIDPath) || ""}
					onChange={handleAutocompleteChange}
					filterOptions={(options, state) => options}
					renderOption={(suggestedValue, option) => DisplayItem(suggestedValue, shownFields)}
					renderInput={params => (
						<TextField
							{...params}
							label={"Numéro de devis"}
							variant="outlined"
							size="medium"
							value={searchField}
							onChange={e => setSearchField(e.target.value)}
						/>
					)}
				/>
			</FormControl>
			<br></br>
			<Button
				color="primary"
				autoFocus
				onClick={() => validateModal("link")}
				style={{ alignSelf: "end" }}
				disabled={disable}
			>
				Associer le devis à la conversation
			</Button>
		</ListItem>
	);
}

/**
 * Display the dialog content when the conversation is linked to a quote
 */
function DialogContentWithQuote({ quoteNumber }) {
	return (
		<ListItem style={{ flex: "1", flexDirection: "column" }}>
			<Typography>{"Cette conversation est lié au devis suivant:" + quoteNumber}</Typography>
		</ListItem>
	);
}

export default SIEQuoteManagerDialog;
